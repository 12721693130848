import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";

import { API_BASE_PATH } from "../const";

/**
 * Base API client.
 * If you need to add endpoints, you should inject them using `api.injectEndpoints()`
 */
export const api = createApi({
  baseQuery: fetchBaseQuery({
    baseUrl: API_BASE_PATH,

    prepareHeaders: (headers) => {
      const token = document.cookie
        .split(";")
        .find((cookie) => cookie.trim().startsWith("csrftoken"))
        ?.split("=")[1];

      if (token) {
        headers.set("X-CSRFToken", token);
      }

      return headers;
    },

    paramsSerializer: (params) => {
      const urlParams = new URLSearchParams();

      for (const [key, value] of Object.entries(params)) {
        if (value == null) continue;

        if (Array.isArray(value)) {
          // If the value is an array, add each item as a separate query parameter
          // Otherwise, the URLSearchParams will add the array as a single query parameter
          // With values separated by commas
          // This will be parsed as one value by the backend
          for (const item of value) {
            urlParams.append(key, item);
          }
        } else {
          urlParams.append(key, value);
        }
      }

      return urlParams.toString();
    },
  }),
  endpoints: () => ({}),
});
