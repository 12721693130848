import { debounce } from "lodash";
import { useCallback, useEffect, useState } from "react";

/**
 * @template T
 * @param {T} value
 * @param {number} [delay=0]
 * @param {import("lodash").DebounceSettings} [options]
 * @returns {T}
 */
export const useDebounce = (value, delay = 0, options) => {
  const [debouncedValue, setDebouncedValue] = useState(value);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const debounced = useCallback(debounce(setDebouncedValue, delay, options), [delay, options]);

  useEffect(() => {
    debounced(value);
  }, [value, debounced]);

  return debouncedValue;
};
