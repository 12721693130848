import PropTypes from "prop-types";
import React from "react";

import { Dropdown } from "@/components";

const defaultGetOptionLabel = (option) => option.label;
const defaultGetOptionValue = (option) => option.value;

export const QueryDropdown = ({
  query,
  getOptionLabel = defaultGetOptionLabel,
  getOptionValue = defaultGetOptionValue,
  sort,
  ...props
}) => {
  const { data, isFetching } = query({ sort });

  return (
    <Dropdown
      options={data}
      getOptionLabel={getOptionLabel}
      getOptionValue={getOptionValue}
      isLoading={isFetching}
      {...props}
    />
  );
};

QueryDropdown.propTypes = {
  query: PropTypes.func.isRequired,
  getOptionLabel: PropTypes.func,
  getOptionValue: PropTypes.func,
  sort: PropTypes.string,
};
