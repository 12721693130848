import React, { useCallback, useEffect, useState } from "react";

import { CustomerInfoForm, Tab, Tabs } from "@/components";

import { CarOverview } from "./CarOverview";
import { CheckoutProcessing } from "./CheckoutProcessing";
import { CheckoutSummary } from "./CheckoutSummary";
import { ConfirmationFee } from "./ConfirmationFee";

/**
 * @param {Object} props
 * @param {import('@/api/generated/cars').CarDetailSchema} props.car - The car to checkout
 */
export const CarCheckout = ({ car, purchaseOrder = null }) => {
  const [currentTab, setCurrentTab] = useState(0);
  const [checkoutFinished, setCheckoutFinished] = useState(false);
  const [selectedAccessories, setSelectedAccessories] = useState(null);
  const [customerInfo, setCustomerInfo] = useState(null);
  const [cardInfo, setCardInfo] = useState(null);

  const nextStep = useCallback(() => {
    setCurrentTab((ct) => ct + 1);
  }, []);

  const onAccessoriesFormSubmit = useCallback(
    (data) => {
      setSelectedAccessories(data);
      nextStep();
    },
    [nextStep],
  );

  const onCustomerInfoSumbit = useCallback(
    (data) => {
      setCustomerInfo(data);
      nextStep();
    },
    [nextStep],
  );

  const onConfirmationFeeFormSubmit = useCallback(
    (data) => {
      setCardInfo(data);
      nextStep();
    },
    [nextStep],
  );

  const onCreateCheckoutSuccess = useCallback((data) => {
    window.location.href = data.url;
  }, []);

  useEffect(() => {
    if (purchaseOrder) {
      setCheckoutFinished(true);
      setCurrentTab(5);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [purchaseOrder]);

  return (
    <Tabs
      currentTab={currentTab}
      onTabChange={setCurrentTab}
      className="my-5 themed-scroller"
      compact
    >
      {/* Step 0 */}
      <Tab title="Bíllinn" disabled={!!purchaseOrder}>
        <CarOverview car={car} onSubmit={onAccessoriesFormSubmit} />
      </Tab>
      {/* Step 1 */}
      <Tab
        title="Kaupandi"
        disabled={(index, currentIndex) =>
          checkoutFinished || currentIndex < index || purchaseOrder
        }
      >
        <CustomerInfoForm onSubmit={onCustomerInfoSumbit} />
      </Tab>
      {/* Step 2 */}
      <Tab
        title="Staðfestingargjald"
        disabled={(index, currentIndex) =>
          checkoutFinished || currentIndex < index || purchaseOrder
        }
      >
        <ConfirmationFee onSubmit={onConfirmationFeeFormSubmit} />
      </Tab>
      {/* Step 3 */}
      <Tab hidden disabled={!!purchaseOrder}>
        <CheckoutProcessing
          car={car}
          accessories={selectedAccessories}
          buyerInfo={customerInfo}
          cardInfo={cardInfo}
          onSuccess={onCreateCheckoutSuccess}
        />
      </Tab>
      {/* Step 4 */}
      <Tab title="Greiðsla" disabled />
      {/* Step 5 */}
      <Tab title="Samantekt" disabled={(index, currentIndex) => currentIndex < index}>
        <CheckoutSummary purchaseOrder={purchaseOrder} />
      </Tab>
    </Tabs>
  );
};
