import React, { useCallback } from "react";
import { useForm } from "react-hook-form";

import { useBookWindshieldReplacementMutation } from "@/api/generated/bookings";
import { Button, LoadingSpinner } from "@/components";
import { cn } from "@/utils";

function WindshieldBookingForm() {
  const [createBooking, { isSuccess, isLoading, isError }] = useBookWindshieldReplacementMutation();

  const {
    register,
    handleSubmit,
    watch,
    formState: { errors, isValid, isDirty },
  } = useForm({ mode: "onChange" });

  const carFrontImage = watch("carFrontImage", false);
  const damageCloseUpImage = watch("damageCloseUpImage", false);

  const onSubmit = useCallback(
    (data) => {
      const formData = new FormData();

      if (data.carFrontImage?.[0]) {
        formData.append("car_front_image", data.carFrontImage[0]);
      }
      if (data.damageCloseUpImage?.[0]) {
        formData.append("damage_closeup_image", data.damageCloseUpImage[0]);
      }

      const bookingData = {
        email: data.email,
        tel: data.tel,
        ownerID: data.ownerID,
        carNumber: data.carNumber,
        insurance: data.insurance,
        incidentDate: data.incidentDate,
        incidentLocation: data.incidentLocation,
        cause: data.cause,
      };

      formData.append("data", JSON.stringify(bookingData));
      createBooking(formData);
    },
    [createBooking],
  );

  if (isSuccess) {
    return (
      <p className="alert alert-success text-center my-5">
        Beiðni um rúðuskipti hefur verið send. Við munum hafa samband fljótlega.{" "}
      </p>
    );
  }

  return (
    <form onSubmit={handleSubmit(onSubmit)} className="my-5">
      <div className="fields row justify-content-center">
        <div className="col-xl-4 col-lg-5 col-md-10 col-12">
          {/* Insurance selection */}
          <div className="mb-3">
            <h5 className="mb-2">Hjá hvaða tryggingafélagi ertu tryggður?</h5>
            <p className="form-description">
              Athugið að tjónþoli greiðir eigin áhættu samkvæmt skilmálum tryggingafélags.
            </p>
            <div className="d-flex justify-content-between flex-wrap">
              <div className="form-check form-check-inline field-container ps-0 flex-row align-items-center">
                <input
                  type="radio"
                  className="form-check-input"
                  value="Sjóvá"
                  {...register("insurance", { required: true })}
                />
                <label className="form-check-label">Sjóvá</label>
              </div>
              <div className="form-check form-check-inline field-container ps-0 flex-row align-items-center ">
                <input
                  type="radio"
                  className="form-check-input"
                  value="TM"
                  {...register("insurance", { required: true })}
                />
                <label className="form-check-label">TM</label>
              </div>
              <div className="form-check form-check-inline  field-container ps-0 flex-row align-items-center">
                <input
                  type="radio"
                  className="form-check-input"
                  value="VÍS"
                  {...register("insurance", { required: true })}
                />
                <label className="form-check-label">VÍS</label>
              </div>
              <div className="form-check form-check-inline  field-container ps-0 flex-row align-items-center">
                <input
                  type="radio"
                  className="form-check-input"
                  value="Vörður"
                  {...register("insurance", { required: true })}
                />
                <label className="form-check-label">Vörður</label>
              </div>
            </div>
            {errors.insurance && <p className="text-danger">Þessi reitur er nauðsynlegur</p>}
          </div>

          {/* Car Info */}
          <div className="field-container">
            <label className="form-label">Bílnúmer</label>
            <input
              type="text"
              className="form-control"
              {...register("carNumber", {
                required: {
                  value: true,
                  message: "Þessi reitur er nauðsynlegur",
                },
                maxLength: {
                  value: 6,
                  message: "This field is too long",
                },
              })}
              placeholder=""
            />
            {errors.carNumber && <p className="text-danger">{errors.carNumber.message}</p>}
          </div>

          <div className="mb-3 field-container">
            <label className="form-label">Kennitala eiganda</label>
            <input
              type="text"
              className="form-control"
              {...register("ownerID", {
                required: {
                  value: true,
                  message: "Þessi reitur er nauðsynlegur",
                },
                maxLength: {
                  value: 10,
                  message: "This field is too long",
                },
              })}
              placeholder=""
            />
            {errors.ownerID && <p className="text-danger">{errors.ownerID.message}</p>}
          </div>

          <div className="mb-3 field-container">
            <label className="form-label">Netfang</label>
            <input
              type="email"
              className="form-control"
              {...register("email", { required: true })}
              placeholder=""
            />
            {errors.email && <p className="text-danger">Þessi reitur er nauðsynlegur</p>}
          </div>

          <div className="mb-3 field-container">
            <label className="form-label">Sími</label>
            <input
              type="tel"
              className="form-control"
              {...register("tel", {
                required: {
                  value: true,
                  message: "Þessi reitur er nauðsynlegur",
                },
                maxLength: {
                  value: 7,
                  message: "This field is too long",
                },
              })}
              placeholder=""
            />
            {errors.tel && <p className="text-danger">{errors.tel.message}</p>}
          </div>
        </div>

        <div className="col-xl-4 col-lg-5 col-md-10 col-12">
          {/* Incident Info */}
          <div className="mb-3">
            <h5 className="mb-2">Hvar gerðist tjónið?</h5>
            <p className="form-description">{` sem dæmi "á þjóðvegi 1 á Holtavörðuheiði leið suður" `}</p>
            <div className="field-container">
              <label className="form-label">Staðsetning</label>
              <input
                type="text"
                className="form-control"
                {...register("incidentLocation", {
                  required: {
                    value: true,
                    message: "Þessi reitur er nauðsynlegur",
                  },
                  maxLength: {
                    value: 255,
                    message: "This field is too long",
                  },
                })}
                placeholder=""
              />
              {errors.incidentLocation && (
                <p className="text-danger">{errors.incidentLocation.message}</p>
              )}
            </div>
          </div>

          <div className="mb-3 ">
            <div className="field-container">
              <label className="form-label">Dagsetning tjóns</label>
              <input
                type="date"
                className="form-control date"
                {...register("incidentDate", { required: true })}
              />
              {errors.incidentDate && <p className="text-danger">Þessi reitur er nauðsynlegur</p>}
            </div>
          </div>

          {/* Cause info */}
          <div className="mb-3">
            <h5 className="mb-2">Hver var orsakavaldurinn?</h5>
            <div className="d-flex flex-wrap cause-wrapper mt-3">
              <div className="form-check field-container ps-0 flex-row cause-field-container align-items-center">
                <input
                  type="checkbox"
                  className="form-check-input"
                  value="Steinkast"
                  {...register("cause")}
                />
                <label className="form-check-label cause-label me-5">Steinkast</label>
              </div>
              <div className="form-check field-container ps-0 flex-row cause-field cause-field-container align-items-center">
                <input
                  type="checkbox"
                  className="form-check-input"
                  value="Innbrot"
                  {...register("cause")}
                />
                <label className="form-check-label cause-label me-5">Innbrot</label>
              </div>
              <div className="form-check field-container ps-0 flex-row cause-field cause-field-container align-items-center">
                <input
                  type="checkbox"
                  className="form-check-input"
                  value="Annað"
                  {...register("cause")}
                />
                <label className="form-check-label cause-label">Annað</label>
              </div>
            </div>
          </div>

          {/* Image Upload */}
          <div className="mb-3">
            <h5 className="mb-2">Hlaða inn myndum af tjóni</h5>
            <div className="field-container">
              <label className="form-label">Mynd framan á bílnum svo númerið sjáist</label>
              <input
                type="file"
                accept="image/*"
                className={cn("form-control file-input", carFrontImage && "has-data")}
                {...register("carFrontImage")}
              />
            </div>
          </div>
          <div className="mb-3 field-container">
            <label className="form-label">Nærmynd af skemmdinni</label>
            <input
              type="file"
              accept="image/*"
              className={cn("form-control file-input", damageCloseUpImage && "has-data")}
              {...register("damageCloseUpImage")}
            />
          </div>

          <div className="submit d-flex justify-content-lg-end justify-content-center mt-5">
            <Button
              type="submit"
              primary
              disabled={(!isDirty && !isValid) || isLoading}
              label="Senda inn"
              className="text-uppercase"
              icon={
                isLoading && <LoadingSpinner className="text-white m-2" style={{ width: "2rem" }} />
              }
            />
          </div>
        </div>
      </div>
      <div className="row justify-content-center">
        {isError && (
          <p className="alert alert-danger text-center my-5">Villa kom upp við að panta bíl</p>
        )}
      </div>
    </form>
  );
}

export default WindshieldBookingForm;
