import { api } from "../index";
export const addTagTypes = [
  "cars",
  "car_makes",
  "car_models",
  "car_fuel_types",
  "tire_types",
  "car_accessories",
  "purchase_order",
] as const;
const injectedRtkApi = api
  .enhanceEndpoints({
    addTagTypes,
  })
  .injectEndpoints({
    endpoints: (build) => ({
      carList: build.query<CarListApiResponse, CarListApiArg>({
        query: (queryArg) => ({
          url: `/api/cars/`,
          params: {
            fuelType: Array.isArray(queryArg.fuelType) ?  queryArg.fuelType?.flat() : queryArg.fuelType,
            category: queryArg.category,
            color: queryArg.color,
            drive: queryArg.drive,
            transmission: queryArg.transmission,
            kjarabill: queryArg.kjarabill,
            seats: queryArg.seats,
            doors: queryArg.doors,
            isofix_points: queryArg.isofixPoints,
            rental_available: queryArg.rentalAvailable,
            make: queryArg.make,
            model: queryArg.model,
            sort: queryArg.sort,
            limit: queryArg.limit,
            offset: queryArg.offset,
          },
        }),
        providesTags: ["cars"],
      }),
      carsGrouped: build.query<CarsGroupedApiResponse, CarsGroupedApiArg>({
        query: (queryArg) => ({
          url: `/api/cars/groups/`,
          params: {
            fuelType: Array.isArray(queryArg.fuelType) ?  queryArg.fuelType?.flat() : queryArg.fuelType,
            category: queryArg.category,
            color: queryArg.color,
            drive: queryArg.drive,
            transmission: queryArg.transmission,
            kjarabill: queryArg.kjarabill,
            seats: queryArg.seats,
            doors: queryArg.doors,
            isofix_points: queryArg.isofixPoints,
            rental_available: queryArg.rentalAvailable,
            make: queryArg.make,
            model: queryArg.model,
            min_price: queryArg.minPrice,
            max_price: queryArg.maxPrice,
            min_rental_price: queryArg.minRentalPrice,
            max_rental_price: queryArg.maxRentalPrice,
            sort: queryArg.sort,
            limit: queryArg.limit,
            offset: queryArg.offset,
          },
        }),
        providesTags: ["cars"],
      }),
      carsCompare: build.query<CarsCompareApiResponse, CarsCompareApiArg>({
        query: (queryArg) => ({ url: `/api/cars/compare/`, params: { ids: queryArg.ids } }),
        providesTags: ["cars"],
      }),
      carsCompareDetails: build.query<CarsCompareDetailsApiResponse, CarsCompareDetailsApiArg>({
        query: (queryArg) => ({ url: `/api/cars/compare/details/`, params: { ids: queryArg.ids } }),
        providesTags: ["cars"],
      }),
      carMakes: build.query<CarMakesApiResponse, CarMakesApiArg>({
        query: (queryArg) => ({
          url: `/api/cars/makes/`,
          params: { code: queryArg.code, sort: queryArg.sort },
        }),
        providesTags: ["car_makes"],
      }),
      carModels: build.query<CarModelsApiResponse, CarModelsApiArg>({
        query: (queryArg) => ({
          url: `/api/cars/models/`,
          params: { code: queryArg.code, sort: queryArg.sort },
        }),
        providesTags: ["car_models"],
      }),
      carColors: build.query<CarColorsApiResponse, CarColorsApiArg>({
        query: (queryArg) => ({ url: `/api/cars/colors/`, params: { sort: queryArg.sort } }),
        providesTags: ["cars"],
      }),
      carDriveTypes: build.query<CarDriveTypesApiResponse, CarDriveTypesApiArg>({
        query: (queryArg) => ({ url: `/api/cars/drive-types/`, params: { sort: queryArg.sort } }),
        providesTags: ["cars"],
      }),
      carTransmissionTypes: build.query<
        CarTransmissionTypesApiResponse,
        CarTransmissionTypesApiArg
      >({
        query: (queryArg) => ({
          url: `/api/cars/transmission-types/`,
          params: { sort: queryArg.sort },
        }),
        providesTags: ["cars"],
      }),
      carFuelTypes: build.query<CarFuelTypesApiResponse, CarFuelTypesApiArg>({
        query: (queryArg) => ({ url: `/api/cars/fuel-types/`, params: { sort: queryArg.sort } }),
        providesTags: ["car_fuel_types"],
      }),
      carWinterTireTypes: build.query<CarWinterTireTypesApiResponse, CarWinterTireTypesApiArg>({
        query: () => ({ url: `/api/cars/winter-tire-types/` }),
        providesTags: ["tire_types"],
      }),
      carsPriceRange: build.query<CarsPriceRangeApiResponse, CarsPriceRangeApiArg>({
        query: () => ({ url: `/api/cars/price-range/` }),
        providesTags: ["cars"],
      }),
      carDetail: build.query<CarDetailApiResponse, CarDetailApiArg>({
        query: (queryArg) => ({ url: `/api/cars/${queryArg.carId}/` }),
        providesTags: ["cars"],
      }),
      carAccessories: build.query<CarAccessoriesApiResponse, CarAccessoriesApiArg>({
        query: (queryArg) => ({ url: `/api/cars/${queryArg.carId}/accessories/` }),
        providesTags: ["cars", "car_accessories"],
      }),
      carCheckout: build.mutation<CarCheckoutApiResponse, CarCheckoutApiArg>({
        query: (queryArg) => ({
          url: `/api/cars/${queryArg.carId}/checkout/`,
          method: "POST",
          body: queryArg.carCheckoutSchema,
        }),
        invalidatesTags: ["cars"],
      }),
      purchaseOrderDetail: build.query<PurchaseOrderDetailApiResponse, PurchaseOrderDetailApiArg>({
        query: (queryArg) => ({ url: `/api/cars/purchase-order/${queryArg.orderNo}/` }),
        providesTags: ["purchase_order"],
      }),
      carLongTermRental: build.mutation<CarLongTermRentalApiResponse, CarLongTermRentalApiArg>({
        query: (queryArg) => ({
          url: `/api/cars/${queryArg.carId}/long-term-rental/`,
          method: "POST",
          body: queryArg.carLongTermRentalCheckoutSchema,
        }),
        invalidatesTags: ["cars"],
      }),
      carsByMakeModel: build.query<CarsByMakeModelApiResponse, CarsByMakeModelApiArg>({
        query: (queryArg) => ({
          url: `/api/cars/${queryArg.carMake}/${queryArg.carModel}/`,
          params: {
            fuelType: queryArg.fuelType,
            category: queryArg.category,
            color: queryArg.color,
            drive: queryArg.drive,
            transmission: queryArg.transmission,
            kjarabill: queryArg.kjarabill,
            seats: queryArg.seats,
            doors: queryArg.doors,
            isofix_points: queryArg.isofixPoints,
            rental_available: queryArg.rentalAvailable,
            sort: queryArg.sort,
            limit: queryArg.limit,
            offset: queryArg.offset,
          },
        }),
        providesTags: ["cars"],
      }),
    }),
    overrideExisting: false,
  });
export { injectedRtkApi as carsApi };
export type CarListApiResponse = /** status 200 OK */ PagedCarSchema;
export type CarListApiArg = {
  fuelType?: string[] | null;
  category?: string[] | null;
  color?: string[] | null;
  drive?: string[] | null;
  transmission?: string[] | null;
  kjarabill?: boolean | null;
  seats?: number[] | null;
  doors?: number[] | null;
  isofixPoints?: number[] | null;
  rentalAvailable?: boolean | null;
  make?: string[] | null;
  model?: string[] | null;
  /** Sort the queryset by this field. Prefix with '-' for descending order. */
  sort?: string | null;
  limit?: number;
  offset?: number;
};
export type CarsGroupedApiResponse = /** status 200 OK */ PagedCarGroupSchema;
export type CarsGroupedApiArg = {
  fuelType?: string[] | null;
  category?: string[] | null;
  color?: string[] | null;
  drive?: string[] | null;
  transmission?: string[] | null;
  kjarabill?: boolean | null;
  seats?: number[] | null;
  doors?: number[] | null;
  isofixPoints?: number[] | null;
  rentalAvailable?: boolean | null;
  make?: string[] | null;
  model?: string[] | null;
  minPrice?: number | null;
  maxPrice?: number | null;
  minRentalPrice?: number | null;
  maxRentalPrice?: number | null;
  /** Sort the queryset by this field. Prefix with '-' for descending order. */
  sort?: string | null;
  limit?: number;
  offset?: number;
};
export type CarsCompareApiResponse = /** status 200 OK */ CarCompareSchema[];
export type CarsCompareApiArg = {
  ids?: number[] | null;
};
export type CarsCompareDetailsApiResponse = /** status 200 OK */ CarDetailSchema[];
export type CarsCompareDetailsApiArg = {
  ids?: number[] | null;
};
export type CarMakesApiResponse = /** status 200 OK */ CarMakeSchema[];
export type CarMakesApiArg = {
  code?: string | null;
  /** Sort the queryset by this field. Prefix with '-' for descending order. */
  sort?: string | null;
};
export type CarModelsApiResponse = /** status 200 OK */ CarModelSchema[];
export type CarModelsApiArg = {
  code?: string | null;
  /** Sort the queryset by this field. Prefix with '-' for descending order. */
  sort?: string | null;
};
export type CarColorsApiResponse = /** status 200 OK */ CarColorSchema[];
export type CarColorsApiArg = {
  /** Sort the queryset by this field. Prefix with '-' for descending order. */
  sort?: string | null;
};
export type CarDriveTypesApiResponse = /** status 200 OK */ CarDriveSchema[];
export type CarDriveTypesApiArg = {
  /** Sort the queryset by this field. Prefix with '-' for descending order. */
  sort?: string | null;
};
export type CarTransmissionTypesApiResponse = /** status 200 OK */ CarTransmissionSchema[];
export type CarTransmissionTypesApiArg = {
  /** Sort the queryset by this field. Prefix with '-' for descending order. */
  sort?: string | null;
};
export type CarFuelTypesApiResponse = /** status 200 OK */ CarFuelTypeSchema[];
export type CarFuelTypesApiArg = {
  /** Sort the queryset by this field. Prefix with '-' for descending order. */
  sort?: string | null;
};
export type CarWinterTireTypesApiResponse = /** status 200 OK */ CarWinterTiresTypeSchema[];
export type CarWinterTireTypesApiArg = void;
export type CarsPriceRangeApiResponse = /** status 200 OK */ PricingSchema;
export type CarsPriceRangeApiArg = void;
export type CarDetailApiResponse = /** status 200 OK */ CarDetailSchema;
export type CarDetailApiArg = {
  carId: number;
};
export type CarAccessoriesApiResponse = /** status 200 OK */ CarAccessorySchema[];
export type CarAccessoriesApiArg = {
  carId: number;
};
export type CarCheckoutApiResponse = /** status 200 OK */ CreateCheckoutResponseSchema;
export type CarCheckoutApiArg = {
  carId: number;
  carCheckoutSchema: CarCheckoutSchema;
};
export type PurchaseOrderDetailApiResponse = /** status 200 OK */ PurchaseOrderResponseSchema;
export type PurchaseOrderDetailApiArg = {
  orderNo: string;
};
export type CarLongTermRentalApiResponse = /** status 200 OK */ SuccessResponseSchema;
export type CarLongTermRentalApiArg = {
  carId: number;
  carLongTermRentalCheckoutSchema: CarLongTermRentalCheckoutSchema;
};
export type CarsByMakeModelApiResponse = /** status 200 OK */ PagedCarDetailSchema;
export type CarsByMakeModelApiArg = {
  /** Car make code */
  carMake: string;
  /** Car model code */
  carModel: string;
  fuelType?: string[] | null;
  category?: string[] | null;
  color?: string[] | null;
  drive?: string[] | null;
  transmission?: string[] | null;
  kjarabill?: boolean | null;
  seats?: number[] | null;
  doors?: number[] | null;
  isofixPoints?: number[] | null;
  rentalAvailable?: boolean | null;
  /** Sort the queryset by this field. Prefix with '-' for descending order. */
  sort?: string | null;
  limit?: number;
  offset?: number;
};
export type CarRentalOptionSchema = {
  months: number;
  kilometers: number;
  price: number;
};
export type CarDiscountSchema = {
  code: string;
  name: string;
  amount: number;
};
export type CarSchema = {
  id: number;
  image: string;
  make: string;
  model: string;
  variant: string;
  model_description: string | null;
  price: number;
  discounted_price: number;
  display_status: string | null;
  rental_options: CarRentalOptionSchema[];
  rental_available: boolean;
  category?: string | null;
  fuelType?: string | null;
  fuel_economy?: number | null;
  range: number | null;
  color?: string | null;
  drive: string | null;
  transmission: string | null;
  seats?: number | null;
  doors?: number | null;
  isofix_points: number | null;
  kjarabill: boolean;
  kjarabill_price?: number | null;
  discounts?: CarDiscountSchema[];
};
export type PagedCarSchema = {
  items: CarSchema[];
  count: number;
};
export type PriceRangeSchema = {
  /** Minimum price. 0 if not available. */
  min?: number;
  /** Maximum price. 0 if not available. */
  max?: number;
};
export type PricingSchema = {
  purchase: PriceRangeSchema;
  rental: PriceRangeSchema;
};
export type CarGroupSchema = {
  make: string;
  model: string;
  image: string;
  pricing: PricingSchema;
  rental_available: boolean;
  display_statuses: string[];
  fuelTypes: string[];
  fuel_economy?: number | null;
  range: number | null;
  kjarabill: boolean;
  available: boolean;
  discounts: CarDiscountSchema[];
};
export type PagedCarGroupSchema = {
  items: CarGroupSchema[];
  count: number;
};
export type CarCompareSchema = {
  id: number;
  make: string;
  model: string;
  image?: string | null;
  variant: string | null;
};
export type CarAttributeCategorySchema = {
  name: string | null;
  attributes: string[];
};
export type CarDetailSchema = {
  id: number;
  image: string;
  make: string;
  model: string;
  variant: string;
  model_description: string | null;
  price: number;
  discounted_price: number;
  display_status: string | null;
  rental_options: CarRentalOptionSchema[];
  rental_available: boolean;
  category?: string | null;
  fuelType?: string | null;
  fuel_economy?: number | null;
  range: number | null;
  color?: string | null;
  drive: string | null;
  transmission: string | null;
  seats?: number | null;
  doors?: number | null;
  isofix_points: number | null;
  kjarabill: boolean;
  kjarabill_price?: number | null;
  discounts?: CarDiscountSchema[];
  model_version: string;
  swatch: string;
  exterior_images?: string[];
  interior_images?: string[];
  engine_size_cc: string | null;
  consumption: number | null;
  co2_emission?: string | null;
  co2_wltp?: string | null;
  horsepower: number | null;
  number_of_cylinders: number | null;
  max_torque: number | null;
  acceleration: number | null;
  top_speed: number | null;
  length: number | null;
  width: number | null;
  height: number | null;
  wheel_base: number | null;
  turning_circle: number | null;
  luggage_compartment_min: number | null;
  luggage_compartment_max: number | null;
  kerb_weight?: string | null;
  towing_capacity_unbraked: number | null;
  towing_capacity_braked: number | null;
  fuel_tank_capacity: number | null;
  attributes: CarAttributeCategorySchema[];
};
export type CarMakeSchema = {
  id: number;
  name: string;
  code: string;
};
export type CarModelSchema = {
  id: number;
  name: string;
  code: string;
};
export type CarColorSchema = {
  name: string;
};
export type CarDriveSchema = {
  name: string;
};
export type CarTransmissionSchema = {
  name: string;
};
export type CarFuelTypeSchema = {
  code: string;
  name: string;
};
export type CarWinterTiresTypeSchema = {
  code: string;
  name: string;
};
export type ErrorResponseSchema = {
  status?: string;
  message?: string | null;
};
export type CarAccessorySchema = {
  id: number;
  item_no: string;
  description: string;
  description2: string | null;
  unit_price: number;
  image?: string | null;
};
export type CreateCheckoutResponseSchema = {
  status: string;
  reference: string;
  url: string;
};
export type CarCheckoutSchema = {
  name: string;
  kennitala: string;
  tel: string;
  email: string;
  accept_terms: boolean;
  accessories?: number[];
};
export type PurchaseOrderResponseSchema = {
  order_no: string;
  checkout_url: string | null;
  checkout_reference: string | null;
  amount: number;
  confirmation_fee: number;
  confirmation_fee_paid: boolean;
  confirmation_fee_paid_at: string | null;
  created_at: string;
  order_sent_to_crm: boolean;
  car: CarSchema;
  accessories?: CarAccessorySchema[];
  name: string;
  email: string;
};
export type SuccessResponseSchema = {
  status?: string;
  /** Status message */
  message?: string | null;
};
export type CarLongTermRentalCheckoutSchema = {
  name: string;
  kennitala: string;
  tel: string;
  email: string;
  accept_terms: boolean;
  rental_option: CarRentalOptionSchema;
  winter_tires?: string | null;
  accessories?: number[];
};
export type PagedCarDetailSchema = {
  items: CarDetailSchema[];
  count: number;
};
export const {
  useCarListQuery,
  useCarsGroupedQuery,
  useCarsCompareQuery,
  useCarsCompareDetailsQuery,
  useCarMakesQuery,
  useCarModelsQuery,
  useCarColorsQuery,
  useCarDriveTypesQuery,
  useCarTransmissionTypesQuery,
  useCarFuelTypesQuery,
  useCarWinterTireTypesQuery,
  useCarsPriceRangeQuery,
  useCarDetailQuery,
  useCarAccessoriesQuery,
  useCarCheckoutMutation,
  usePurchaseOrderDetailQuery,
  useCarLongTermRentalMutation,
  useCarsByMakeModelQuery,
} = injectedRtkApi;
