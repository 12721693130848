import React, { useMemo } from "react";

import { formatNumber } from "@/utils";

/**
 * @param {Object} props
 * @param {import('@/api/generated/cars').PurchaseOrderResponseSchema} props.purchaseOrder - The purchase order
 */
export const CheckoutSummary = ({ purchaseOrder }) => {
  const remaining = useMemo(
    () => Math.max(purchaseOrder.amount - purchaseOrder.confirmation_fee, 0),
    [purchaseOrder],
  );

  const modelOnOffer = useMemo(
    () => purchaseOrder.car.discounts.some((discount) => discount.code === "offer_price"),
    [purchaseOrder],
  );

  return (
    <div className="d-flex flex-column gap-4">
      <div className="mt-5">
        <p className="h4 text-primary fw-bold mb-0">Takk fyrir {purchaseOrder?.name}</p>
        <p className="mb-3">Við munum nú fara yfir pöntunina þína og hafa svo samband við þig</p>
      </div>
      <p className="h4 text-primary fw-bold">Samantekt</p>
      <table className="table">
        <tbody>
          <tr>
            <td className="text-start">
              {purchaseOrder.car.make} {purchaseOrder.car.model} {purchaseOrder.car.variant}
            </td>
            <td className="text-end">{formatNumber(purchaseOrder.car.kjarabill ? purchaseOrder.car.kjarabill_price : (modelOnOffer ? purchaseOrder.car.discounted_price : purchaseOrder.car.price) ) } kr.</td>
          </tr>
          {purchaseOrder.accessories?.map((acc) => (
            <tr key={acc.id}>
              <td className="text-start">{acc.description}</td>
              <td className="text-end">{formatNumber(acc.unit_price)} kr.</td>
            </tr>
          ))}
          <tr>
            <td className="text-start fw-bold">Samtals</td>
            <td className="text-end fw-bold">{formatNumber(purchaseOrder.amount)} kr.</td>
          </tr>
        </tbody>
      </table>
      <table className="table">
        <tbody>
          <tr>
            <td className="text-start fw-medium">Staðfestingargjald</td>
            <td className="text-end fw-medium">
              {formatNumber(purchaseOrder.confirmation_fee)} kr.
            </td>
          </tr>
          <tr>
            <td className="text-start fw-bold">Eftirstöðvar samtals</td>
            <td className="text-end fw-bold">{formatNumber(remaining)} kr.</td>
          </tr>
        </tbody>
      </table>
      <p className="mt-5">
        Hafir þú einhverjar spurningar er velkomið að hringja í okkur, síminn er 525-8000.
      </p>
    </div>
  );
};
