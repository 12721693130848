import { api } from "../index";
export const addTagTypes = [] as const;
const injectedRtkApi = api
  .enhanceEndpoints({
    addTagTypes,
  })
  .injectEndpoints({
    endpoints: (build) => ({
      bookCarTestDrive: build.mutation<BookCarTestDriveApiResponse, BookCarTestDriveApiArg>({
        query: (queryArg) => ({ url: `/api/bookings/test-drive/`, method: "POST", body: queryArg }),
      }),
      bookWindshieldReplacement: build.mutation<
        BookWindshieldReplacementApiResponse,
        BookWindshieldReplacementApiArg
      >({
        query: (queryArg) => ({
          url: `/api/bookings/windshield-replacement/`,
          method: "POST",
          body: queryArg,
        }),
      }),
    }),
    overrideExisting: false,
  });
export { injectedRtkApi as enhancedApi };
export type BookCarTestDriveApiResponse = /** status 200 OK */ SuccessResponseSchema;
export type BookCarTestDriveApiArg = TestDriveBookingSchema;
export type BookWindshieldReplacementApiResponse = /** status 200 OK */ SuccessResponseSchema;
export type BookWindshieldReplacementApiArg = {
  car_front_image: Blob;
  damage_closeup_image: Blob;
  data: WindshieldReplacementBookingSchema;
};
export type SuccessResponseSchema = {
  status?: string;
  /** Status message */
  message?: string | null;
};
export type ErrorResponseSchema = {
  status?: string;
  message?: string | null;
};
export type TestDriveBookingSchema = {
  date: string;
  time: string;
  name: string;
  tel: string;
  email: string;
  car_make: string;
  car_model: string;
};
export type WindshieldReplacementBookingSchema = {
  email: string;
  tel: string;
  ownerID: string;
  carNumber: string;
  insurance: string;
  incidentDate: string;
  incidentLocation: string;
  cause: string[] | null;
};
export const { useBookCarTestDriveMutation, useBookWindshieldReplacementMutation } = injectedRtkApi;
