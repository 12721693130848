import React from "react";

import { cn } from "../../utils";

/**
 * @param {Object} props
 * @param {boolean} [props.primary]
 * @param {boolean} [props.noBorder] - Removes the border from the button
 * @param {boolean} [props.underline] - Adds an underline to the button
 * @param {'extra-small' | 'small' | 'medium' | 'large' | 'icon' | 'CTA'} [props.size]
 * @param {string} [props.backgroundColor] - Custom background color, a css color value
 * @param {string} [props.label]
 * @param {React.ReactElement} [props.icon]
 * @param {boolean} [props.disabled]
 * @param {string} [props.className]
 * @param {React.HTMLProps<HTMLButtonElement>} props
 */
export const Button = ({
  primary = false,
  noBorder = false,
  underline = false,
  size = "medium",
  backgroundColor,
  label,
  icon,
  disabled,
  className,
  ...props
}) => {
  return (
    <button
      type="button"
      className={cn(
        "button",
        primary ? "button--primary" : "button--secondary",
        noBorder && "button--no-border",
        underline && "button--underline",
        size && `button--${size}`,
        disabled && "button--disabled",
        className,
      )}
      style={backgroundColor && { backgroundColor }}
      disabled={disabled}
      {...props}
    >
      {icon}
      {label}
    </button>
  );
};
