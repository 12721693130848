import React from "react";

import { cn } from "@/utils";

import "./DataList.scss";

/**
 * @param {Object} props
 * @param {string} props.title
 * @param {{ label: string, value: string }[]} props.items
 * @param {string} props.className
 */
export const DataList = ({ title, items, className }) => {
  const itemsWithValues = items.filter(({ value }) => value);

  if (!itemsWithValues.length) {
    return null;
  }

  return (
    <div className={cn("data-list", className)}>
      <p className="data-list__title">{title}</p>
      <ul className="data-list__list">
        {itemsWithValues.map(({ label, value }) => (
          <li className="data-list__list__item" key={label}>
            <span className="data-list__list__item__label">{label}</span>
            <span className="data-list__list__item__value">{value}</span>
          </li>
        ))}
      </ul>
    </div>
  );
};
