import React, { useCallback, useState } from "react";
import { Virtuoso } from "react-virtuoso";

import { useCarsGroupedInfiniteQuery } from "@/api/infinite/cars";
import { Button, CarGroupCard, LoadingSpinner } from "@/components";
import { cn } from "@/utils";

const LIMIT = 10;

/**
 * @param {Object} props
 * @param {import("@/api/generated/cars").CarGroupSchema[]} props.items - Cars to display in the carousel
 */
export const Carousel = ({ filters }) => {
  const [offset, setOffset] = useState(0);
  const { data, isFetching } = useCarsGroupedInfiniteQuery({ ...filters, limit: LIMIT, offset });

  const loadMore = useCallback(() => {
    if (isFetching) return;

    setOffset((prevOffset) => prevOffset + LIMIT);
  }, [isFetching]);

  const cars = data?.items ?? [];
  const hasMore = data?.count > cars.length;

  return (
    <Virtuoso
      data={cars}
      computeItemKey={(_, car) => `${car.make}-${car.model}`}
      context={{ isFetching, hasMore, loadMore }}
      components={{
        Scroller: CarouselScroller,
        List: CarouselList,
        Item: CarouselListItem,
        Footer: CarouselFooter,
      }}
      increaseViewportBy={100}
      horizontalDirection
      className="car-carousel"
    />
  );
};

const CarouselScroller = React.forwardRef(function CarouselScroller(
  { context: _, className, ...props },
  ref,
) {
  return (
    <div ref={ref} className={cn("car-carousel__scroller themed-scroller", className)} {...props} />
  );
});

const CarouselList = React.forwardRef(function CarouselList(
  { context: _, className, ...props },
  ref,
) {
  return <div ref={ref} className={cn("car-carousel__list", className)} {...props} />;
});

const CarouselListItem = React.forwardRef(function CarouselGridItem(
  { item, context: _, ...props },
  ref,
) {
  return (
    <div ref={ref} className="car-carousel__item" {...props}>
      <CarGroupCard carGroup={item} showMake />
    </div>
  );
});

const CarouselFooter = React.forwardRef(function CarouselFooter({ context, ...props }, ref) {
  return (
    <div ref={ref} className="car-carousel__footer" {...props}>
      {context.isFetching ? (
        <LoadingSpinner className="snap-center" />
      ) : (
        <div className="d-flex flex-column gap-3 align-items-stretch snap-center">
          {Boolean(context.hasMore) && (
            <Button onClick={context.loadMore} label="Sýna fleiri bíla" />
          )}
          <a href="/nyir-bilar" className="button button--secondary button--medium">
            Skoða allt úrvalið
          </a>
        </div>
      )}
    </div>
  );
});
