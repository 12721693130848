import React from "react";
import { Userbar } from "../../components/userbar/Userbar";

export default function UserbarContainer() {
  // TODO: Replace with real user
  const user = { name: "John Doe" };
  // TODO: Replace with real functions
  const onLogin = () => {
    console.log("onLogin");
  };
  const onCreateAccount = () => {
    console.log("onCreateAccount");
  };

  return <Userbar user={user} onLogin={onLogin} onCreateAccount={onCreateAccount} />;
}
