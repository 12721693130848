import React, { useMemo, useReducer } from "react";

import { Button } from "@/components";
import { cn, formatNumber } from "@/utils";

import { SelectedCarInfo } from "./SelectedCarInfo";

/**
 * @param {Object} props
 * @param {import('@/api/generated/cars').CarDetailSchema} props.car - The car to checkout
 * @param {Function} props.onSubmit
 */
export const CarRentalType = ({ car, onSubmit }) => {
  const rentalOptions = useMemo(() => {
    return car.rental_options.filter((ro) => ro.price > 0);
  }, [car]);

  const [selectedRentalOption, dispatch] = useReducer(reducer, rentalOptions[0]);

  const validRentalLengthOptions = useMemo(() => {
    return Array.from(new Set(rentalOptions.map((ro) => ro.months)));
  }, [rentalOptions]);

  const validRentalAnnualMileageOptions = useMemo(() => {
    return Array.from(
      new Set(
        rentalOptions
          .filter((ro) => ro.months === selectedRentalOption.months)
          .map((ro) => ro.kilometers),
      ),
    );
  }, [rentalOptions, selectedRentalOption.months]);

  return (
    <div className="d-flex flex-column gap-5">
      <SelectedCarInfo car={car} selectedRentalOption={selectedRentalOption} />
      <div>
        <p className="h4 text-primary fw-bold m-0">Leigutími</p>
        <div className="d-grid grid-cols-1 grid-cols-md-2 grid-cols-lg-3 gap-4 mt-4 mb-5">
          {validRentalLengthOptions.map((ro) => (
            <div
              key={ro}
              className={cn("card w-100", selectedRentalOption.months === ro && "border-secondary")}
              onClick={() =>
                dispatch({ type: "SET_RENTAL_LENGTH", rentalLength: ro, rentalOptions })
              }
              role="button"
            >
              <div className="card-body d-flex flex-column align-items-center gap-4">
                <p className="m-0 text-primary fw-bold text-uppercase p-5">{ro} mánuðir</p>
              </div>
            </div>
          ))}
        </div>

        <div className="d-flex flex-wrap gap-3">
          <p className="h4 text-primary fw-bold m-0">Akstur á ári </p>
          <p className="m-0">(algengur meðalakstur er um það bil 18.000 km.)</p>
        </div>
        <div className="d-grid grid-cols-1 grid-cols-md-2 grid-cols-lg-3 gap-4 mt-4 mb-5">
          {validRentalAnnualMileageOptions.map((ro) => (
            <div
              key={ro}
              className={cn(
                "card w-100",
                selectedRentalOption.kilometers === ro && "border-secondary",
              )}
              onClick={() =>
                dispatch({ type: "SET_ANNUAL_MILEAGE", annualMileage: ro, rentalOptions })
              }
              role="button"
            >
              <div className="card-body d-flex flex-column align-items-center gap-4">
                <p className="m-0 text-primary fw-bold p-5">{formatNumber(ro)} km</p>
              </div>
            </div>
          ))}
        </div>
      </div>
      <div className="d-flex justify-content-center">
        <div className="d-flex flex-column gap-4 w-max min-w-5">
          <Button
            label="Áfram"
            className="w-100"
            primary
            onClick={() => onSubmit(selectedRentalOption)}
          />
        </div>
      </div>
    </div>
  );
};

/**
 * Reducer function for the rental type selector
 * @param {import('@/api/generated/cars').CarRentalOptionSchema} state
 * @param {{ type: string; rentalLength?: number; annualMileage?: number; rentalOptions: import('@/api/generated/cars').CarRentalOptionSchema[]; }} action
 */
const reducer = (state, action) => {
  const rentalOptions = action.rentalOptions ?? [];

  switch (action.type) {
    case "SET_RENTAL_LENGTH":
      return (
        findClosestRentalOption(rentalOptions, {
          rentalLength: action.rentalLength,
          annualMileage: state.kilometers,
          priority: "length",
        }) ?? state
      );
    case "SET_ANNUAL_MILEAGE":
      return (
        findClosestRentalOption(rentalOptions, {
          rentalLength: state.months,
          annualMileage: action.annualMileage,
          priority: "mileage",
        }) ?? state
      );
    default:
      return state;
  }
};

/**
 * @typedef {Object} FindClosestRentalOptionOptions
 * @property {number} [rentalLength]
 * @property {number} [annualMileage]
 * @property {'length' | 'mileage'} [priority]
 */

/**
 * Find closest matching rental option
 * @param {import('@/api/generated/cars').CarRentalOptionSchema[]} rentalOptions
 * @param {FindClosestRentalOptionOptions} options
 */
const findClosestRentalOption = (rentalOptions, options) => {
  const { rentalLength, annualMileage, priority = "length" } = options;

  return (
    rentalOptions.find((ro) => {
      return (
        (!rentalLength || ro.months === rentalLength) &&
        (!annualMileage || ro.kilometers === annualMileage)
      );
    }) ??
    rentalOptions.filter((ro) => {
      return priority === "length" ? ro.months === rentalLength : ro.kilometers === annualMileage;
    })[0]
  );
};
