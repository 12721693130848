import React, { useCallback, useEffect } from "react";

import { useCarCheckoutMutation } from "@/api/generated/cars";
import { Button, LoadingSpinner } from "@/components";

export const CheckoutProcessing = ({ car, accessories, buyerInfo, cardInfo, onSuccess }) => {
  const [checkout, { isLoading, isError }] = useCarCheckoutMutation();

  const onCheckout = useCallback(async () => {
    try {
      const result = await checkout({
        carId: car.id,
        carCheckoutSchema: {
          name: buyerInfo.name,
          email: buyerInfo.email,
          tel: buyerInfo.tel,
          kennitala: buyerInfo.kennitala,
          accept_terms: cardInfo.terms,
          accessories: accessories,
        },
      }).unwrap();

      if (result.status === "success") {
        onSuccess(result);
      }
    } catch (e) {
      // Fail silently
    }
  }, [checkout, car, buyerInfo, cardInfo, onSuccess, accessories]);

  useEffect(() => {
    if (isLoading) return;

    const timer = setTimeout(() => {
      onCheckout();
    }, 0);

    return () => clearTimeout(timer);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [onCheckout]);

  if (isLoading) {
    return (
      <div className="d-flex justify-content-center p-5">
        <LoadingSpinner />
      </div>
    );
  }

  if (isError) {
    return (
      <div className="d-flex flex-column gap-4 py-5">
        <p className="h4 text-primary fw-bold">Villa kom upp</p>
        <p>Eitthvað fór úrskeiðis við að vinna pöntunina þína, vinsamlegast reyndu aftur</p>
        <Button onClick={onCheckout} label="Reyna aftur" />
      </div>
    );
  }

  return null;
};
