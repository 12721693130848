import PropTypes from "prop-types";
import React from "react";
import { useSwipeable } from "react-swipeable";

import { cn } from "@/utils";

import { ChevronLeft, ChevronRight } from "@/components";

/**
 * @typedef {string | number} CarouselImageKey
 */

/**
 * @typedef {string[] | Record<string, string>} CarouselImages
 */

/**
 * Image carousel component
 * @param {Object} props
 * @param {CarouselImageKey} props.currentKey - Unique key to access the current image from the images prop
 * @param {CarouselImages} props.images - Array of image URLs or object with image URLs
 * @param {Function} props.onPrev - Function to handle previous image
 * @param {Function} props.onNext - Function to handle next image
 * @param {boolean} [props.compact] - Compact mode
 * @param {string} [props.className] - Additional class name
 * @param {React.ReactNode} [props.children]
 */
export const ImageCarousel = ({
  images,
  currentKey,
  onPrev,
  onNext,
  compact,
  className,
  children,
}) => {
  const handlers = useSwipeable({
    onSwipedLeft: onNext,
    onSwipedRight: onPrev,
    swipeDuration: 500,
    preventScrollOnSwipe: true,
    trackMouse: true,
  });

  return (
    <div className={cn("image-carousel", compact && "image-carousel--compact", className)}>
      <div className="image-carousel__wrapper">
        {onPrev && (
          <button className="image-carousel__nav left" onClick={onPrev}>
            <ChevronLeft />
            <span className="visually-hidden">Previous image</span>
          </button>
        )}
        <div className="image-carousel__images" {...handlers}>
          {Object.entries(images).map(([key, url]) => (
            <img
              key={key}
              src={url}
              className={cn(
                "image-carousel__image",
                key == currentKey && "image-carousel__image--visible",
              )}
              alt="Carousel image"
              loading="lazy"
            />
          ))}
        </div>
        {onNext && (
          <button className="image-carousel__nav right" onClick={onNext}>
            <ChevronRight />
            <span className="visually-hidden">Next image</span>
          </button>
        )}
      </div>
      {children}
    </div>
  );
};

ImageCarousel.propTypes = {
  images: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.string), PropTypes.object]).isRequired,
  currentKey: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  onPrev: PropTypes.func.isRequired,
  onNext: PropTypes.func.isRequired,
  compact: PropTypes.bool,
  className: PropTypes.string,
  children: PropTypes.node,
};

/**
 * @param {Object} props
 * @param {CarouselImageKey} props.currentKey - Unique key to access the current image from the images prop
 * @param {CarouselImages} props.images - Array of image URLs or object with image URLs
 * @param {Function} props.onImageSelect - Function to handle image select
 * @param {string} [props.className] - Additional class name
 */
export const ImageCarouselThumbnails = ({ images, currentKey, onImageSelect, className }) => {
  return (
    <div className={cn("image-carousel__thumbnails", className)}>
      {Object.entries(images).map(([key, url]) => (
        <button
          key={key}
          className={cn(
            "image-carousel__thumbnail",
            key == currentKey && "image-carousel__thumbnail--selected",
          )}
          onClick={() => onImageSelect(key)}
        >
          <img src={url} alt="Thumbnail" loading="lazy" />
        </button>
      ))}
    </div>
  );
};

ImageCarouselThumbnails.propTypes = {
  images: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.string), PropTypes.object]).isRequired,
  currentKey: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  onImageSelect: PropTypes.func.isRequired,
  className: PropTypes.string,
};

/**
 * @param {Object} props
 * @param {CarouselImageKey} props.currentKey - Unique key to access the current image from the images prop
 * @param {CarouselImages} props.images - Array of image URLs or object with image URLs
 * @param {Function} props.onImageSelect - Function to handle image select
 * @param {string} [props.className] - Additional class name
 */
export const ImageCarouselProgressBar = ({ images, currentKey, onImageSelect, className }) => {
  return (
    <div className={cn("image-carousel__progress-bar", className)}>
      {Object.entries(images).map(([key]) => (
        <button
          key={key}
          className={cn(
            "image-carousel__progress-bar-item",
            key == currentKey && "image-carousel__progress-bar-item--selected",
          )}
          onClick={() => onImageSelect(key)}
        />
      ))}
    </div>
  );
};

ImageCarouselProgressBar.propTypes = {
  images: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.string), PropTypes.object]).isRequired,
  currentKey: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  onImageSelect: PropTypes.func.isRequired,
  className: PropTypes.string,
};

/**
 * @param {Object} props
 * @param {CarouselImageKey} props.currentKey - Unique key to access the current image from the images prop
 * @param {CarouselImages} props.images - Array of image URLs or object with image URLs
 * @param {string} [props.className] - Additional class name
 */
export const ImageCarouselProgressCounter = ({ images, currentKey, className }) => {
  const currentIndex = Object.keys(images).indexOf(currentKey);

  return (
    <div className={cn("image-carousel__progress-counter", className)}>
      {currentIndex + 1} / {Object.keys(images).length}
    </div>
  );
};

ImageCarouselProgressCounter.propTypes = {
  images: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.string), PropTypes.object]).isRequired,
  currentKey: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  className: PropTypes.string,
};
