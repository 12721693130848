import { api } from "../index";
export const addTagTypes = ["service-providers"] as const;
const injectedRtkApi = api
  .enhanceEndpoints({
    addTagTypes,
  })
  .injectEndpoints({
    endpoints: (build) => ({
      serviceProvidersList: build.query<
        ServiceProvidersListApiResponse,
        ServiceProvidersListApiArg
      >({
        query: () => ({ url: `/api/service-providers/` }),
        providesTags: ["service-providers"],
      }),
    }),
    overrideExisting: false,
  });
export { injectedRtkApi as enhancedApi };
export type ServiceProvidersListApiResponse = /** status 200 OK */ ServiceProviderSchema[];
export type ServiceProvidersListApiArg = void;
export type CarMakeLogoSchema = {
  id: number;
  name: string;
  logo?: string;
};
export type ServiceProviderSchema = {
  id: number;
  name: string;
  address: string;
  zip_code?: string;
  tel: string;
  email: string;
  car_makes: CarMakeLogoSchema[];
  order_url?: string | null;
};
export const { useServiceProvidersListQuery } = injectedRtkApi;
