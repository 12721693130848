import React, { StrictMode } from "react";
import { Provider } from "react-redux";

import { store } from "@/store";
import { CarKjarabillListContainer } from "./car-kjarabill-list-container";

export const Wrapper = (props) => {
  return (
    <StrictMode>
      <Provider store={store}>
        <CarKjarabillListContainer {...props} />
      </Provider>
    </StrictMode>
  );
};
