import { useCallback, useState } from "react";

import { useCallbackRef } from "./useCallbackRef";

/**
 * A custom hook that manages state which can be controlled by a parent component or internally.
 *
 * @template T
 * @param {Object} params
 * @param {T} params.prop - The controlled value from the parent component.
 * @param {T} params.defaultProp - The default value to use when the state is uncontrolled.
 * @param {(value: T) => void} params.onChange - The callback function to call when the value changes.
 * @returns {[T, (newValue: T | ((prevValue: T) => T)) => void, boolean]} - Returns the current value, a function to update the value, and a boolean indicating if the state is controlled.
 */
export const useControllableState = ({ prop, defaultProp, onChange }) => {
  const [internalValue, setInternalValue] = useState(defaultProp);
  const isControlled = prop !== undefined;
  const value = isControlled ? prop : internalValue;
  const handleChange = useCallbackRef(onChange);

  const setValue = useCallback(
    (newValue) => {
      const value = typeof newValue === "function" ? newValue(prop) : newValue;

      if (isControlled) {
        if (newValue !== prop) handleChange?.(value);
      } else {
        if (newValue !== internalValue) {
          setInternalValue(value);
          handleChange?.(value);
        }
      }
    },
    [prop, isControlled, handleChange, internalValue],
  );

  return [value, setValue, isControlled];
};
