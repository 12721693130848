export const API_BASE_PATH = new URL(bl_BASE_URL).toString();
export const API_DEFAULT_PAGE_SIZE = 10;
export const API_AVAILABLE_PAGE_SIZES = [10, 30, 50, 100];

export const CONFIRMATION_FEE_AMOUNT = window.blConfiguration.confirmationFee;
export const TERMS_URL = window.blConfiguration.termsUrl;

export const FUEL_TYPE = /** @type {const} */ ({
  UNKNOWN: "UNKNOWN",
  ELECTRIC: "RAFMAGN",
  PETROL: "BENSIN",
  DIESEL: "DISEL",
  PETROL_HYBRID: "BENSIN_HYBRID",
  DIESEL_HYBRID: "DISEL_HYBRID",
  PETROL_PLUGIN: "BENSIN_RAFMAGN",
  DIESEL_PLUGIN: "DISEL_RAFMAGN",
  PLUGIN_HYBRID: "PLUGIN_HYBRID",
});

/**
 * @typedef {typeof FUEL_TYPE} FuelType
 */

/**
 * @type {Record<FuelType[keyof FuelType], string>}
 */
export const FUEL_TYPE_LABEL = {
  UNKNOWN: "Unknown",
  RAFMAGN: "Rafmagn",
  BENSIN: "Bensín",
  DISEL: "Dísil",
  BENSIN_HYBRID: "Bensín/Hybrid",
  DISEL_HYBRID: "Dísil/Hybrid",
  BENSIN_RAFMAGN: "Bensín/Raftengiltvinn",
  DISEL_RAFMAGN: "Dísil/Raftengiltvinn",
  PLUGIN_HYBRID: "Plug-in Hybrid",
};

export const CAR_CATEGORY = /** @type {const} */ ({
  JEPPLINGUR: "JEPPLINGUR",
  FOLKSBILL: "FOLKSBILL",
  JEPPI: "JEPPI",
  ATVINNUBILL: "ATVINNUBILL",
  TILBOD: "TILBOD"
});

/**
 * @typedef {typeof CAR_CATEGORY} CarCategory
 */

/**
 * @type {Record<CarCategory[keyof CarCategory], string>}
 */
export const CAR_CATEGORY_LABEL = {
  JEPPLINGUR: "Jepplingur",
  FOLKSBILL: "Fólksbíll",
  JEPPI: "Jeppi",
  ATVINNUBILL: "Atvinnubíll",
  TILBOD: "Tilboð"
};

export const CAR_DISPLAY_STATUS = /** @type {const} */ ({
  IN_STOCK: "IN_STOCK",
  IN_ORDER: "IN_ORDER",
  KJARABILL: "KJARABILL",
  DEMO_FOR_SALE: "DEMO_FOR_SALE",
  MODEL_ON_OFFER: "MODEL_ON_OFFER"
});

/**
 * @typedef {typeof CAR_DISPLAY_STATUS} CarDisplayStatus
 */

/**
 * @type {Record<CarDisplayStatus[keyof CarDisplayStatus], string>}
 */
export const CAR_DISPLAY_STATUS_LABEL = {
  IN_STOCK: "Til á lager",
  IN_ORDER: "Væntanlegur",
  KJARABILL: "Kjarabíll",
  DEMO_FOR_SALE: "Reynsluakstursbíll",
  MODEL_ON_OFFER: "Tilboð"
};
