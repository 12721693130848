import React from "react";

export const CloseIcon = () => {
  return (
    <svg viewBox="0 0 16 17">
      <path
        fill="#404040"
        fillRule="nonzero"
        d="M14.2361.5858L16 2.3496 9.763 8.5858 16 14.8219l-1.7639 1.7639L8 10.3488l-6.2361 6.237L0 14.8219l6.236-6.2361L0 2.3496 1.7639.5858 8 6.8218l6.2361-6.236z"
      ></path>
    </svg>
  );
};

export const LeafSvg = () => {
  return (
    <svg width="16px" height="14px" viewBox="0 0 16 14" version="1.1">
      <g id="V.2" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <g id="Bílarnir-A-12grid" transform="translate(-243, -813)" fill="#2FCD2F">
          <g id="Group-6" transform="translate(200, 481)">
            <g id="100%-Rafbíll" transform="translate(43, 329)">
              <g
                id="noun-ecology-5656984"
                transform="translate(8, 10) scale(-1, 1) translate(-8, -10)translate(0, 3.1579)"
              >
                <path
                  d="M0,0 C5.35602837,0.140591204 16.9985816,-0.866979092 14.0028369,9.46647441 C11.6425532,4.75666907 9.53191489,2.41348234 3.74468085,0.890410959 C12.6638298,4.78010094 11.8921986,9.27901947 16,12.3017304 L16,13.6842105 C14.9560284,12.9578226 14.1163121,12.0205479 13.3219858,10.5677722 C2.95035461,9.2555876 3.9035461,3.3741889 0,0 Z"
                  id="Path"
                ></path>
              </g>
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
};

export const AddToComparisonSvg = ({ className }) => {
  return (
    <svg className={className} width="17px" height="22px" viewBox="0 0 17 22" version="1.1">
      <g id="V.2" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <g
          id="Nissan-Ariya-hover"
          transform="translate(-89, -83)"
          fill="#3BADE0"
          fillRule="nonzero"
        >
          <path
            d="M98.75,83 L98.75,86 L103.5,86 C104.880712,86 106,87.1192881 106,88.5 L106,99 C106,100.380712 104.880712,101.5 103.5,101.5 L98.75,101.5 L98.75,104.5 L97.25,104.5 L97.25,83 L98.75,83 Z M98.75,100 L103.5,100 C104.052285,100 104.5,99.5522847 104.5,99 L104.5,88.5 C104.5,87.9477153 104.052285,87.5 103.5,87.5 L98.75,87.5 L98.75,100 Z M89,97.75 L89,99 C89,100.380712 90.1192881,101.5 91.5,101.5 L92.5,101.5 L92.5,100 L91.5,100 C90.9477153,100 90.5,99.5522847 90.5,99 L90.5,97.75 L89,97.75 Z M91.5,86 C90.1192881,86 89,87.1192881 89,88.5 L89,89.75 L90.5,89.75 L90.5,88.5 C90.5,87.9477153 90.9477153,87.5 91.5,87.5 L92.5,87.5 L92.5,86 L91.5,86 Z"
            id="Shape"
          ></path>
        </g>
      </g>
    </svg>
  );
};

export const CalendarSvg = () => {
  return (
    <svg width="21px" height="20px" version="1.1" id="Layer_1" x="0px" y="0px" viewBox="0 0 22 21">
      <path
        id="Shape"
        className="st0"
        d="M18.1,1.6h-0.5c-0.4,0-0.7,0.3-0.7,0.7s0.3,0.7,0.7,0.7h0.5c1.3,0,2.4,1.1,2.4,2.5v2H1.5v-2
                c0-1.4,1.1-2.5,2.4-2.5h0.6c0.4,0,0.7-0.3,0.7-0.7S4.9,1.6,4.4,1.6H3.9C1.7,1.6,0,3.3,0,5.5v11.5C0,19.2,1.7,21,3.9,21h14.2
                c2.1,0,3.9-1.8,3.9-3.9V5.5C22,3.3,20.3,1.6,18.1,1.6z M18.1,19.5H3.9c-1.3,0-2.4-1.1-2.4-2.5V9h19.1v8.1
                C20.5,18.4,19.4,19.5,18.1,19.5z"
      />
      <path
        id="Path"
        className="st0"
        d="M13.2,3.1c0.4,0,0.7-0.3,0.7-0.7s-0.3-0.7-0.7-0.7H8.8C8.4,1.6,8,1.9,8,2.3s0.3,0.7,0.7,0.7H13.2z"
      />
      <path
        id="Path_00000070111706794165108270000000194447890290619804_"
        className="st0"
        d="M16.1,3.6V0.7c0-0.4-0.3-0.7-0.7-0.7
                s-0.7,0.3-0.7,0.7v2.8c0,0.4,0.3,0.7,0.7,0.7S16.1,4,16.1,3.6z"
      />
      <path
        id="Path_00000139293889788093021690000013787296645132703360_"
        className="st0"
        d="M7.3,3.6V0.7C7.3,0.3,7,0,6.6,0
                C6.2,0,5.8,0.3,5.8,0.7v2.8c0,0.4,0.3,0.7,0.7,0.7C7,4.3,7.3,4,7.3,3.6z"
      />
    </svg>
  );
};

export const CartSvg = () => {
  return (
    <svg
      width="26.67px"
      height="20px"
      version="1.1"
      id="Layer_1"
      x="0px"
      y="0px"
      viewBox="0 0 27 20"
    >
      <path
        id="Shape"
        className="st0"
        d="M26.5,7c-0.2-0.2-0.4-0.3-0.7-0.3h-3.2l-4.2-6.3c-0.2-0.4-0.8-0.5-1.2-0.3c-0.4,0.2-0.6,0.7-0.3,1.1
                l3.7,5.5H6.2l3.7-5.5c0.2-0.4,0.1-0.8-0.3-1.1C9.1-0.1,8.6,0,8.3,0.4L4.1,6.7H0.9C0.6,6.7,0.4,6.8,0.2,7C0,7.2,0,7.4,0,7.6l3.6,11.8
                C3.7,19.8,4.1,20,4.5,20h17.7c0.4,0,0.8-0.2,0.9-0.6l3.6-11.8C26.7,7.4,26.6,7.2,26.5,7z M21.5,18.5H5.2L2,8.2h22.6L21.5,18.5z"
      />
    </svg>
  );
};

export const CompareSvg = () => {
  return (
    <svg
      version="1.1"
      id="Layer_1"
      x="0px"
      y="0px"
      viewBox="0 0 18 22"
      style="enable-background:new 0 0 18 22;"
    >
      <path
        id="Combined-Shape"
        className="st0"
        d="M7.8,0.2v3H3c-1.4,0-2.5,1.1-2.5,2.5v10.5c0,1.4,1.1,2.5,2.5,2.5h4.8v3h1.5V0.2H7.8z
                M7.8,17.2H3c-0.6,0-1-0.4-1-1V5.8c0-0.6,0.4-1,1-1h4.8V17.2z"
      />
      <path
        id="Shape"
        className="st0"
        d="M17.5,15v1.2c0,1.4-1.1,2.5-2.5,2.5h-1v-1.5h1c0.6,0,1-0.4,1-1V15H17.5z M15,3.2
                c1.4,0,2.5,1.1,2.5,2.5V7H16V5.8c0-0.6-0.4-1-1-1h-1V3.2H15z"
      />
    </svg>
  );
};

export const MyPagesSvg = () => {
  return (
    <svg
      width="20px"
      height="22.5px"
      version="1.1"
      id="Layer_1"
      x="0px"
      y="0px"
      viewBox="0 0 20 23"
    >
      <path
        id="Shape"
        className="st0"
        d="M13.5,12.5c2-1.2,3.3-3.3,3.3-5.7C16.8,3,13.8,0,10,0C6.2,0,3.2,3,3.2,6.7c0,2.4,1.3,4.6,3.3,5.7
            c-3.8,1.4-6.5,5-6.5,9.3c0,0.4,0.4,0.8,0.8,0.8c0.4,0,0.8-0.4,0.8-0.8c0-4.4,3.5-8.1,8-8.3h0c0.1,0,0.3,0,0.4,0c0.1,0,0.3,0,0.4,0h0
            c4.4,0.2,8,3.9,8,8.3c0,0.4,0.4,0.8,0.8,0.8c0.4,0,0.8-0.4,0.8-0.8C20,17.5,17.3,13.9,13.5,12.5z M10,11.9c-0.1,0-0.3,0-0.4,0
            c-2.7-0.2-4.8-2.4-4.8-5.1c0-2.8,2.3-5.1,5.2-5.1c2.9,0,5.2,2.3,5.2,5.1c0,2.7-2.1,4.9-4.8,5.1C10.3,11.9,10.1,11.9,10,11.9z"
      />
    </svg>
  );
};

export const SearchSvg = () => {
  return (
    <svg width="21px" height="21px" version="1.1" id="Layer_1" x="0px" y="0px" viewBox="0 0 21 21">
      <path
        id="Shape"
        className="st0"
        d="M9,0C4,0,0,4,0,9c0,5,4,9,9,9c2.2,0,4.1-0.8,5.7-2l4.8,4.8c0.4,0.4,0.9,0.4,1.3,0s0.4-0.9,0-1.3
                L16,14.7c1.3-1.6,2-3.5,2-5.7C18,4,14,0,9,0L9,0z M9,1.8c4,0,7.2,3.2,7.2,7.2c0,4-3.2,7.2-7.2,7.2S1.8,13,1.8,9C1.8,5,5,1.8,9,1.8z"
      />
    </svg>
  );
};

export const CheckboxSvg = () => {
  return (
    <svg width="12px" height="10px" viewBox="0 0 12 10" version="1.1">
      <g id="V.2" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <path
          d="M9.8975705,0.873275457 L11.1267245,1.8975705 L10.614577,2.51214752 L5.61457702,8.51214752 L5.05375868,9.18512953 L4.43431458,8.56568542 C4.29145743,8.42282828 4.15676355,8.2881344 4.02967318,8.16104403 L3.67065277,7.80202362 C3.3895449,7.52091575 3.15246035,7.2838312 2.95065277,7.08202362 L2.72485685,6.8562277 C2.68985976,6.82123061 2.65613382,6.78750467 2.62360904,6.75497989 L2.21707842,6.34844927 C2.12802886,6.25939971 2.05260029,6.18397114 1.98631458,6.11768542 L1.89318338,6.02455423 C1.7912242,5.92259505 1.71040787,5.84177872 1.6267344,5.75810525 L1.51569067,5.64706152 C1.48982478,5.62119563 1.462886,5.59425685 1.43431458,5.56568542 L0.86862915,5 L2,3.86862915 L2.56568542,4.43431458 C2.59425685,4.462886 2.62119563,4.48982478 2.64706152,4.51569067 L2.75810525,4.6267344 C2.84177872,4.71040787 2.92259505,4.7912242 3.02455423,4.89318338 L3.11768542,4.98631458 C3.18397114,5.05260029 3.25939971,5.12802886 3.34844927,5.21707842 L3.75497989,5.62360904 C3.78750467,5.65613382 3.82123061,5.68985976 3.8562277,5.72485685 L4.08202362,5.95065277 C4.2838312,6.15246035 4.52091575,6.3895449 4.80202362,6.67065277 L4.946,6.814 L9.38542298,1.48785248 L9.8975705,0.873275457 Z"
          id="Path-17"
          fill="#3C4043"
          fillRule="nonzero"
        ></path>
      </g>
    </svg>
  );
};

export const PlusIcon = () => {
  return (
    <svg width="28px" height="28px" viewBox="0 0 28 28" version="1.1">
      <g id="V.2" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <g id="Flex-einstaklingar" transform="translate(-1389, -5240)" fill="#103483">
          <g id="Group-8" transform="translate(403, 4386)">
            <path
              d="M1001,854 L1001,867 L1014,867 L1014,869 L1001,869 L1001,882 L999,882 L999,869 L986,869 L986,867 L999,867 L999,854 L1001,854 Z"
              id="Combined-Shape-Copy-4"
            ></path>
          </g>
        </g>
      </g>
    </svg>
  );
};

export const ChevronLeft = ({ className }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0 24 24"
      strokeWidth={1.5}
      stroke="currentColor"
      className={className}
    >
      <path strokeLinecap="round" strokeLinejoin="round" d="M15.75 19.5 8.25 12l7.5-7.5" />
    </svg>
  );
};

export const ChevronRight = ({ className }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0 24 24"
      strokeWidth={1.5}
      stroke="currentColor"
      className={className}
    >
      <path strokeLinecap="round" strokeLinejoin="round" d="m8.25 4.5 7.5 7.5-7.5 7.5" />
    </svg>
  );
};

export const FilterIcon = () => {
  return (
    <svg
      id="Layer_1"
      data-name="Layer 1"
      xmlns="http://www.w3.org/2000/svg"
      version="1.1"
      viewBox="0 0 19.4 11.9"
      width="25px"
      height="25px"
    >
      <g id="V.2">
        <path
          id="Shape"
          className="cls-1"
          fill="#313e3e"
          strokeWidth="0px"
          d="M12.3,10c.5,0,.9.4.9.9s-.4.9-.9.9h-5.1c-.5,0-.9-.4-.9-.9s.4-.9.9-.9h5.1ZM18.4,0c.5,0,1,.4,1,1s-.4,1-1,1H1c-.5,0-1-.4-1-1s.4-1,1-1h17.5ZM15.7,5c.6,0,1,.4,1,1s-.4,1-1,1H3.7c-.6,0-1-.4-1-1s.4-1,1-1h12Z"
        />
      </g>
    </svg>
  );
};

export const CloseFilterIcon = () => {
  return (
    <svg width="20px" height="20px" viewBox="0 0 16 16" version="1.1">
      <g id="V.2" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <g id="Bílarnir-A-ítarleit-12grid-filter" transform="translate(-398, -416)">
          <g id="Group" transform="translate(200, 368)">
            <g id="Group-6-Copy" transform="translate(198, 48)">
              <circle id="Oval" fill="#3BADE0" cx="8" cy="8" r="8"></circle>
              <polygon
                id="Path"
                fill="#FFFFFF"
                fillRule="nonzero"
                points="10.5 4.79289322 11.2071068 5.5 8.70689322 7.99989322 11.2071068 10.5 10.5 11.2071068 7.99989322 8.70689322 5.5 11.2071068 4.79289322 10.5 7.29289322 7.99989322 4.79289322 5.5 5.5 4.79289322 7.99989322 7.29289322"
              ></polygon>
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
};

export const ArrowLeft = ({ className }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 20 20"
      fill="currentColor"
      className={className}
    >
      <path
        fillRule="evenodd"
        d="M18 10a.75.75 0 0 1-.75.75H4.66l2.1 1.95a.75.75 0 1 1-1.02 1.1l-3.5-3.25a.75.75 0 0 1 0-1.1l3.5-3.25a.75.75 0 1 1 1.02 1.1l-2.1 1.95h12.59A.75.75 0 0 1 18 10Z"
        clipRule="evenodd"
      />
    </svg>
  );
};

export const ArrowRight = ({ className }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 20 20"
      fill="currentColor"
      className={className}
    >
      <path
        fillRule="evenodd"
        d="M2 10a.75.75 0 0 1 .75-.75h12.59l-2.1-1.95a.75.75 0 1 1 1.02-1.1l3.5 3.25a.75.75 0 0 1 0 1.1l-3.5 3.25a.75.75 0 1 1-1.02-1.1l2.1-1.95H2.75A.75.75 0 0 1 2 10Z"
        clipRule="evenodd"
      />
    </svg>
  );
};
export const BlueExit = ({ className }) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
    <path d="M8 16C12.4183 16 16 12.4183 16 8C16 3.58172 12.4183 0 8 0C3.58172 0 0 3.58172 0 8C0 12.4183 3.58172 16 8 16Z" fill="#103483"/>
    <path d="M10.4962 4.79297L11.2033 5.50008L8.70306 7.99997L11.2033 10.5001L10.4962 11.2072L7.99606 8.70697L5.49617 11.2072L4.78906 10.5001L7.28906 7.99997L4.78906 5.50008L5.49617 4.79297L7.99606 7.29297L10.4962 4.79297Z" fill="white"/>
    </svg>
  );
};
