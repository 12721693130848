import { useCallback, useSyncExternalStore } from "react";

/**
 * Hook that checks if a media query matches
 * @param {string} query
 * @returns {boolean}
 */
export const useMediaQuery = (query) => {
  const subscribe = useCallback(
    (callback) => {
      const matchMedia = window.matchMedia(query);

      matchMedia.addEventListener("change", callback);
      return () => {
        matchMedia.removeEventListener("change", callback);
      };
    },
    [query],
  );

  const getSnapshot = () => {
    return window.matchMedia(query).matches;
  };

  return useSyncExternalStore(subscribe, getSnapshot);
};
