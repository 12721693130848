import React, { useCallback, useState } from "react";

import { CustomerInfoForm, Tab, Tabs } from "@/components";

import { CarRentalAccessories } from "./CarRentalAccessories";
import { CarRentalType } from "./CarRentalType";
import { RentalSummary } from "./RentalSummary";

/**
 * @param {Object} props
 * @param {import('@/api/generated/cars').CarDetailSchema} props.car - The car to checkout
 */
export const CarLongTermRental = ({ car }) => {
  const [currentTab, setCurrentTab] = useState(0);
  const [selectedRentalOption, setSelectedRentalOption] = useState(null);
  const [selectedAccessories, setSelectedAccessories] = useState([]);
  const [customerInfo, setCustomerInfo] = useState(null);

  const nextStep = useCallback(() => {
    setCurrentTab((ct) => ct + 1);
  }, []);

  const onRentalTypeFormSubmit = useCallback(
    (data) => {
      setSelectedRentalOption(data);
      nextStep();
    },
    [nextStep],
  );

  const onRentalAccessoriesFormSubmit = useCallback(
    (data) => {
      setSelectedAccessories(data);
      nextStep();
    },
    [nextStep],
  );

  const onCustomerInfoFormSubmit = useCallback(
    (data) => {
      setCustomerInfo(data);
      nextStep();
    },
    [nextStep],
  );

  return (
    <Tabs currentTab={currentTab} onTabChange={setCurrentTab} className="my-5" compact>
      <Tab title="Leigutími og akstur">
        <CarRentalType car={car} onSubmit={onRentalTypeFormSubmit} />
      </Tab>
      {/*
      NOTE: 
      when uncomment update RentalSummary below and set selectedAccessories=selectedRentalOption
      ALSO: open RentalSummary.js and remove corresponding commented section there
       <Tab title="Aukabúnaður" 
      disabled={(index, currentIndex) => currentIndex < index}>
        <CarRentalAccessories
          car={car}
          selectedRentalOption={selectedRentalOption}
          onSubmit={onRentalAccessoriesFormSubmit}
        />
      </Tab> */}
      <Tab title="Leigutaki" disabled={(index, currentIndex) => currentIndex < index}>
        <CustomerInfoForm onSubmit={onCustomerInfoFormSubmit} rental={true} />
      </Tab>
      <Tab title="Samantekt og umsókn" disabled={(index, currentIndex) => currentIndex < index}>
        <RentalSummary
          car={car}
          selectedRentalOption={selectedRentalOption}
          customerInfo={customerInfo}
          selectedAccessories={{accessories:[]}}
        />
      </Tab>
    </Tabs>
  );
};
