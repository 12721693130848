import { format } from "date-fns";
import React from "react";
import { Controller, useForm } from "react-hook-form";

import { useBookCarTestDriveMutation } from "@/api/generated/bookings";
import { Button, Calendar, CloseIcon, Dropdown, LoadingSpinner } from "@/components";

/**
 * Form to book a test drive for a car
 * @param {Object} props
 * @param {import("@/api/generated/cars").CarDetailSchema} props.car - Car to book a test drive for
 * @param {() => void} props.onCloseBookingForm - Callback to close the booking form
 */
export const TestDriveBooking = ({ car, onCloseBookingForm }) => {
  const [bookTestDrive, { isLoading, isSuccess, isError }] = useBookCarTestDriveMutation();
  const {
    register,
    control,
    handleSubmit,
    formState: { errors },
  } = useForm();

  if (isSuccess) {
    return <p className="alert alert-success text-center my-5">Bókun hefur verið móttekin</p>;
  }

  return (
    <form onSubmit={handleSubmit(bookTestDrive)} className="d-flex flex-column gap-4">
      <div className="d-flex flex-column gap-2">
        <div className="d-flex align-items-center justify-content-between">
          <h3>Bóka reynsluakstur</h3>
          <button
            type="button"
            onClick={onCloseBookingForm}
            className="btn-close"
            aria-label="Close"
          >
            <CloseIcon />
          </button>
        </div>
        <div className="d-flex justify-content-between align-items-center mb-3">
          <label htmlFor="date">Veldu dagsetningu</label>
        </div>
        <Controller
          name="date"
          control={control}
          rules={{ required: true }}
          render={({ field }) => (
            <Calendar
              mode="single"
              disabled={[
                {
                  dayOfWeek: [0, 6],
                },
                {
                  before: new Date(),
                },
              ]}
              required
              name={field.name}
              selected={field.value}
              onSelect={(date) => field.onChange(format(date, "yyyy-MM-dd"))}
            />
          )}
        />
        {errors.date && <span className="text-danger">Þessi reitur er nauðsynlegur</span>}
      </div>

      <div className="d-flex flex-column gap-2">
        <Controller
          name="time"
          control={control}
          rules={{ required: true }}
          render={({ field }) => (
            <Dropdown
              placeholder="Veldu tíma"
              options={timeOptions}
              name="time"
              value={field.value}
              onChange={field.onChange}
              onBlur={field.onBlur}
              selectClassName="form-control"
            />
          )}
        />
        {errors.time && <span className="text-danger">Þessi reitur er nauðsynlegur</span>}
      </div>

      <hr className="w-15 mx-auto text-secondary border-2 opacity-100" />

      <div className="d-flex flex-column gap-2">
        <label htmlFor="name">Nafn</label>
        <input
          type="text"
          autoComplete="name"
          id="name"
          className="form-control form-control-lg"
          {...register("name", { required: true })}
        />
        {errors.name && <span className="text-danger">Þessi reitur er nauðsynlegur</span>}
      </div>

      <div className="d-flex flex-column gap-2">
        <label htmlFor="phone">Sími</label>
        <input
          type="tel"
          autoComplete="tel"
          id="phone"
          className="form-control form-control-lg"
          {...register("tel", { required: true })}
        />
        {errors.tel && <span className="text-danger">Þessi reitur er nauðsynlegur</span>}
      </div>

      <div className="d-flex flex-column gap-2">
        <label htmlFor="email">Netfang</label>
        <input
          type="email"
          autoComplete="email"
          id="email"
          className="form-control form-control-lg"
          {...register("email", { required: true })}
        />
        {errors.email && <span className="text-danger">Þessi reitur er nauðsynlegur</span>}
      </div>

      <input type="hidden" value={car.make} {...register("car_make")} />
      <input type="hidden" value={car.model} {...register("car_model")} />

      <Button
        type="submit"
        primary
        size="CTA"
        label="Bóka reynsluakstur"
        disabled={isLoading}
        icon={isLoading && <LoadingSpinner className="text-white m-2" style={{ width: "2rem" }} />}
        className="trial-booking capitalize"
      />

      {isError && (
        <p className="alert alert-danger text-center">
          Eitthvað fór úrskeiðis, vinsamlegast reyndu aftur
        </p>
      )}
    </form>
  );
};

const timeOptions = [
  { label: "09:00", value: "09:00" },
  { label: "10:00", value: "10:00" },
  { label: "11:00", value: "11:00" },
  { label: "12:00", value: "12:00" },
  { label: "13:00", value: "13:00" },
  { label: "14:00", value: "14:00" },
  { label: "15:00", value: "15:00" },
  { label: "16:00", value: "16:00" },
  { label: "17:00", value: "17:00" },
];
