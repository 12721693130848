import { useEffect, useMemo, useRef } from "react";

/**
 * A custom hook that converts a callback to a ref to avoid triggering re-renders when passed as a prop or avoid re-executing effects when passed as a dependency
 * @template T
 * @param {T | undefined} callback - The callback to convert to a ref
 * @returns {T} The callback
 */
export const useCallbackRef = (callback) => {
  const callbackRef = useRef(callback);

  useEffect(() => {
    callbackRef.current = callback;
  });

  // https://github.com/facebook/react/issues/19240
  return useMemo(
    () =>
      (...args) =>
        callbackRef.current?.(...args),
    [],
  );
};
