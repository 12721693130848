import React from "react";

import { Button, CloseFilterIcon, Pill } from "@/components";
import { cn, formatNumber } from "@/utils";

import "./CarComparisonTable.scss";

/**
 * @typedef {Object} CategoryValues
 * @property {string} label - Label for the table row
 * @property {keyof import("@/api/generated/cars").CarDetailSchema} value - Key to get the value from the car object
 * @property {(value: any) => string} [formatter] - Optional function to format the value
 * @property {string} [unit] - Optional unit to display after the value
 * @property {string} [labelClassName]
 * @property {string} [valueClassName]
 */

/**
 * @typedef {Object} Category
 * @property {string} name
 * @property {CategoryValues[]} content
 */

/**
 * @type {Category[]}
 */
const categories = [
  {
    name: "Verðupplýsingar",
    content: [
      {
        label: "Verð",
        value: "price",
        formatter: formatNumber,
        unit: "kr.",
        valueClassName: "fs-5 text-primary fw-bold",
      },
    ],
  },
  {
    name: "Vél og afköst",
    content: [
      {
        label: "Eldsneyti",
        value: "fuelType",
      },
      {
        label: "Skipting",
        value: "transmission",
      },
      {
        label: "Drif",
        value: "drive",
      },
      {
        label: "Hámarks hestöfl (Hö)",
        value: "horsepower",
      },
      {
        label: "Hröðun (0-100 Km/Klst.)",
        value: "acceleration",
      },
      {
        label: "Hámarkshraði",
        value: "top_speed",
      },
      {
        label: "Drægi",
        value: "range",
      },
      {
        label: "Stærð rafhlöðu",
        value: "battery_capacity",
      },
    ],
  },
  {
    name: "Eyðsla og útblástur",
    content: [
      {
        label: "Eyðsla á 100km",
        value: "consumption",
      },
      {
        label: "Emission Standard",
        value: "co2_emission",
      },
    ],
  },
  {
    name: "Helstu mál",
    content: [
      {
        label: "Heildarlengd",
        value: "length",
      },
      {
        label: "Heildarbreidd",
        value: "width",
      },
      {
        label: "Heildarhæð",
        value: "height",
      },
      {
        label: "Hjólhaf",
        value: "wheel_base",
      },
      {
        label: "Eiginþyngd",
        value: "kerb_weight",
      },
      {
        label: "Farangursrými",
        value: "luggage_compartment_max",
      },
    ],
  },
];

/**
 * @param {Object} props
 * @param {import("@/api/generated/cars").CarDetailSchema[]} props.cars
 *  @param {(id: string) => void} props.onRemove
 * @param {string} [props.className]
 */
export const CarComparisonTable = ({ cars, onRemove, className }) => {
  return (
    <div
      className={cn(
        "position-relative w-full overflow-auto themed-scroller py-3 car-comparison-table-wrapper",
        className,
      )}
    >
      <table className="car-comparison-table">
        <thead>
          <tr>
            <th className="car-comparison-table__header" />
            {cars.map((car) => (
              <th key={car.id} className="car-comparison-table__header">
                <Button
                  label="Sleppa"
                  icon={<CloseFilterIcon />}
                  noBorder
                  className="px-0 py-2 h-auto d-flex flex-row-reverse fw-normal ms-auto"
                  onClick={() => onRemove(car.id)}
                />
                <a className="text-inherit" href={`/nyir-bilar/${car.make}/${car.model}`}>
                  {`${car.make} ${car.model} ${car.variant}`}
                </a>
              </th>
            ))}
          </tr>
        </thead>
        <tbody>
          <tr className="car-comparison-table__image-row nohover">
            <td className="car-comparison-table__label transparent" />
            {cars.map((car) => (
              <td key={car.id}>
                <img
                  src={car.image}
                  alt={`${car.make} ${car.model} ${car.variant}`}
                  className="car-comparison-table__image"
                />
              </td>
            ))}
          </tr>
          <tr className="car-comparison-table__availability-row nohover">
            <td className="car-comparison-table__label transparent" />
            {cars.map((car) => (
              <td key={car.id} className="car-comparison-table__availability">
                <div className="d-flex flex-row align-items-center justify-content-end">
                  {car.available && <Pill label="Til á lager" className="w-fit" />}
                </div>
              </td>
            ))}
          </tr>
          {categories.map((category) => (
            <React.Fragment key={category.name}>
              <tr className="nohover">
                <td colSpan={cars.length + 1} className="car-comparison-table__category">
                  <span className="car-comparison-table__category-name ">{category.name}</span>
                </td>
              </tr>
              {category.content.map((content) => (
                <tr key={content.label} className="car-comparison-table__value-row">
                  <td className={cn("car-comparison-table__label", content.labelClassName)}>
                    {content.label}
                  </td>
                  {cars.map((car) => (
                    <td
                      key={car.id}
                      className={cn("car-comparison-table__value", content.valueClassName)}
                    >
                      {car[content.value] ? (
                        <>
                          {content.formatter
                            ? content.formatter(car[content.value])
                            : car[content.value]}
                          {content.unit && (
                            <span className="car-comparison-table__unit">{content.unit}</span>
                          )}
                        </>
                      ) : (
                        "-"
                      )}
                    </td>
                  ))}
                </tr>
              ))}
            </React.Fragment>
          ))}
        </tbody>
      </table>
    </div>
  );
};
