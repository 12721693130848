import PropTypes from "prop-types";
import React from "react";

import { FaqQuestion } from "../faq_question/FaqQuestion";

export const FAQ = ({ title, questions }) => {
  return (
    <div className="question-list row justify-content-center clamped single-question mb-50">
      <div className="row justify-content-center">
        <div className="col-xl-8 col-md-12 col-14">
          <h2 className="demi-weight title">{title}</h2>
        </div>
        {questions.map((question, index) => {
          return <FaqQuestion key={index} q={question} />;
        })}
      </div>
    </div>
  );
};

FAQ.propTypes = {
  title: PropTypes.string,
  questions: PropTypes.array,
};
