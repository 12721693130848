import React from "react";

import { LoadingSpinner } from "@/components";
import { formatNumber } from "@/utils";

/**
 * @param {Object} props
 * @param {import('@/api/generated/cars').CarDetailSchema} props.car - The car to display
 * @param {import('@/api/generated/cars').CarRentalOptionSchema} props.selectedRentalOption - The selected rental option
 */
export const SelectedCarInfo = ({ car, selectedRentalOption }) => {
  if (!car || !selectedRentalOption) {
    return (
      <div className="d-flex justify-content-center align-items-center py-5">
        <LoadingSpinner />
      </div>
    );
  }

  return (
    <div>
      <div className="d-flex flex-wrap flex-md-nowrap justify-content-center align-items-center gap-4 mt-3">
        <img src={car.image} alt={car.make} className="img-fluid" style={{ width: "200px" }} />
        <div className="d-flex gap-4 flex-grow-1">
          <div className="me-auto">
            <p className="m-0 text-secondary fw-bold">{car.make}</p>
            <p className="m-0 h4 text-primary fw-bold">
              {car.model} {car.variant}
            </p>
          </div>
          <div className="d-flex align-items-center gap-4">
            <div>
              <p className="m-0 text-uppercase text-secondary text-xsmall fw-bold">Leigutími</p>
              <p className="m-0 h4 text-primary fw-bold">
                {selectedRentalOption.months}
                <span className="fs-6"> mánuðir</span>
              </p>
            </div>
            <div>
              <p className="m-0 text-uppercase text-secondary text-xsmall fw-bold">Akstur á ári</p>
              <p className="m-0 h4 text-primary fw-bold">
                {formatNumber(selectedRentalOption.kilometers)}
                <span className="fs-6"> km</span>
              </p>
            </div>
            <div>
              <p className="m-0 text-uppercase text-secondary text-xsmall fw-bold">Verð á mánuði</p>
              <p className="m-0 h4 text-primary fw-bold">
                {formatNumber(selectedRentalOption.price)}
                <span className="fs-6"> kr.</span>
              </p>
            </div>
          </div>
        </div>
      </div>
      <hr className="mb-5" />
    </div>
  );
};
