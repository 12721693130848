import { useCallback, useState } from "react";

import { useEventListener } from "./useEventListener";

const LOCAL_STORAGE_EVENT_NAME = "local-storage";
const serialize = (value) => JSON.stringify(value);
const deserialize = (value) => JSON.parse(value);

/**
 * Hook that uses local storage to sync and persist state across different react apps, tabs and page reloads
 * @template T
 * @param {string} key - Key to store the value in local storage
 * @param {T | (() => T)} initialValue - Initial value to use if no value is found in local storage
 * @returns {[T, (value: T | ((val: T) => T)) => void, () => void]} - Tuple containing the stored value, a function to set the value and a function to remove the value
 */
export const useLocalStorage = (key, initialValue) => {
  const readValue = useCallback(() => {
    if (typeof window === "undefined") {
      return initialValue;
    }

    try {
      const item = window.localStorage.getItem(key);
      return item ? deserialize(item) : initialValue;
    } catch (error) {
      console.warn(`Error reading localStorage key “${key}”:`, error);
      return initialValue;
    }
  }, [initialValue, key]);

  const [storedValue, setStoredValue] = useState(readValue);

  const setValue = useCallback(
    (value) => {
      if (typeof window == "undefined") {
        console.warn(`Cannot set localStorage key “${key}” because window is undefined`);
      }

      try {
        const newValue = value instanceof Function ? value(storedValue) : value;

        window.localStorage.setItem(key, serialize(newValue));

        setStoredValue(newValue);

        window.dispatchEvent(new StorageEvent(LOCAL_STORAGE_EVENT_NAME, { key }));
      } catch (error) {
        console.warn(`Error setting localStorage key “${key}”:`, error);
      }
    },
    [key, storedValue],
  );

  const removeValue = useCallback(() => {
    if (typeof window === "undefined") {
      console.warn(`Cannot remove localStorage key “${key}” because window is undefined`);
    }

    try {
      window.localStorage.removeItem(key);
      window.dispatchEvent(new StorageEvent(LOCAL_STORAGE_EVENT_NAME, { key }));
    } catch (error) {
      console.warn(`Error removing localStorage key “${key}”:`, error);
    }
  }, [key]);

  const handleStorageChange = useCallback(
    (event) => {
      if (event.key === key) {
        setStoredValue(readValue());
      }
    },
    [key, readValue],
  );

  // "storage" event is triggered when change was made by other document
  useEventListener("storage", handleStorageChange, window);

  // Custom event is triggered when change was made by the same document
  useEventListener(LOCAL_STORAGE_EVENT_NAME, handleStorageChange, window);

  return [storedValue, setValue, removeValue];
};
