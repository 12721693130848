import React from "react";

import { cn } from "@/utils";
import "./LoadingSpinner.scss";

export const LoadingSpinner = ({ className, ...props }) => {
  return (
    <div className={cn("loading-spinner", className)} role="status" {...props}>
      <div className="loading-spinner__spinner" />
      <p className="visually-hidden">Hleðsla</p>
    </div>
  );
};
