import React from "react";
import ReactDOM from "react-dom/client";

import { Wrapper } from "./wrapper";

function carKjarabillListContainer(element) {
  const options = element.dataset;
  const root = ReactDOM.createRoot(element);
  root.render(React.createElement(Wrapper, options));
}

document.addEventListener("DOMContentLoaded", function () {
  const elements = document.querySelectorAll("[data-car-kjarabill-list-container]");
  elements.forEach(carKjarabillListContainer);
});
