import React, { useCallback, useEffect, useState } from "react";

import { useNewsListQuery } from "@/api/generated/news";
import { LoadingSpinner } from "@/components";

const PAGE_SIZE = 18;

function NewsList() {
  /** @type {ReturnType<typeof useState<import('@/api/generated/news').NewsSchema[]>} */
  const [newsItems, setNewsItems] = useState([]);
  const [offset, setOffset] = useState(0);
  const [hasMore, setHasMore] = useState(true);

  const { data, isLoading, isFetching, isError } = useNewsListQuery({ limit: PAGE_SIZE, offset });

  useEffect(() => {
    if (data) {
      setNewsItems((prevItems) => [...prevItems, ...data.items]);
      setHasMore(data.count > offset + PAGE_SIZE);
    }
  }, [data, offset]);

  const handleLoadMore = useCallback(() => {
    setOffset((prevOffset) => prevOffset + PAGE_SIZE);
  }, []);

  if (isLoading) {
    return (
      <div className="d-flex justify-content-center">
        <LoadingSpinner />
      </div>
    );
  }

  if (isError && !data) {
    return <p className="alert alert-danger text-center">Villa við að sækja fréttir</p>;
  }

  return (
    <>
      <div className="row">
        {newsItems.map((item) => (
          <div
            key={item.id}
            className="col-xl-4 col-md-6 col-sm-12 col-12 d-flex d-md-block justify-content-center"
          >
            <a href={item.url}>
              <div className="news-item position-relative">
                <div className="news-item-image position-relative">
                  <img className="img-fluid" src={item.card_image} alt={item.title} />
                </div>
                <div className="news-item-content position-absolute">
                  <div className="news-item-date">{item.first_published_at}</div>
                  <h3 className="news-item-title">{item.title}</h3>
                </div>
              </div>
            </a>
          </div>
        ))}
      </div>
      {isFetching && <p>Sæki fleiri fréttir...</p>}
      {hasMore && !isError && (
        <div className="row justify-content-center">
          <div className="more-btn col-xl-2 col-lg-3 col-md-4 col-6">
            <button
              className="button button--primary button--large"
              onClick={handleLoadMore}
              disabled={isFetching}
            >
              Fleiri fréttir
            </button>
          </div>
        </div>
      )}
    </>
  );
}

export default NewsList;
