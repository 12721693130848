import React, { useEffect, useState } from "react";

import { useCarDetailQuery, usePurchaseOrderDetailQuery } from "@/api/generated/cars";
import { CarCheckout, LoadingSpinner } from "@/components";

export const CarCheckoutContainer = ({ carId, orderNo }) => {
  const [pollingInterval, setPollingInterval] = useState(1000);

  const { data, error, isLoading } = useCarDetailQuery({
    carId,
  });
  const {
    data: purchaseOrderData,
    error: purchaseOrderError,
    isLoading: purchaseOrderIsLoading,
  } = usePurchaseOrderDetailQuery(
    {
      orderNo,
    },
    { skip: !orderNo, pollingInterval: pollingInterval, skipPollingIfUnfocused: true },
  );

  useEffect(() => {
    if (
      purchaseOrderData &&
      purchaseOrderData.confirmation_fee_paid === true &&
      purchaseOrderData.order_sent_to_crm === true &&
      pollingInterval !== 0
    ) {
      setPollingInterval(0);
    }
  }, [purchaseOrderData, pollingInterval]);

  if (isLoading || purchaseOrderIsLoading || (orderNo && pollingInterval !== 0)) {
    return <LoadingIndicator />;
  }

  if (error || purchaseOrderError) {
    // Check if the error is a fetch error
    // We do a redirect when navigating to the payment page which causes a fetch error
    if (!error.status === "FETCH_ERROR") {
      return <ErrorIndicator />;
    }
  }

  if ((!data && !orderNo) || (orderNo && !purchaseOrderData)) {
    return <NoResultsIndicator />;
  }

  return <CarCheckout car={data} purchaseOrder={purchaseOrderData} />;
};

const LoadingIndicator = () => (
  <div className="d-flex justify-content-center p-5">
    <LoadingSpinner />
  </div>
);

const ErrorIndicator = () => {
  return (
    <div className="alert alert-danger text-center" role="alert">
      Villa kom upp, vinsamlegast reyndu aftur síðar.
    </div>
  );
};

const NoResultsIndicator = () => (
  <div className="alert alert-warning text-center" role="alert">
    Bíll fannst ekki
  </div>
);
