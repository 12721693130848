import React, { useCallback } from "react";

import { useCarAccessoriesQuery } from "@/api/generated/cars";
import { LoadingSpinner } from "@/components";
import { useControllableState } from "@/hooks";
import { cn, formatNumber } from "@/utils";

/**
 * @param {Object} props
 * @param {number} props.carId - ID of the car to load accessories for
 * @param {number[]} [props.selectedAccessories] - IDs of the selected accessories
 * @param {Function} [props.onSelectedAccessoriesChange] - Callback for when the selected accessories change
 */
export const CarAccessoriesSelector = ({
  carId,
  selectedAccessories: selectedAccessoriesProp,
  onSelectedAccessoriesChange,
}) => {
  const { data: accessories, isLoading, isError } = useCarAccessoriesQuery({ carId });
  const [selectedAccessories, setSelectedAccessories] = useControllableState({
    prop: selectedAccessoriesProp,
    onChange: onSelectedAccessoriesChange,
    defaultProp: [],
  });

  const isSelected = useCallback(
    (accessory) => selectedAccessories.includes(accessory.id),
    [selectedAccessories],
  );

  const onAccessoryToggle = useCallback(
    (accessory) => {
      setSelectedAccessories((selected) =>
        isSelected(accessory)
          ? selected.filter((id) => id !== accessory.id)
          : [...selected, accessory.id],
      );
    },
    [isSelected, setSelectedAccessories],
  );
  {accessories?.map((accessory) => {
    return (
      <div key={accessory.id}>
        {accessory.description}
      </div>
    );
  })}

  return (
    <div>
      {!isLoading && !isError && accessories?.length === 0 ? null :
      (
        <p className="h4 text-primary fw-bold">Helsti aukabúnaður</p>
      )}
      {isLoading && (
        <div className="d-flex justify-content-center m-3">
          <LoadingSpinner />
        </div>
      )}
      {/* {!isLoading && !isError && accessories?.length === 0 && (
        <p className="alert alert-info text-center fs-6">Þessi bíll er ekki með neinn aukabúnað.</p>
      )} */}
      {isError && (
        <p className="alert alert-danger text-center fs-6">Villa kom upp við að sækja aukabúnað.</p>
      )}
      <div className="d-grid grid-cols-1 grid-cols-lg-2 row-gap-4 column-gap-5 mt-3">
        {accessories?.map((accessory) => (
          <div
            key={accessory.id}
            className={cn("card w-100", isSelected(accessory) && "border-secondary")}
            onClick={() => onAccessoryToggle(accessory)}
            role="button"
          >
            <div className="card-body d-flex flex-row align-items-center gap-2rem">
              <input
                type="checkbox"
                checked={isSelected(accessory)}
                readOnly
                style={{ width: "1.5rem", height: "1.5rem" }}
              />
              <img src={accessory.image}  alt={accessory.description2 ? accessory.description2 : accessory.description} className="img-fluid mb-3" />
              <div className="flex-grow-1 h-100 d-flex flex-column justify-content-between gap-3">
                {accessory.description2 ? (
                  <p className="m-0 fs-6 text-primary fw-bold">{accessory.description2}</p>
                ) : (
                  <p className="m-0 fs-6 text-primary fw-bold">{accessory.description}</p>
                )}
                <div>
                  <p className="m-0 text-secondary text-uppercase text-xsmall fw-bold">Verð</p>
                  <p className="m-0 text-primary fw-bold">
                    {formatNumber(accessory.unit_price)}
                    <span className="text-small"> kr.</span>
                  </p>
                </div>
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};
