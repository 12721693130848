import React from "react";

import { cn } from "@/utils";

/**
 * @param {Object} props
 * @param {string} props.label - Text to display in the pill
 * @param {'blue' | 'red' | 'green' | 'orange'} [props.color='blue'] - Color of the pill
 * @param {string} [props.className]
 */
export const Pill = ({ label, colorInverted = false, color = "blue", className, ...props }) => {
  return (
    <div className={cn("pill", `pill--${color}`, colorInverted ? "color-inverted" : "", className)} {...props}>
      <div className="pill__label text-uppercase font-bold text-nowrap">{label}</div>
    </div>
  );
};
