import React from "react";
import { Provider } from "react-redux";
import { store } from "../../store";
import ServiceProviders from "../../components/service_providers";

export default function Wrapper(props) {
  return (
    <Provider store={store}>
      <ServiceProviders {...props} />
    </Provider>
  );
}
