import React, { useCallback } from "react";

import { Button, CloseFilterIcon } from "@/components";
import { useComparisonCarsIds } from "@/hooks";


/**
 * A small card to show a car in the comparison bar
 * @param {Object} props
 * @param {import('@/api/generated/cars').CarCompareSchema} props.car
 */
export const CarComparisonCard = ({ car }) => {
  const { setComparisonCarsIds } = useComparisonCarsIds();

  const removeCarFromComparison = useCallback(() => {
    setComparisonCarsIds((prev) => prev.filter((id) => id !== car.id));
  }, [car.id, setComparisonCarsIds]);

  return (
    <div className="col-auto ">
      <div className="car-comparison-card">
        <img src={car.image} alt={car.model} height={64} className="car-comparison-card__image" />
        <div>
          <p className="car-comparison-card__title">{`${car.make} ${car.model}`}</p>
          <p className="car-comparison-card__title text-small">{`${car.variant}`}</p>
        </div>
        <Button
          onClick={removeCarFromComparison}
          icon={<CloseFilterIcon />}
          noBorder
          aria-label="Remove car from comparison"
          className="car-comparison-card__remove"
        />
      </div>
    </div>
  );
}
