import React from "react";

import { Pill } from "@/components";
import { CAR_DISPLAY_STATUS, CAR_DISPLAY_STATUS_LABEL } from "@/const";

/**
 * @param {Object} props
 * @param {CAR_DISPLAY_STATUS[keyof typeof CAR_DISPLAY_STATUS]} props.status
 */
export const CarStatusBadge = ({ status }) => {
  switch (status) {
    case CAR_DISPLAY_STATUS.IN_STOCK:
      return <Pill label={CAR_DISPLAY_STATUS_LABEL.IN_STOCK} color="blue" />;
    case CAR_DISPLAY_STATUS.IN_ORDER:
      return <Pill label={CAR_DISPLAY_STATUS_LABEL.IN_ORDER} color="orange" />;
    case CAR_DISPLAY_STATUS.KJARABILL:
      return <Pill label={CAR_DISPLAY_STATUS_LABEL.KJARABILL} color="red" />;
    case CAR_DISPLAY_STATUS.DEMO_FOR_SALE:
      return <Pill label={CAR_DISPLAY_STATUS_LABEL.DEMO_FOR_SALE} color="blue" />;
    case CAR_DISPLAY_STATUS.MODEL_ON_OFFER:
      return <Pill label={CAR_DISPLAY_STATUS_LABEL.MODEL_ON_OFFER} color="red" colorInverted={true} />;
  }
};
