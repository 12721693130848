import React from "react";
import { useForm } from "react-hook-form";

import { formatNumber } from "@/utils";
import { Button } from "@/components";
import { CONFIRMATION_FEE_AMOUNT, TERMS_URL } from "@/const";

/**
 * @param {Object} props
 * @param {Function} props.onSubmit
 */
export const ConfirmationFee = ({ onSubmit }) => {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();

  return (
    <>
      <div className="d-flex flex-row justify-content-between gap-4 py-3 mt-3">
        <p className="m-0 fw-medium">Staðfestingargjald</p>
        <p className="m-0 h4 text-primary fw-bold">
          {formatNumber(CONFIRMATION_FEE_AMOUNT)}
          <span className="fs-6"> kr</span>
        </p>
      </div>
      <hr />
      <form className="row" onSubmit={handleSubmit(onSubmit)}>
        <div className="col-12 mb-5">
          <div className="form-check">
            <input
              type="checkbox"
              id="terms"
              className="form-check-input"
              {...register("terms", { required: "Vinsamlegast staðfestu skilmála" })}
            />
            <label htmlFor="terms" className="form-label form-check-label">
              Ég samþykki{" "}
              <a className="text-secondary text-decoration-underline fw-medium" target="_blank" rel="noreferrer" href={TERMS_URL}>
                viðskiptaskilmála
              </a>{" "}
              BL ehf. og dótturfélaga
            </label>
          </div>
          {errors.terms && <p className="text-danger">{errors.terms.message}</p>}
        </div>
        <div className="col-12">
          <p className="alert alert-info text-center fs-6">Þegar smellt er á staðfesta færist þú yfir á örugga greiðslusíðu til að klára pöntunina.</p>
          <hr className="my-5" />
        </div>
        <div className="col-12 d-flex justify-content-center">
          <div className="d-flex flex-column gap-4 w-max min-w-5">
            <Button type="submit" label="Staðfesta" className="w-100" primary />
          </div>
        </div>
      </form>
    </>
  );
};
