import PropTypes from "prop-types";
import React, { useCallback } from "react";

import { useControllableState } from "@/hooks";
import { cn } from "@/utils";


export const Toggler = ({ label, value, defaultValue = false, onChange, className }) => {
  const [active, setValue] = useControllableState({ prop: value, defaultProp: defaultValue, onChange });

  const handleKeyDown = useCallback(
    (e) => {
      e.preventDefault();

      if (e.key === "Enter") {
        setValue(true);
        return;
      }

      if (e.key === "Delete" || e.key === "Backspace") {
        setValue(false);
        return;
      }

      if (e.key === "Escape") {
        e.target.blur();
        return;
      }
    },
    [setValue],
  );

  return (
    <div
      className={cn("toggler", active && "toggler--active", className)}
      role="switch"
      aria-checked={active}
      tabIndex={0}
      onClick={() => setValue(true)}
      onKeyDown={handleKeyDown}
    >
      <span className="toggler__label">{label}</span>
      {active && (
        <span
          className="toggler__delete"
          onClick={(e) => {
            e.stopPropagation();
            setValue(false);
          }}
        >
          ✕
        </span>
      )}
    </div>
  );
};

Toggler.propTypes = {
  label: PropTypes.string,
  value: PropTypes.bool,
  defaultValue: PropTypes.bool,
  onChange: PropTypes.func,
  className: PropTypes.string,
};
