import React, { StrictMode } from "react";
import { Provider } from "react-redux";

import { store } from "@/store";
import { CarCheckoutContainer } from "./car-checkout-container";

export const Wrapper = (props) => {
  return (
    <StrictMode>
      <Provider store={store}>
        <CarCheckoutContainer {...props} />
      </Provider>
    </StrictMode>
  );
};
