import React from "react";

import { useCarDetailQuery } from "@/api/generated/cars";
import { CarLongTermRental, LoadingSpinner } from "@/components";

export const CarLongTermRentalContainer = ({ carId }) => {
  const { data, error, isLoading } = useCarDetailQuery({
    carId,
  });

  if (isLoading) {
    return <LoadingIndicator />;
  }

  if (error) {
    return <ErrorIndicator />;
  }

  if (!data) {
    return <NoResultsIndicator />;
  }

  return <CarLongTermRental car={data} />;
};

const LoadingIndicator = () => (
  <div className="d-flex justify-content-center p-5">
    <LoadingSpinner />
  </div>
);

const ErrorIndicator = () => {
  return (
    <div className="alert alert-danger text-center" role="alert">
      Villa kom upp, vinsamlegast reyndu aftur síðar.
    </div>
  );
};

const NoResultsIndicator = () => (
  <div className="alert alert-warning text-center" role="alert">
    Bíll fannst ekki
  </div>
);
