import React, { StrictMode } from "react";
import { Provider } from "react-redux";

import NewsList from "@/components/news-list";
import { store } from "@/store";

export default function Wrapper(props) {
  return (
    <StrictMode>
      <Provider store={store}>
        <div>
          <NewsList {...props} />
        </div>
      </Provider>
    </StrictMode>
  );
}
