import { isEqual } from "lodash";
import PropTypes from "prop-types";
import ReactSlider from "rc-slider";
import React, { useEffect, useMemo } from "react";

import { useCarsPriceRangeQuery } from "@/api/generated/cars";
import { useControllableState } from "@/hooks";
import { cn, formatNumber } from "@/utils";

/**
 * Price range slider component
 * @param {Object} props
 * @param {string} props.label - Label for the slider
 * @param {number[]} props.value - Value of the slider
 * @param {number[]} props.defaultValue - Default value of the slider
 * @param {function} props.onChange - Callback function for when the slider value changes
 * @param {function} props.onChangeComplete - Callback function for when the slider value change is complete
 * @param {boolean} props.rental - Show range for rental prices instead of purchase prices
 * @param {number} props.step - Step value for the slider
 * @param {number} props.min - Minimum value of the slider
 * @param {number} props.max - Maximum value of the slider
 * @param {string} props.className - Additional classes for the slider
 */
export const PriceRangeSlider = ({
  label = "Verðbil",
  value: valueProp,
  defaultValue,
  onChange,
  onChangeComplete,
  rental = false,
  step = 10000,
  className,
  onUpdate,
  ...props
}) => {
  const { data: pricing } = useCarsPriceRangeQuery();
  const [value, setValue] = useControllableState({
    prop: valueProp,
    defaultProp: defaultValue,
    onChange,
  });

  const [min_price, max_price, are_offers] = useMemo(() => {
    if (!pricing) return [0, 0, false];

    return rental
      ? [pricing.rental?.min, pricing.rental?.max, pricing?.are_offers]
      : [pricing.purchase?.min, pricing.purchase?.max, pricing?.are_offers];
  }, [pricing, rental]);

  // Clamp value to price range
  useEffect(() => {
    if (!pricing || !value) return;

    let [min, max] = value;

    if (min > max) [min, max] = [max, min]; // Ensure min < max
    if (min == null || min < min_price) min = min_price;
    if (max == null || max > max_price) max = max_price;

    if (!isEqual(value, [min, max])) setValue([min, max]);
    onUpdate(are_offers);
  }, [max_price, min_price, pricing, value, setValue]);

  return (
    <div className={cn("slider", className)}>
      <div className="slider__text-container justify-content-between d-flex">
        <p className="slider__label mb-0">{label}</p>
        <p className="slider__price mb-0">
          {formatNumber(value[0] ?? 0)} - {formatNumber(value[1] ?? 0)} kr.
        </p>
      </div>
      <ReactSlider
        value={value}
        onChange={setValue}
        onChangeComplete={onChangeComplete}
        step={step}
        min={min_price}
        max={max_price}
        range
        {...props}
      />
    </div>
  );
};

PriceRangeSlider.propTypes = {
  label: PropTypes.string,
  value: PropTypes.arrayOf(PropTypes.number),
  defaultValue: PropTypes.arrayOf(PropTypes.number),
  onChange: PropTypes.func,
  onChangeComplete: PropTypes.func,
  step: PropTypes.number,
  min: PropTypes.number,
  max: PropTypes.number,
  className: PropTypes.string,
};
