import React, { useCallback, useEffect, useMemo, useState } from "react";
import { VirtuosoGrid } from "react-virtuoso";

import { useCarListInfiniteQuery } from "@/api/infinite/cars";
import { CarCard } from "@/components";

import { CarCardGridLayout, gridComponents } from "./CarGridComponents";

const DEFAULT_LIMIT = 12;

/**
 * A grid of cars
 * @param {Object} props
 * @param {import("@/api/generated/cars").CarListApiArg} props.filters - Filters to apply to the query
 * @param {Boolean} [props.showMake=true] - Whether to show the make of the car in the card
 * @param {Boolean} [props.showRentalPrices=false] - Show rental prices instead of purchase prices on the cards
 */
export const CarsGrid = ({ filters, showMake = true, showRentalPrices = false }) => {
  const [offset, setOffset] = useState(0);
  const params = useMemo(
    () => ({
      limit: DEFAULT_LIMIT,
      offset,
      ...filters,
    }),
    [filters, offset],
  );
  const { currentData, isFetching, isError, isUninitialized } = useCarListInfiniteQuery(params);

  const fetchMoreData = useCallback(() => {
    setOffset(() => currentData?.items?.length ?? 0);
  }, [currentData?.items?.length]);

  // Reset offset when filters change
  useEffect(() => {
    setOffset(0);
  }, [filters]);

  const cars = currentData?.items ?? [];

  if (isError) {
    return (
      <CarCardGridLayout>
        <div className="alert alert-danger text-center my-5" role="alert">
          Villa við að sækja gögn
        </div>
      </CarCardGridLayout>
    );
  }

  if (!isUninitialized && !isFetching && cars.length === 0) {
    return (
      <CarCardGridLayout>
        <div className="alert alert-info text-center my-5" role="alert">
          Engir bílar fundust
        </div>
      </CarCardGridLayout>
    );
  }

  return (
    <CarCardGridLayout>
      <VirtuosoGrid
        data={cars}
        components={gridComponents}
        itemContent={(_, car) => (
          <CarGridItem car={car} showMake={showMake} showRentalPrices={showRentalPrices} />
        )}
        computeItemKey={(_, car) => car.id}
        context={{ isFetching }}
        endReached={fetchMoreData}
        overscan={{ main: 1000, reverse: 500 }}
        useWindowScroll
        itemClassName="card-grid__item"
      />
    </CarCardGridLayout>
  );
};

const CarGridItem = React.memo(function CarGridItem({ car, showMake, showRentalPrices, ...props }) {
  return <CarCard car={car} showMake={showMake} rental={showRentalPrices} {...props} />;
});
