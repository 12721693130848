import accounting from "accounting";

import { API_DEFAULT_PAGE_SIZE } from "../const";

export const invoiceDateFormat = (date) => {
  return new Date(date).toLocaleDateString("is-IS", {
    year: "numeric",
    month: "2-digit",
    day: "2-digit",
  });
};

export const formatNumber = (num) => {
  return accounting.formatMoney(+num, "", 0, ".", ",");
};

export const convertErrorsFromServer = (errors) => {
  return Object.keys(errors).map((key) => {
    return { type: "manual", name: key, message: errors[key].join(" ") };
  });
};

export const convertPageToOffsetLimit = (page, pageSize = API_DEFAULT_PAGE_SIZE) => {
  let offset = 0;
  if (page > 1) {
    offset = (page - 1) * pageSize;
  }
  return [{ offset: offset }, { limit: pageSize }];
};

export const formatPercentage = (num, extra = 0) => {
  return `${accounting.formatMoney(+num, "", extra, ".", ",")}%`;
};

export const getInvoiceStatusDescriptive = (status) => {
  switch (status) {
    case "Paid":
      return "Greitt";
    case "Open":
      return "Opinn";
    case "Canceled":
      return "Hætt við";
    case "Draft":
      return "Í vinnslu";
    default:
      return status;
  }
};

export const getOrderStatusDescriptive = (status) => {
  switch (status) {
    case "Draft":
      return "Í vinnslu";
    default:
      return status;
  }
};

/**
 * @typedef {string | null | undefined} MaybeClassName
 */

/**
 * Join class names together
 * @type {(...classes: MaybeClassName[]) => string}
 */
export const cn = (...classes) => classes.filter(Boolean).join(" ");

/**
 * @param {unknown} arr
 * @returns {arr is Array<unknown>}
 */
export const isValidNonEmptyArray = (arr) => {
  return Array.isArray(arr) && arr.length > 0;
};

/**
 * Get an array of unique values from an array
 * @template T
 * @param {Array<T>} arr
 * @returns {Array<T>}
 */
export const uniqueArray = (arr) => {
  return [...new Set(arr)];
};

/**
 * Function to group an array of objects by a key
 * @template T
 * @param {Array<T>} list
 * @param {string} key
 * @returns {Record<string, Array<T>>}
 */
export const groupArrayByValue = (list, key) => {
  if (!isValidNonEmptyArray(list)) {
    return {};
  }

  return list.reduce((acc, item) => {
    const value = item[key];

    if (value === undefined || value === null) {
      return acc;
    }

    acc[value] = acc[value] ?? [];
    acc[value].push(item);
    return acc;
  }, {});
};

/**
 * Sort object by keys alphabetically
 * @param {Record<string, unknown>} obj
 * @returns {Record<string, unknown>}
 */
export const sortObjectByKey = (obj) => {
  return Object.keys(obj)
    .sort()
    .reduce((acc, key) => {
      acc[key] = obj[key];
      return acc;
    }, {});
};
