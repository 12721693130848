import PropTypes from "prop-types";
import React, { useCallback, useEffect, useMemo, useState } from "react";

import {
    ImageCarousel,
    ImageCarouselProgressBar,
    ImageCarouselProgressCounter,
    ImageCarouselThumbnails,
} from "@/components";
import { cn } from "@/utils";

/**
 * Car image gallery component
 * @param {Object} props
 * @param {boolean} [props.compact]
 * @param {import('@/api/generated/cars').CarDetailSchema[]} props.variants - Array of car variants
 * @param {(swatch: string) => void} [props.onSwatchChange]
 * @param {string} [props.className]
 */
export const CarImageGallery = ({ variants, onSwatchChange, compact, className }) => {
  const [selectedImage, setSelectedImage] = useState();

  const distinctSwatches = useMemo(() => {
    const swatches = variants.map((variant) => variant.swatch).filter(Boolean);
    return [...new Set(swatches)];
  }, [variants]);

  const [selectedSwatch, setSelectedSwatch] = useState(distinctSwatches?.[0]);

  const imagesForSelectedSwatch = useMemo(() => {
    const selectedVariant = variants.find((variant) => variant.swatch === selectedSwatch);

    if (!selectedVariant) return { exterior: {}, interior: {}, all: {} };

    const { exterior_images, interior_images } = selectedVariant;

    const exterior = exterior_images.reduce((acc, image) => {
      acc[image] = image;
      return acc;
    }, {});

    const interior = interior_images.reduce((acc, image) => {
      acc[image] = image;
      return acc;
    }, {});

    const all = { ...exterior, ...interior };

    return {
      exterior,
      interior,
      all,
    };
  }, [selectedSwatch, variants]);

  const onPrevImage = useCallback(() => {
    const keys = Object.keys(imagesForSelectedSwatch.all);
    const currentIndex = keys.indexOf(selectedImage);
    const nextIndex = currentIndex === 0 ? keys.length - 1 : currentIndex - 1;
    setSelectedImage(keys[nextIndex]);
  }, [imagesForSelectedSwatch.all, selectedImage]);

  const onNextImage = useCallback(() => {
    const keys = Object.keys(imagesForSelectedSwatch.all);
    const currentIndex = keys.indexOf(selectedImage);
    const nextIndex = currentIndex === keys.length - 1 ? 0 : currentIndex + 1;
    setSelectedImage(keys[nextIndex]);
  }, [imagesForSelectedSwatch.all, selectedImage]);

  // Reset selected swatch when variants change
  useEffect(() => {
    setSelectedSwatch(distinctSwatches?.[0]);
  }, [distinctSwatches]);

  // Reset selected image when swatch changes
  useEffect(() => {
    setSelectedImage(Object.keys(imagesForSelectedSwatch.all)?.[0]);
  }, [imagesForSelectedSwatch.all]);

  // Call onSwatchChange when selected swatch changes
  useEffect(() => {
    if (onSwatchChange) {
      onSwatchChange(selectedSwatch);
    }
  }, [onSwatchChange, selectedSwatch]);

  return (
    <div className={cn("car-image-gallery", compact && "car-image-gallery--compact", className)}>
      {compact && (
        <div className="car-image-gallery__swatches">
          {distinctSwatches.map((swatch) => (
            <button
              key={swatch}
              className={cn(
                "car-image-gallery__swatch",
                swatch === selectedSwatch && "car-image-gallery__swatch--selected",
              )}
              onClick={() => setSelectedSwatch(swatch)}
            >
              <img className="car-image-gallery__swatch-image" src={swatch} alt="Car swatch" />
            </button>
          ))}
        </div>
      )}
      <ImageCarousel
        images={imagesForSelectedSwatch.all}
        currentKey={selectedImage}
        onPrev={onPrevImage}
        onNext={onNextImage}
        compact={compact}
        className="car-image-gallery__carousel"
      />
      {compact ? (
        <>
          <ImageCarouselProgressCounter
            images={imagesForSelectedSwatch.all}
            currentKey={selectedImage}
            className="my-1"
          />
          <ImageCarouselProgressBar
            images={imagesForSelectedSwatch.all}
            currentKey={selectedImage}
            onImageSelect={setSelectedImage}
          />
        </>
      ) : (
        <>
          <div className="car-image-gallery__swatches">
            <p className="mx-2 my-0">Litur</p>
            {distinctSwatches.map((swatch) => (
              <button
                key={swatch}
                className={cn(
                  "car-image-gallery__swatch",
                  swatch === selectedSwatch && "car-image-gallery__swatch--selected",
                )}
                onClick={() => setSelectedSwatch(swatch)}
              >
                <img className="car-image-gallery__swatch-image" src={swatch} alt="Car swatch" />
              </button>
            ))}
          </div>
          <hr />
          <div className="car-image-gallery__thumbnails">
            {Object.keys(imagesForSelectedSwatch.exterior).length > 0 && (
              <div className="car-image-gallery__thumbnails__group">
                <p>{Object.keys(imagesForSelectedSwatch.interior).length > 0 ? "Að utan":"Myndir"}</p>
                <ImageCarouselThumbnails
                  images={imagesForSelectedSwatch.exterior}
                  currentKey={selectedImage}
                  onImageSelect={setSelectedImage}
                />
              </div>
            )}
            {Object.keys(imagesForSelectedSwatch.interior).length > 0 && (
              <div className="car-image-gallery__thumbnails__group">
                <p>{Object.keys(imagesForSelectedSwatch.exterior).length > 0 ? "Að innan":"Myndir"}</p>
                <ImageCarouselThumbnails
                  images={imagesForSelectedSwatch.interior}
                  currentKey={selectedImage}
                  onImageSelect={setSelectedImage}
                />
              </div>
            )}
          </div>
        </>
      )}
    </div>
  );
};

CarImageGallery.propTypes = {
  variants: PropTypes.arrayOf(
    PropTypes.shape({
      color: PropTypes.string.isRequired,
      exterior_images: PropTypes.arrayOf(PropTypes.string).isRequired,
      interior_images: PropTypes.arrayOf(PropTypes.string).isRequired,
    }),
  ).isRequired,
};
