import PropTypes from "prop-types";
import React from "react";

export const ProductCard = ({ product, ...props }) => {
  return (
    <div className="card card__product justify-content-center" {...props}>
      <img src={product.image} className="card-img-top" alt={product.title} />
      <div className="card-body">
        <div>
          <p className="card-text-small fw-bold pb-0 text-uppercase">{product.category}</p>
          <p className="card-text-large medium fw-bold">{product.title}</p>
          <p className="card-text-medium">{product.description}</p>
        </div>
        <div className="price-container d-flex fw-bold">
          <p className="card-text-small d-flex flex-column me-5 text-uppercase">
            Verð
            <span className="card-text-large text-lowercase mt-2">{product.price} kr.</span>
          </p>
          {product.originalPrice && (
            <p className="card-text-small d-flex flex-column text-disabled text-uppercase">
              Verð áður
              <span className="text-decoration-line-through card-text-large text-disabled text-lowercase mt-2">
                {product.originalPrice} kr.
              </span>
            </p>
          )}
        </div>
      </div>
    </div>
  );
};

ProductCard.propTypes = {
  product: PropTypes.object.isRequired,
};
