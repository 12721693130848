import React, { useState, useMemo } from "react";

import { Button } from "@/components";
import { cn, formatNumber } from "@/utils";

import { CarAccessoriesSelector } from "./CarAccessoriesSelector";

/**
 * @param {Object} props
 * @param {import('@/api/generated/cars').CarDetailSchema} props.car - The car to checkout
 * @param {Function} props.onSubmit
 */
export const CarOverview = ({ car, onSubmit }) => {
  const [selectedAccessories, setSelectedAccessories] = useState([]);

  const modelOnOffer = useMemo(
    () => car.discounts.some((discount) => discount.code === "offer_price"),
    [car],
  );

  return (
    <div className="d-flex flex-column gap-5">
      <div>
        <div className="d-flex flex-wrap flex-md-nowrap justify-content-center align-items-center gap-4 mt-3">
          <img src={car.image} alt={car.make} className="img-fluid" style={{ width: "200px" }} />
          <div className="d-flex gap-4 flex-grow-1">
            <div className="me-auto">
              <p className="m-0 text-secondary fw-bold">{car.make}</p>
              <p className="m-0 h4 text-primary fw-bold">
                {car.model} {car.variant}
              </p>
            </div>
            <div>
              <p className={cn("m-0 text-uppercase text-secondary text-xsmall fw-bold", car.kjarabill && "kjarabill")}>{ car.kjarabill ? "Kjarabílaverð" : "Verð" }</p>
              <p className={cn("m-0 h4 text-primary fw-bold", car.kjarabill && "kjarabill")}>
                { car.kjarabill ?
                  formatNumber(car.kjarabill_price)
                  :
                  formatNumber(modelOnOffer ? car.discounted_price : car.price)
                }
                <span className="fs-6"> kr.</span>
              </p>
            </div>
          </div>
        </div>
        <hr className="mb-4" />
        {car.discounts.some((d) => d.code === "energy_grant") && (
          <p className="energy-grant-disclaimer text-xsmall mb-5">* Að kaupum loknum getur þú sótt um rafbílastyrk hjá <a className="fw-bold" href="https://island.is/rafbilastyrkir" target="_blank" rel="noreferrer">Orkusjóði</a></p>
        )}
      </div>
      <CarAccessoriesSelector
        carId={car.id}
        selectedAccessories={selectedAccessories}
        onSelectedAccessoriesChange={setSelectedAccessories}
      />
      <div className="d-flex justify-content-center">
        <div className="d-flex flex-column gap-4 w-max min-w-5">
          <Button
            label="Áfram"
            className="w-100"
            primary
            onClick={() => onSubmit(selectedAccessories)}
          />
        </div>
      </div>
    </div>
  );
};
