import React, { useState } from "react";

import { CarsGrid } from "@/components";
import { useDebounce } from "@/hooks";
import { sortObjectByKey } from "@/utils";

export const CarKjarabillListContainer = () => {
  const [filters, setFilters] = useState({
    kjarabill: true,
  });

  const debouncedFilters = useDebounce(filters, 500);

  return (
    <>
      <h1 className="text-primary display-3 fw-bold">KJARABÍLAR</h1>
      <CarCardGridMemo
        filters={debouncedFilters}
        key={JSON.stringify(sortObjectByKey(debouncedFilters))}
      />
    </>
  );
};

const CarCardGridMemo = React.memo(CarsGrid);
