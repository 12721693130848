import React, { useEffect, useState } from "react";

import { useServiceProvidersListQuery } from "@/api/generated/service_providers";

function ServiceProviders() {
  const { data } = useServiceProvidersListQuery();
  const [filters, setFilters] = useState({});
  const [activeFilters, setActiveFilters] = useState({});

  useEffect(() => {
    if (data) {
      let filters = {};
      for (const item of data) {
        for (const car_make of item.car_makes) {
          if (!filters[car_make.id]) {
            filters[car_make.id] = car_make.logo;
          }
        }
      }
      setFilters(filters);
    }
  }, [data]);

  const should_show = (item) => {
    if (
      Object.keys(activeFilters).length === 0 ||
      Object.values(activeFilters).every((v) => v === false)
    ) {
      return "d-block";
    } else {
      for (const key in activeFilters) {
        if (activeFilters[key] && item.includes(parseInt(key))) {
          return "d-block";
        }
      }
      return "d-none";
    }
  };

  const isFilterActive = Object.values(activeFilters).some((v) => v);

  const clearFilters = () => {
    setActiveFilters({});
  };
  return (
    <div>
      <>
        <div className="logo-filter">
          <div className="row justify-content-center">
            <div className="col-10">
              <div className="d-flex justify-content-between align-items-baseline">
                <h5 className="mb-4">Sía eftir vörumerki</h5>
                {isFilterActive && (
                  <button
                    role="button"
                    className="button clear-btn pe-0"
                    aria-label="Hreinsa síu"
                    onClick={clearFilters}
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="20px"
                      height="20px"
                      viewBox="0 0 512 512"
                    >
                      <path
                        d="M256 48a208 208 0 1 1 0 416 208 208 0 1 1 0-416zm0 464A256 256 0 1 0 256 0a256 256 0 1 0 0 512zM175 175c-9.4 9.4-9.4 24.6 0 33.9l47 47-47 47c-9.4 9.4-9.4 24.6 0 33.9s24.6 9.4 33.9 0l47-47 47 47c9.4 9.4 24.6 9.4 33.9 0s9.4-24.6 0-33.9l-47-47 47-47c9.4-9.4 9.4-24.6 0-33.9s-24.6-9.4-33.9 0l-47 47-47-47c-9.4-9.4-24.6-9.4-33.9 0z"
                        fill="#103483"
                      />
                    </svg>
                  </button>
                )}
              </div>
              <div className="d-flex flex-wrap justify-content-center">
                {filters &&
                  Object.entries(filters).map(([key, value]) => (
                    <div className="col-4 col-sm-3 col-lg-2 filter-wrapper" key={key}>
                      <div className="item-logo">
                        <div
                          className={`car-logo-container d-flex justify-content-center ${Object.keys(activeFilters).includes(key) && activeFilters[key] === true ? "active" : ""}`}
                          onClick={() => setActiveFilters({ [key]: !activeFilters[key] })}
                        >
                          <img src={value} alt="" className="" />
                        </div>
                      </div>
                    </div>
                  ))}
              </div>
            </div>
          </div>
        </div>
        <div className="row justify-content-center">
          {data &&
            data.map((item) => (
              <div
                key={item.id}
                className={`col-xl-5 col-md-8 col-10 ${should_show(item.car_makes.map((cm) => cm.id))}`}
              >
                <div className="provider-card-wrapper">
                  <div className="provider-card">
                    <div className="info-container">
                      <h5 className="card-title">{item.name}</h5>
                      <div className="address-info mb-2">
                        <div className="fw-bold">
                          {item.address} - {item.zip_code}
                        </div>
                      </div>
                      <div className="phone-container">
                        <div className="phone-label">Sími</div>
                        <a className="phone" href="tel:{{item.tel}}">
                          {item.tel}
                        </a>
                      </div>
                      <div className="d-flex align-items-center flex-wrap">
                        {item.car_makes.map((car_make) => (
                          <div
                            key={car_make.id}
                            className="w-25 d-flex justify-content-between car-logo"
                          >
                            <div className="car-logo-container d-flex justify-content-center">
                              <img
                                src={car_make.logo}
                                alt="{{ car_make.name }}"
                                className="w-100"
                              />
                            </div>
                          </div>
                        ))}
                      </div>
                    </div>
                    <div
                      className={`d-flex button-container ${item.order_url ? "justify-content-between" : ""}`}
                    >
                      <a
                        type="button"
                        className={`button text-uppercase button--primary ${item.order_url ? "double-btn" : ""}`}
                        href={`mailto:${item.email}`}
                      >
                        Senda fyrirspurn
                      </a>
                      {item.order_url && (
                        <a
                          type="button"
                          className="button text-uppercase button--primary dark-blue double-btn"
                          href={item.order_url}
                        >
                          Panta tíma
                        </a>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            ))}
        </div>
      </>
    </div>
  );
}

export default ServiceProviders;
