import * as AccordionPrimitive from "@radix-ui/react-accordion";
import React from "react";

import { cn } from "@/utils";

import "./accordion.scss";

/**
 * @type {React.FC<React.ComponentProps<typeof AccordionPrimitive.Root>>} Accordion
 */
export const Accordion = React.forwardRef(({ className, ...props }, ref) => {
  return <AccordionPrimitive.Root ref={ref} className={cn("accordion", className)} {...props} />;
});

Accordion.displayName = "Accordion";

/**
 * @type {React.FC<React.ComponentProps<typeof AccordionPrimitive.Item>>} props
 */
export const AccordionItem = React.forwardRef(({ className, ...props }, ref) => {
  return (
    <AccordionPrimitive.Item ref={ref} className={cn("accordion__item", className)} {...props} />
  );
});

AccordionItem.displayName = "AccordionItem";

/**
 * @type {React.FC<React.ComponentProps<typeof AccordionPrimitive.Header>>} props
 */
export const AccordionHeader = React.forwardRef(({ className, children, ...props }, ref) => {
  return (
    <AccordionPrimitive.Header className="accordion__header" {...props}>
      <AccordionPrimitive.Trigger ref={ref} className={cn("accordion__trigger", className)}>
        {children}
      </AccordionPrimitive.Trigger>
    </AccordionPrimitive.Header>
  );
});

AccordionHeader.displayName = "AccordionHeader";

/**
 * @type {React.FC<React.ComponentProps<typeof AccordionPrimitive.Content>>} props
 */
export const AccordionContent = React.forwardRef(({ className, ...props }, ref) => {
  return (
    <AccordionPrimitive.Content ref={ref} className="accordion__content" {...props}>
      <div className={cn("accordion__content__inner", className)}>{props.children}</div>
    </AccordionPrimitive.Content>
  );
});

AccordionContent.displayName = "AccordionContent";
