import React from "react";

import { useCarsCompareQuery } from "@/api/generated/cars";
import { Button, CarComparisonCard, CloseFilterIcon, LoadingSpinner } from "@/components";
import { useComparisonCarsIds } from "@/hooks";

export const CarComparisonBarContainer = () => {
  const { comparisonCarsIds, deleteComparisonCarsIds } = useComparisonCarsIds();

  const { data, isLoading } = useCarsCompareQuery({
    ids: comparisonCarsIds,
  });

  // No cars to compare, do not render anything
  if (!comparisonCarsIds || comparisonCarsIds.length === 0) return null;

  // Query is loading
  if (isLoading) return <LoadingSpinner className="mx-auto my-3" />;

  // No data to show, car ids might be invalid
  if (!data || data.length === 0) return null;

  return (
    <div className="container-fluid py-3 clamped">
      <div className="row justify-content-center">
        <div className="col">
          <div className="d-flex justify-content-between align-items-center gap-3 mb-md-2 mb-3">
            <p className="text-primary fw-bold m-0">Samanburður</p>
            <Button
              label="Hreinsa samanburð"
              icon={<CloseFilterIcon />}
              noBorder
              className="p-2 h-auto d-flex flex-row-reverse fw-normal clear-comparison"
              onClick={deleteComparisonCarsIds}
            />
          </div>
        </div>
      </div>
      <div className="row">
        <div className="col-md-9 col-8">
          <div className="row flex-grow-1 overflow-auto themed-scroller flex-row flex-nowrap">
            {data.map((car) => (
              <CarComparisonCard key={car.id} car={car} />
            ))}
          </div>
        </div>
        <div className="col-md-3 col-4">
          {data.length > 1 && (
            <a href="/samanburdur">
              <Button
                size="lg"
                label="Skoða samanburð"
                primary
                className="h-100 w-100 fw-bold comparison-btn"
              />
            </a>
          )}
        </div>
      </div>
    </div>
  );
};
