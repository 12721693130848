import { carsApi } from "@/api/generated/cars";
import { isValidNonEmptyArray, sortObjectByKey } from "@/utils";

const carsInfiniteApi = carsApi.enhanceEndpoints({
  endpoints: {
    carsGrouped: {
      // Remove offset from endpoint query cache key to allow for merging of queries
      serializeQueryArgs: ({ queryArgs: { offset: _, ...queryArgs } }) => {
        return sortObjectByKey(queryArgs);
      },
      // Merge new data with existing cache data
      merge: (currentCache, responseData) => {
        return {
          ...responseData,
          items: isValidNonEmptyArray(currentCache?.items)
            ? [...currentCache.items, ...responseData.items]
            : responseData.items,
        };
      },
      // Refetch data when filters change
      forceRefetch({ currentArg, endpointState }) {
        // If endpointState is null, the query has not been initialized yet
        // Always fetch data
        if (endpointState == null) return true;

        // We loaded all available data
        if (endpointState.data?.count === endpointState.data?.items?.length) return false;

        // If the requested data range is greater that what is currently available in the cache
        // Fetch more data
        return currentArg.offset + currentArg.limit > endpointState.data?.items?.length;
      },
    },
    // Exactly the same, but for single cars
    carList: {
      serializeQueryArgs: ({ queryArgs: { offset: _, ...queryArgs } }) => {
        return sortObjectByKey(queryArgs);
      },
      merge: (currentCache, responseData) => {
        return {
          ...responseData,
          items: isValidNonEmptyArray(currentCache?.items)
            ? [...currentCache.items, ...responseData.items]
            : responseData.items,
        };
      },
      forceRefetch({ currentArg, endpointState }) {
        if (endpointState == null) return true;
        if (endpointState.data?.count === endpointState.data?.items?.length) return false;
        return currentArg.offset + currentArg.limit > endpointState.data?.items?.length;
      },
    },
  },
});

export const {
  useCarsGroupedQuery: useCarsGroupedInfiniteQuery,
  useCarListQuery: useCarListInfiniteQuery,
} = carsInfiniteApi;
