import { useEffect, useLayoutEffect, useRef } from "react";

/**
 * Hook that allows you to add event listeners to the target element
 * @template {string} EventName
 * @param {EventName} eventName - Name of the event to listen for
 * @param {(event: HTMLElementEventMap[EventName]) => void} handler - Event handler
 * @param {HTMLElement | null} [target = document] - Target element to add the event listener to
 * @param {boolean | AddEventListenerOptions} [options = false] - Options to pass to the event listener
 */
export const useEventListener = (eventName, handler, target = document, options = false) => {
  const handlerRef = useRef(handler);

  useLayoutEffect(() => {
    handlerRef.current = handler;
  }, [handler]);

  useEffect(() => {
    const targetElement = target ?? document;

    if (!(targetElement && targetElement.addEventListener)) return;

    const listener = (event) => handlerRef.current(event);

    targetElement.addEventListener(eventName, listener, options);

    return () => {
      targetElement.removeEventListener(eventName, listener, options);
    };
  }, [eventName, target, options]);
};
