import React from "react";
import ReactModal from "react-modal";

/**
 * Modal component
 * @param {import("react-modal").Props} props
 */
export const Modal = (props) => {
  return (
    // Silence the warning about "App element not defined."
    // There isn't a single app in this project that can be hidden, so focus traping will not work.
    <ReactModal ariaHideApp={false} {...props} />
  );
};
