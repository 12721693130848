import React, { StrictMode } from "react";
import { Provider } from "react-redux";
import { store } from "../../store";
import UserbarContainer from "./userbar-container";

export default function Wrapper(props) {
  return (
    <StrictMode>
      <Provider store={store}>
        <UserbarContainer {...props} />
      </Provider>
    </StrictMode>
  );
}
