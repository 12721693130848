import { configureStore } from "@reduxjs/toolkit";
import { setupListeners } from "@reduxjs/toolkit/query";
import { createLogger } from "redux-logger";

import { api } from "./api";

const logger = createLogger({
  collapsed: true,
});

export const store = configureStore({
  reducer: {
    [api.reducerPath]: api.reducer,
  },
  middleware: (getDefaultMiddleware) => getDefaultMiddleware().concat(api.middleware, logger),
  devTools: process.env.NODE_ENV !== "production",
});

setupListeners(store.dispatch);
