import React from "react";
import ReactDOM from "react-dom/client";
import Wrapper from "./wrapper";

function serviveProvidersContainer(element) {
  var options = element.dataset;
  var root = ReactDOM.createRoot(element);
  root.render(React.createElement(Wrapper, options));
}

document.addEventListener("DOMContentLoaded", function () {
  var elements = document.querySelectorAll("[data-service-providers-container]");
  elements.forEach(serviveProvidersContainer);
});
