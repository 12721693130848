import React from "react";
import { useForm } from "react-hook-form";

import { Button } from "@/components";

/**
 * @param {Object} props
 * @param {Function} props.onSubmit
 * @param {boolean} [props.rental=false]
 */
export const CustomerInfoForm = ({ onSubmit, rental=false }) => {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();

  return (
    <form className="row py-5" onSubmit={handleSubmit(onSubmit)}>
      <p className="col-12 text-primary fw-bold h4 mb-4">Upplýsingar um {rental == true ? "leigutaka" : "kaupanda"} </p>
      <div className="col-12 col-md-6 mb-4">
        <label htmlFor="name" className="form-label form-label-sm fw-medium">
          Nafn
        </label>
        <input
          type="text"
          id="name"
          className="form-control form-control-lg"
          {...register("name", {
            required: "Nafn er nauðsynlegt",
            minLength: {
              value: 2,
              message: "Nafn verður að vera að minnsta kosti 2 stafir",
            },
          })}
        />
        {errors.name && <p className="text-danger">{errors.name.message}</p>}
      </div>
      <div className="col-12 col-md-6 mb-4">
        <label htmlFor="kennitala" className="form-label form-label-sm fw-medium">
          Kennitala
        </label>
        <input
          type="text"
          id="kennitala"
          className="form-control form-control-lg"
          {...register("kennitala", {
            required: "Kennitala er nauðsynleg",
            pattern: {
              value: /^\d{6}-?\d{4}$/,
              message: "Kennitala verður að vera á forminu 000000-0000",
            },
          })}
        />
        {errors.kennitala && <p className="text-danger">{errors.kennitala.message}</p>}
      </div>
      <div className="col-12 col-md-6 mb-4">
        <label htmlFor="phone" className="form-label form-label-sm fw-medium">
          Sími
        </label>
        <input
          type="tel"
          id="phone"
          className="form-control form-control-lg"
          {...register("tel", {
            required: "Sími er nauðsynlegur",
            pattern: {
              value: /^\d{3}-?\d{4}$/,
              message: "Símanúmer verður að vera á forminu 000-0000",
            },
          })}
        />
        {errors.tel && <p className="text-danger">{errors.tel.message}</p>}
      </div>
      <div className="col-12 col-md-6 mb-4">
        <label htmlFor="email" className="form-label form-label-sm fw-medium">
          Netfang
        </label>
        <input
          type="email"
          id="email"
          className="form-control form-control-lg"
          {...register("email", { required: "Netfang er nauðsynlegt" })}
        />
        {errors.email && <p className="text-danger">{errors.email.message}</p>}
      </div>
      <hr className="my-5" />
      <div className="d-flex justify-content-center">
        <div className="d-flex flex-column gap-4 w-max min-w-5">
          <Button type="submit" label="Áfram" className="w-100" primary />
        </div>
      </div>
    </form>
  );
};
