import { api } from "../index";
export const addTagTypes = ["news"] as const;
const injectedRtkApi = api
  .enhanceEndpoints({
    addTagTypes,
  })
  .injectEndpoints({
    endpoints: (build) => ({
      newsList: build.query<NewsListApiResponse, NewsListApiArg>({
        query: (queryArg) => ({
          url: `/api/news/`,
          params: { limit: queryArg.limit, offset: queryArg.offset },
        }),
        providesTags: ["news"],
      }),
    }),
    overrideExisting: false,
  });
export { injectedRtkApi as enhancedApi };
export type NewsListApiResponse = /** status 200 OK */ PagedNewsSchema;
export type NewsListApiArg = {
  limit?: number;
  offset?: number;
};
export type NewsSchema = {
  id: number;
  title: string;
  card_image?: string | null;
  url: string;
  first_published_at: string;
};
export type PagedNewsSchema = {
  items: NewsSchema[];
  count: number;
};
export const { useNewsListQuery } = injectedRtkApi;
