import { is } from "date-fns/locale";
import React from "react";
import { DayPicker } from "react-day-picker";

import { ArrowLeft, ArrowRight } from "@/components";
import { cn } from "@/utils";

import "./Calendar.scss";

/**
 * Calendar component
 * @param {React.ComponentProps<typeof DayPicker>} props
 */
export const Calendar = ({ className, classNames, ...props }) => {
  return (
    <DayPicker
      className={cn("calendar", className)}
      classNames={{
        ...classNames,
        months: "calendar__months",
        nav: "calendar__nav",
        button_previous: "calendar__button-prev",
        button_next: "calendar__button-next",
        chevron: "calendar__chevron",
        month: "calendar__month",
        month_caption: "calendar__month__caption",
        caption_label: "calendar__month__caption__label",
        month_grid: "calendar__month__grid",
        weekdays: "calendar__weekdays",
        weekday: "calendar__weekday",
        weeks: "calendar__weeks",
        week: "calendar__week",
        day: "calendar__day",
        today: "calendar__day--today",
        disabled: "calendar__day--disabled",
        hidden: "calendar__day--hidden",
        outside: "calendar__day--outside",
        selected: "calendar__day--selected",
        day_button: "calendar__day__button",
      }}
      components={{
        Chevron: ({ orientation, ...props }) => {
          return orientation === "left" ? <ArrowLeft {...props} /> : <ArrowRight {...props} />;
        },
      }}
      locale={is}
      {...props}
    />
  );
};
