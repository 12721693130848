import React, { StrictMode, useMemo } from "react";
import { Provider } from "react-redux";

import { Carousel } from "@/components";
import { store } from "@/store";

export default function Wrapper(props) {
  const filters = useMemo(() => {
    return JSON.parse(props?.filters);
  }, [props?.filters]);

  return (
    <StrictMode>
      <Provider store={store}>
        <Carousel filters={filters} />
      </Provider>
    </StrictMode>
  );
}
