import React, { useCallback, useEffect, useMemo } from "react";

import {
  Accordion,
  AccordionContent,
  AccordionHeader,
  AccordionItem,
  Button,
  Tab,
  Tabs,
} from "@/components";
import { useControllableState } from "@/hooks";
import { cn, formatNumber, groupArrayByValue, uniqueArray } from "@/utils";

import { CarTestDriveBooking } from "./CarDetails";

/**
 * @param {Object} props
 * @param {import("@/api/generated/cars").CarDetailSchema[]} props.variants
 * @param {import("@/api/generated/cars").CarDetailSchema} props.selectedVariant
 * @param {string | null} props.selectedModelVersion
 * @param {(variant: string) => void} props.onSelectModelVersion
 * @param {"purchase" | "rental"} [props.mode]
 * @param {(mode: "purchase" | "rental") => void} [props.onModeChange]
 * @param {boolean} props.compact
 * @param {string} [props.className]
 */
export const CarModelVersionPicker = ({
  variants,
  selectedVariant,
  selectedModelVersion,
  onSelectModelVersion,
  mode: modeProp,
  onModeChange,
  compact,
  className,
}) => {
  /** @type {ReturnType<typeof useControllableState<'purchase' | 'rental'>>} */
  const [mode, setMode] = useControllableState({
    prop: modeProp,
    onChange: onModeChange,
    defaultProp: "purchase",
  });

  const getCheapestAvailableVariant = useCallback(
    (rental = false) => {
      const availableVariants = rental ? variants.filter((v) => v.rental_available) : variants;
      const cheapestAvailable = getCheapestCar(availableVariants, rental);

      return cheapestAvailable;
    },
    [variants],
  );

  const handleTabChange = useCallback(
    (key = "purchase") => {
      const isRental = key === "rental";

      // Check if currently selected variant is available in the new tab
      const currentIsAvailable = isRental
        ? variants.find((v) => v.model_version === selectedModelVersion && v.rental_available)
        : variants.find((v) => v.model_version === selectedModelVersion);

      // If not available, select cheapest available option
      if (!currentIsAvailable) {
        const cheapestAvailable = getCheapestAvailableVariant(isRental);

        if (cheapestAvailable) {
          onSelectModelVersion(cheapestAvailable.model_version);
        }
      }

      setMode(key);
    },
    [variants, setMode, selectedModelVersion, getCheapestAvailableVariant, onSelectModelVersion],
  );

  const isRentalAvailable = useMemo(() => {
    return variants.some((v) => v.rental_available);
  }, [variants]);

  const lowestPurchasePrice = useMemo(() => {
    return getLowestPrice(variants);
  }, [variants]);

  const lowestRentalPrice = useMemo(() => {
    return getLowestRentalPrice(selectedVariant);
  }, [selectedVariant]);

  const isEnergyGrant = useMemo(() => 
    selectedVariant.discounts.map((i) => i.code).includes("energy_grant")
  , [selectedVariant])

  const isOfferDiscount = useMemo(() =>
    selectedVariant.discounts.map((i) => i.code).includes("offer_price")
  , [selectedVariant])

  useEffect(() => {
    // Select cheapest available variant on mount if none is selected
    if (!selectedModelVersion) {
      const isRental = mode === "rental";
      const cheapestAvailable = getCheapestAvailableVariant(isRental);

      if (cheapestAvailable) {
        onSelectModelVersion(cheapestAvailable.model_version);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div
      className={cn(
        "car-details__variant-select",
        compact && "car-details__variant-select--compact",
        !isRentalAvailable && "car-details__variant-select--no-rental",
        className,
      )}
    >
      <Tabs currentTab={mode} onTabChange={handleTabChange} tabPanelClassName="p-3 px-lg-4 pb-lg-4">
        <Tab value="purchase" title="Kaup">
          {!compact && (
            <>
              {isRentalAvailable && (
                <p className="car-details__variant-select__price-small text-end mb-2" style={lowestRentalPrice === Infinity?{"color": "transparent"}:{}}>
                  Leiga á mánuði frá:{" "}
                  <span className="fs-5 ms-2">{formatNumber(lowestRentalPrice)}</span> kr
                </p>
              )}
              <ModelVersionSelectAccordion
                variants={variants}
                selectedModelVersion={selectedModelVersion}
                onSelectModelVersion={onSelectModelVersion}
              />
            </>
          )}
          <div className="d-flex flex-column gap-2 pt-4">
            <div className="car-details__variant-select__info fw-bold">
              {selectedVariant?.variant && (
                <p className="car-details__variant-select__label text-uppercase text-secondary">
                  Gerð
                </p>
              )}
              <p className={cn("car-details__variant-select__label text-uppercase text-secondary", selectedVariant?.kjarabill && "kjarabill")}>
                { selectedVariant?.kjarabill ?
                  <>Kjarabílaverð</>
                  :
                  <>Verð</>
                }
              </p>
              {selectedVariant?.variant && (
                <p className="text-primary text-nowrap">{selectedVariant.variant}</p>
              )}
              <p
                className={cn(
                  "car-details__variant-select__price text-primary text-nowrap",
                  selectedVariant.kjarabill && isEnergyGrant && "discount",
                  isOfferDiscount && "discount",
                  selectedVariant?.kjarabill && "kjarabill",
                )}
              >
                {selectedVariant?.kjarabill ?
                  <>{formatNumber(selectedVariant.kjarabill_price)}</>
                :
                  <>{formatNumber(selectedVariant.price)}</>
                } <span>kr</span>
              </p>
              {isEnergyGrant && !isOfferDiscount && (
                <>
                <p className="text-secondary">Með Orkusjóðsstyrk</p>
                <p className="car-details__variant-select__price text-secondary text-nowrap">
                  {formatNumber(selectedVariant.discounted_price)} <span>kr</span>
                </p>
                </>
              )}
              {isOfferDiscount && (
                <>
                <p className="text-secondary">Tilboðsverð</p>
                <p className="car-details__variant-select__price text-secondary text-nowrap">
                  {formatNumber(selectedVariant.discounted_price)} <span>kr</span>
                </p>
                {isEnergyGrant && <p className="car-details__variant-select__label text-secondary">með Orkusjóðsstyrk</p>}
                </>
              )}
            </div>
            <div className={cn("btn-section", compact && "d-flex gap-2")}>
              {selectedVariant?.id && (
                <a
                  href={`/kaupferli/${selectedVariant.id}`}
                  className={cn(
                    "button button--primary text-capitalize",
                    compact ? "button--large w-50" : "button--CTA w-100",
                    selectedVariant.kjarabill && "kjarabill-btn",
                  )}
                >
                  Panta
                </a>
              )}
              <CarTestDriveBooking car={selectedVariant} compact={compact} />
            </div>
          </div>
        </Tab>
        <Tab value="rental" title="Langtímaleiga" disabled={!isRentalAvailable}>
          {!compact && (
            <>
              <p className="car-details__variant-select__price-small text-end mb-2">
                Kaupverð frá: <span className="fs-5 ms-2">{formatNumber(lowestPurchasePrice)}</span>{" "}
                kr
              </p>
              <ModelVersionSelectAccordion
                variants={variants}
                selectedModelVersion={selectedModelVersion}
                onSelectModelVersion={onSelectModelVersion}
                rental
              />
            </>
          )}
          <div className="d-flex flex-column gap-2 pt-4">
            <div className="car-details__variant-select__info fw-bold">
              <p className="car-details__variant-select__label text-uppercase text-secondary">
                Gerð
              </p>
              <p className="car-details__variant-select__label text-uppercase text-secondary text-nowrap">
                Leiga á mánuði frá
              </p>
              <p className="text-primary text-nowrap">{selectedVariant.variant}</p>
              <p className="car-details__variant-select__price text-primary text-nowrap">
                {formatNumber(lowestRentalPrice)} <span>kr</span>
              </p>
            </div>
            <div className={cn("d-flex", compact && "gap-2")}>
              {selectedVariant?.id && (
                <a
                  href={`/langtima-leiga/${selectedVariant.id}`}
                  className={cn(
                    "button button--primary text-capitalize",
                    compact ? "button--large w-50" : "button--CTA w-100",
                  )}
                >
                  Leigja
                </a>
              )}
              {compact && <CarTestDriveBooking car={selectedVariant} compact />}
            </div>
          </div>
        </Tab>
      </Tabs>
    </div>
  );
};

/**
 * @param {Object} props
 * @param {import("@/api/generated/cars").CarDetailSchema[]} props.variants
 * @param {string | null} props.selectedModelVersion
 * @param {(variant: string) => void} props.onSelectModelVersion
 * @param {Boolean} props.rental
 * @param {string} [props.className]
 */
export const ModelVersionSelectAccordion = ({
  variants,
  selectedModelVersion,
  onSelectModelVersion,
  rental,
  className,
}) => {
  const availableCars = useMemo(() => {
    return rental ? variants.filter((variant) => variant.rental_available) : variants;
  }, [variants, rental]);

  const carsByVariant = useMemo(() => {
    return groupArrayByValue(availableCars, "variant");
  }, [availableCars]);

  /**
   * Different configurations of the same variant
   * First level: variant
   * Second level: property
   * Third level: value
   * @example
   * {
   *   "Classic": {
   *     "transmission": {
   *       "manual": [variant1, variant2],
   *       "automatic": [variant3, variant4],
   *     }
   *   }
   * }
   * @type {Record<string, Record<string, GroupedCars>>}
   */
  const configurationsByVariant = useMemo(() => {
    return Object.entries(carsByVariant).reduce((acc, [variant, variants]) => {
      return {
        ...acc,
        [variant]: getCarConfigurations(variants),
      };
    }, {});
  }, [carsByVariant]);

  const selectedVariant = useMemo(() => {
    return availableCars.find((v) => v.model_version === selectedModelVersion);
  }, [availableCars, selectedModelVersion]);

  const onConfigurationSelect = useCallback(
    (variantName, configuration = {}) => {
      if (variantName !== selectedVariant?.variant) {
        // Selecting a new variant, we can ignore the properties of the currently selected variant
        const newVariant = selectMostMatchingVariantByConfig(
          carsByVariant[variantName],
          configuration,
        );
        onSelectModelVersion(newVariant?.model_version);
        return;
      }

      // Selecting a configuration of the same variant
      // We need to find the variant that matches the selected configuration the most
      // First, filter out the variants that exactly match the selected configuration
      const possibleVariants = carsByVariant[variantName].filter((v) => {
        return Object.entries(configuration).every(
          ([key, value]) => String(v[key]) === String(value),
        );
      });

      // If there is only one variant that matches the configuration, select it
      if (possibleVariants.length === 1) {
        onSelectModelVersion(possibleVariants[0].model_version);
        return;
      }

      // If there are multiple variants that match the configuration, select the one that has the most matching properties
      const currentConfiguration = variableProperties.reduce((acc, key) => {
        return {
          ...acc,
          [key]: selectedVariant[key],
        };
      }, {});

      // Override matching properties with the new configuration
      const wantedConfiguration = {
        ...currentConfiguration,
        ...configuration,
      };

      const newVariant = selectMostMatchingVariantByConfig(possibleVariants, wantedConfiguration);
      onSelectModelVersion(newVariant?.model_version);
    },
    [carsByVariant, onSelectModelVersion, selectedVariant],
  );

  const handleAccordionChange = useCallback(
    (variant) => {
      onConfigurationSelect(variant);
    },
    [onConfigurationSelect],
  );

  function format_registration_no(reg_no) {
    return reg_no.substring(0,2)+" "+reg_no.substring(2);
  }

  return (
    <div className={cn("car-details__variant-select__accordion", className)}>
      <p className="font-bold text-primary my-2">
        { selectedVariant?.kjarabill ?
          <><span className={cn("car-registration-no")}>{format_registration_no(selectedVariant?.registration_no)}</span></>
          :
          <>Veldu <span className="text-secondary">gerð</span></>
        }
      </p>
      <Accordion
        type="single"
        value={selectedVariant?.variant}
        onValueChange={handleAccordionChange}
      >
        {Object.entries(configurationsByVariant)
          .sort(([variantA], [variantB]) => {
            return (
              getLowestPrice(carsByVariant[variantA], rental) -
              getLowestPrice(carsByVariant[variantB], rental)
            );
          })
          .map(([variantName, configurations]) => (
            <AccordionItem key={variantName} value={variantName}>
              <AccordionHeader className="car-details__variant-select__accordion__header">
                <p className="car-details__variant-select__accordion__header__title">
                  {variantName}
                </p>
                <p className="car-details__variant-select__accordion__header__price">
                  {rental ? "Leiga á mánuði frá" : "Verð:"}{" "}
                  <span className="fs-5 fw-medium mx-1">
                    {formatNumber(getLowestPrice(carsByVariant[variantName], rental))}
                  </span>
                </p>
              </AccordionHeader>
              {hasMultipleConfigurations(configurations) && (
                <AccordionContent className="p-3">
                  <div className="d-grid grid-cols-2 gap-3">
                    {Object.entries(configurations)
                      .filter(([_, values]) => Object.keys(values).length > 1)
                      .map(([variableProperty, carConfigurations]) =>
                        Object.entries(carConfigurations).map(([value, variants]) => (
                          <Button
                            key={value}
                            label={getConfigurationLabel(variableProperty, value)}
                            primary={selectedModelVersion === variants[0].model_version}
                            onClick={() =>
                              onConfigurationSelect(variantName, { [variableProperty]: value })
                            }
                          />
                        )),
                      )}
                  </div>
                </AccordionContent>
              )}
            </AccordionItem>
          ))}
      </Accordion>
    </div>
  );
};

/**
 * @typedef {keyof import("@/api/generated/cars").CarDetailSchema} CarDetailSchemaKey
 */

/**
 * @constant
 * @type {Array<CarDetailSchemaKey>} variableProperties
 */
const variableProperties = ["transmission", "drive", "seats", "fuelType"];

/**
 * @typedef {Record<string, import("@/api/generated/cars").CarDetailSchema[]>} GroupedCars
 */

/**
 * @param {import("@/api/generated/cars").CarDetailSchema[]} variants
 * @returns {Record<string, GroupedCars>}
 */
const getCarConfigurations = (variants) => {
  return variableProperties.reduce((acc, key) => {
    const values = uniqueArray(variants.map((v) => v[key])).filter(Boolean);

    const variantConfigurations = values.reduce((acc, value) => {
      return {
        ...acc,
        [value]: variants.filter((v) => v[key] === value),
      };
    }, {});

    return {
      ...acc,
      [key]: variantConfigurations,
    };
  }, {});
};

/**
 * Check if a variant has multiple configurations
 * @param {Record<string, GroupedCars>} configurations
 * @returns {boolean}
 */
const hasMultipleConfigurations = (configurations) => {
  return Object.values(configurations).some((values) => Object.keys(values).length > 1);
};

/**
 * Get a label for a variant configuration property
 * @param {CarDetailSchemaKey} key
 * @param {string | number} value
 * @returns {string}
 */
const getConfigurationLabel = (key, value) => {
  switch (key) {
    case "transmission":
      return value;
    case "drive":
      return value;
    case "seats":
      return `${value} sæti`;
    case "fuelType":
      return value;
    default:
      return value;
  }
};

/**
 * Select a most fitting variant based on the chosen configuration
 * @param {import("@/api/generated/cars").CarDetailSchema[]} variants
 * @param {Record<string, string>} configuration
 * @returns {import("@/api/generated/cars").CarDetailSchema}
 */
const selectMostMatchingVariantByConfig = (variants, configuration) => {
  // Find the variant that has the most matching properties
  // The more matching properties, the more fitting the variant
  // If there are multiple variants with the same amount of matching properties, select the first one
  if (Object.keys(configuration).length === 0) {
    return variants[0];
  }

  return (
    variants.reduce(
      (acc, variant) => {
        const matchingProperties = Object.entries(configuration).filter(([key, value]) => {
          return variant[key] === value;
        });

        return matchingProperties.length > acc.matchingProperties.length
          ? { variant, matchingProperties }
          : acc;
      },
      { variant: null, matchingProperties: [] },
    ).variant ?? variants[0]
  );
};

/**
 * Get the lowest price of a list of cars
 * @param {import("@/api/generated/cars").CarDetailSchema[]} cars - List of variants
 * @param {boolean} [rental=false] - Whether to get the lowest rental price. Defaults to purchase price
 */
const getLowestPrice = (cars, rental = false) => {
  if (rental) {
    return cars.reduce((acc, variant) => {
      return variant.rental_options.reduce((acc, ro) => {
        return ro.price < acc ? ro.price : acc;
      }, acc);
    }, Infinity);
  }

  return cars.reduce((acc, variant) => {
    return variant.price < acc ? variant.price : acc;
  }, Infinity);
};

/**
 * Get the cheapest car from a list of cars
 * @param {import("@/api/generated/cars").CarDetailSchema[]} cars - List of variants
 * @param {boolean} [rental=false] - Whether to get the cheapest rental car. Defaults to purchase price
 * @returns {import("@/api/generated/cars").CarDetailSchema}
 */
const getCheapestCar = (cars, rental = false) => {
  return cars.reduce((acc, variant) => {
    if (rental) {
      const isCheaper = variant.rental_options.some((ro) => {
        return acc.rental_options.every((accRo) => {
          return ro.price < accRo.price;
        });
      });

      return isCheaper ? variant : acc;
    }

    return variant.price < acc.price ? variant : acc;
  }, cars[0]);
};

/**
 * Get the lowest rental price of a single variant
 * @param {import("@/api/generated/cars").CarDetailSchema[]} variant - single car
 */
const getLowestRentalPrice = (variant) => {
  return variant.rental_options.reduce((acc, ro) => {
    return ro.price < acc ? ro.price : acc;
  }, Infinity);
};
