import React from "react";

import { LoadingSpinner } from "@/components";
import { cn } from "@/utils";

export const CarCardGridLayout = (props) => {
  return <div className="card-grid" {...props} />;
};

export const CarGridGrid = React.forwardRef(function CarGridGrid(
  { children, className, context: _, ...props },
  ref,
) {
  return (
    <div ref={ref} className={cn("card-grid__grid", className)} {...props}>
      {children}
    </div>
  );
});

export const LoadingIndicator = ({ context }) => {
  if (!context?.isFetching) return null;

  return (
    <div className="card-grid__loading-indicator">
      <LoadingSpinner />
    </div>
  );
};

export const gridComponents = {
  List: CarGridGrid,
  Footer: LoadingIndicator,
};
