import { isEqual } from "lodash";
import React, { useCallback, useMemo, useState, useEffect } from "react";

import {
  useCarDriveTypesQuery,
  useCarFuelTypesQuery,
  useCarMakesQuery,
  useCarModelsQuery,
  useCarTransmissionTypesQuery,
} from "@/api/generated/cars";
import {
  BlueExit,
  Button,
  CarsGroupedGrid,
  Dropdown,
  PriceRangeSlider,
  QueryDropdown,
  Toggle,
  Toggler,
} from "@/components";
import { CAR_CATEGORY, FUEL_TYPE } from "@/const";
import { useDebounce, useQueryParamsState } from "@/hooks";
import { cn, sortObjectByKey } from "@/utils";

export const CarListContainer = () => {
  // Standard filters
  const [fuelType, setFuelType] = useQueryParamsState("fuelType", null);
  const [minPrice, setMinPrice] = useQueryParamsState("minPrice", null);
  const [maxPrice, setMaxPrice] = useQueryParamsState("maxPrice", null);
  const [sort, setSort] = useQueryParamsState("sort", null);
  const [rental, setRental] = useQueryParamsState("rental", null);
  const [category, setCategory] = useQueryParamsState("category", null);
  const [kjarabill, setKjarabill] = useQueryParamsState("kjarabill", null);

  // Advanced filters
  const [showAdvancedFilters, setShowAdvancedFilters] = useState(false);
  const [make, setMake] = useQueryParamsState("make", null);
  const [model, setModel] = useQueryParamsState("model", null);
  const [color, setColor] = useQueryParamsState("color", null);
  const [drive, setDrive] = useQueryParamsState("drive", null);
  const [transmission, setTransmission] = useQueryParamsState("transmission", null);
  const [seats, setSeats] = useQueryParamsState("seats", null);
  const [doors, setDoors] = useQueryParamsState("doors", null);
  const [isofixPoints, setIsofixPoints] = useQueryParamsState("isofixPoints", null);

  // Mobile filters
  const [mobileFiltersOpen, setMobileFiltersOpen] = useState(false);

  const advancedFiltersActive = useMemo(() => {
    const filterValues = [
      make,
      model,
      color,
      fuelType,
      minPrice,
      maxPrice,
      seats,
      doors,
      isofixPoints,
      drive,
      transmission,
    ];
    return filterValues.some((value) => !!value);
  }, [make, model, color, fuelType, minPrice, maxPrice, seats, doors, isofixPoints, drive, transmission]);

  const filters = useMemo(() => {
    return {
      make,
      model,
      color,
      fuelType,
      category,
      seats,
      doors,
      isofixPoints,
      drive,
      transmission,
      sort,
      ...(rental
        ? {
            minRentalPrice: minPrice,
            maxRentalPrice: maxPrice,
          }
        : {
            minPrice,
            maxPrice,
          }),
      rentalAvailable: rental,
      kjarabill: false,
      limit: 12,
    };
  }, [
    make,
    model,
    color,
    fuelType,
    category,
    seats,
    doors,
    isofixPoints,
    drive,
    transmission,
    sort,
    rental,
    minPrice,
    maxPrice,
    kjarabill
  ]);

  const debouncedFilters = useDebounce(filters, 500);

  const clearFilters = useCallback(() => {
    setFuelType(null);
    setMinPrice(null);
    setMaxPrice(null);
    setSort(null);
    setMake(null);
    setModel(null);
    setColor(null);
    setDrive(null);
    setTransmission(null);
    setSeats(null);
    setDoors(null);
    setIsofixPoints(null);
  }, [
    setColor,
    setDoors,
    setDrive,
    setFuelType,
    setIsofixPoints,
    setMake,
    setMaxPrice,
    setMinPrice,
    setModel,
    setSeats,
    setSort,
    setTransmission,
  ]);

  const onRentalChangeCallback = useCallback(
    (checked) => {
      setRental(checked ? true : null);
      setMinPrice(null);
      setMaxPrice(null);
    },
    [setRental, setMinPrice, setMaxPrice],
  );

  const onPriceRangeSliderUpdate = useCallback(
    (are_offers) => {
      if (!are_offers) {
        document.querySelectorAll(".col-md.col-12.d-flex.flex-row.category-filters .TILBOD")[0].style.display = "none";
      }
    },[]
  );

  return (
    <>
      {/* DESKTOP FILTERS */}
      {/* d-none d-md-block  */}
      <div className="filter-section-desktop filter-section">
        <div className="row justify-content-between align-items-center mb-4">
          <div className="col-lg-4 col-md-6 col-12">
            <div className="d-flex justify-content-between align-items-center gap-3 mb-md-0 mb-5">
              <a href="/kjarabilar/" className="button button--kjarabilar">
                Kjarabílar
              </a>
              <Toggler
                className="advanced-search d-none d-md-flex"
                label="Ítarleit"
                onChange={(checked) => setShowAdvancedFilters(checked)}
              />
              <Toggler
                className={cn("advanced-search mobile- d-flex d-md-none")}
                label="Ítarleit"
                value={mobileFiltersOpen}
                onChange={() => setMobileFiltersOpen(!mobileFiltersOpen)}
              />
              <Toggle
                value={rental}
                onChange={onRentalChangeCallback}
                label_l="Kaup"
                label_r="Leiga"
                className="mx-lg-auto"
              />
            </div>
          </div>

          <div className="col-xl-4 col-md-6 col-12 mb-xl-0 mb-3">
            <PriceRangeSlider
              value={[minPrice, maxPrice]}
              onChange={([min, max]) => {
                setMinPrice(min);
                setMaxPrice(max);
              }}
              rental={rental}
              label="Verðbil"
              step={100000}
              onUpdate={onPriceRangeSliderUpdate}
            />
          </div>
        </div>

        <div className="row justify-content-between gy-3 d-none d-md-block">
          {showAdvancedFilters && (
            <div className="col-12">
              {advancedFiltersActive && (
                <div className="d-flex justify-content-end">
                  <label htmlFor="clear-filter" className="filter-clear-button-label">Hreinsa allt val</label>
                  <Button
                    id="clear-filter"
                    onClick={clearFilters}
                    aria-label="Hreinsa allt val"
                    icon={<BlueExit />}
                    size="extra-small"
                    noBorder
                    className="fw-medium"
                  />
                </div>
              )}
              <div className="d-flex flex-row flex-wrap gap-4">
                <QueryDropdown
                  aria-label="Framleiðandi"
                  placeholder="Framleiðandi"
                  query={useCarMakesQuery}
                  sort="name"
                  getOptionLabel={(carMake) => carMake.name}
                  getOptionValue={(carMake) => carMake.code}
                  value={make}
                  onChange={(val) => setMake(val)}
                  isMulti
                />
                <QueryDropdown
                  aria-label="Orkugjafar"
                  placeholder="Orkugjafar"
                  query={useCarFuelTypesQuery}
                  options={fuelTypeOptions}
                  getOptionLabel={(option) => option.label}
                  getOptionValue={(option) => option.value}
                  sort="name"
                  value={fuelType}
                  onChange={(val) => setFuelType(val)}
                  isMulti
                />
                <QueryDropdown
                  aria-label="Undirtegund"
                  placeholder="Undirtegund"
                  query={useCarModelsQuery}
                  sort="name"
                  getOptionLabel={(carModel) => carModel.name}
                  getOptionValue={(carModel) => carModel.code}
                  value={model}
                  onChange={(val) => setModel(val)}
                  isMulti
                />
                {/* Color filter disabled until colors have been fixed in database by BL */}
                {/* <QueryDropdown
                  aria-label="Litur"
                  placeholder="Litur"
                  query={useCarColorsQuery}
                  sort="name"
                  getOptionLabel={(carColor) => carColor.name.toLowerCase()}
                  getOptionValue={(carColor) => carColor.name}
                  value={color}
                  onChange={(val) => setColor(val)}
                  isMulti
                /> */}
                <QueryDropdown
                  aria-label="Drif"
                  placeholder="Drif"
                  query={useCarDriveTypesQuery}
                  sort="name"
                  getOptionLabel={(carDriveType) => carDriveType.name}
                  getOptionValue={(carDriveType) => carDriveType.name}
                  value={drive}
                  onChange={(val) => setDrive(val)}
                  isMulti
                />
                <QueryDropdown
                  aria-label="Skipting"
                  placeholder="Skipting"
                  query={useCarTransmissionTypesQuery}
                  sort="name"
                  getOptionLabel={(carTransmissionType) => carTransmissionType.name}
                  getOptionValue={(carTransmissionType) => carTransmissionType.name}
                  value={transmission}
                  onChange={(val) => setTransmission(val)}
                  isMulti
                />
                <Dropdown
                  aria-label="Fjöldi sæta"
                  placeholder="Fjöldi sæta"
                  options={seatsOptions}
                  value={seats}
                  onChange={(val) => setSeats(val)}
                />
                <Dropdown
                  aria-label="Fjöldi hurða"
                  placeholder="Fjöldi hurða"
                  options={doorsOptions}
                  value={doors}
                  onChange={(val) => setDoors(val)}
                />
                <Dropdown
                  aria-label="Fjöldi ISOfix"
                  placeholder="Fjöldi ISOfix"
                  options={isofixOptions}
                  value={isofixPoints}
                  onChange={(val) => setIsofixPoints(val)}
                />
              </div>
            </div>
          )}
        </div>
        <div className="row justify-content-center align-items-center mt-4">
          <div className="col-md col-12 d-flex flex-row category-filters">
            {carCategoryOptions.map((option) => (
              <Button
                key={option.value}
                label={option.label}
                onClick={() => setCategory(option.value)}
                underline={isEqual(category, option.value)}
                noBorder
                size="small"
                className={cn("fw-medium",option.value)}
              />
            ))}
          </div>
          <div className="col-auto ms-auto">
            <Dropdown
              className="text-nowrap no-border d-none d-md-block"
              options={sortOptions}
              value={sort}
              onChange={(val) => setSort(val)}
              placeholder="Sjálfgefin röðun"
            />
          </div>
        </div>
      </div>

      {/* MOBILE FILTERS */}
      <div className="d-block d-md-none filter-section-mobile filter-section">
        {mobileFiltersOpen && (
          <div className="filter-overlay mobile-advanced-search">
            <div className="row justify-content-center">
              <div className="col-11">
                <div className="d-flex justify-content-start align-items-center gap-3">
                  {/* Button for Kjarabílar page */}
                  {/* <Button
                      label="Kjarabílar"
                      className="button--kjarabilar"
                    /> */}
                  <Toggler
                    className="advanced-search filter-active"
                    label="Ítarleit"
                    aria-label="Ítarleit"
                    onChange={() => setMobileFiltersOpen(false)}
                  />
                </div>
              </div>

              <div className="row justify-content-center">
                <div className="col-12 p-4 mb-5">
                  {advancedFiltersActive && (
                    <div className="d-flex justify-content-end">
                      <Button
                        onClick={clearFilters}
                        label="Hreinsa allt val"
                        size="extra-small"
                        noBorder
                        className="fw-medium"
                      />
                    </div>
                  )}
                  <div className="row gy-3">
                    <QueryDropdown
                      label-aria="Framleiðandi"
                      placeholder="Framleiðandi"
                      query={useCarMakesQuery}
                      sort="name"
                      getOptionLabel={(carMake) => carMake.name}
                      getOptionValue={(carMake) => carMake.code}
                      value={make}
                      onChange={(val) => setMake(val)}
                      isMulti
                    />
                    <QueryDropdown
                      label-aria="Orkugjafar"
                      placeholder="Orkugjafar"
                      query={useCarFuelTypesQuery}
                      options={fuelTypeOptions}
                      getOptionLabel={(option) => option.label}
                      getOptionValue={(option) => option.value}
                      sort="name"
                      value={fuelType}
                      onChange={(val) => setFuelType(val)}
                      isMulti
                    />
                    <QueryDropdown
                      label-aria="Undirtegund"
                      placeholder="Undirtegund"
                      query={useCarModelsQuery}
                      sort="name"
                      getOptionLabel={(carModel) => carModel.name}
                      getOptionValue={(carModel) => carModel.code}
                      value={model}
                      onChange={(val) => setModel(val)}
                      isMulti
                    />
                    {/* Color filter disabled until colors have been fixed in database by BL */}
                    {/* <QueryDropdown
                            label-aria="Litur"
                            placeholder="Litur"
                            query={useCarColorsQuery}
                            sort="name"
                            getOptionLabel={(carColor) => carColor.name.toLowerCase()}
                            getOptionValue={(carColor) => carColor.name}
                            value={color}
                            onChange={(val) => setColor(val)}
                            isMulti
                          /> */}
                    <QueryDropdown
                      label-aria="Drif"
                      placeholder="Drif"
                      query={useCarDriveTypesQuery}
                      sort="name"
                      getOptionLabel={(carDriveType) => carDriveType.name}
                      getOptionValue={(carDriveType) => carDriveType.name}
                      value={drive}
                      onChange={(val) => setDrive(val)}
                      isMulti
                    />
                    <QueryDropdown
                      label-aria="Skipting"
                      placeholder="Skipting"
                      query={useCarTransmissionTypesQuery}
                      sort="name"
                      getOptionLabel={(carTransmissionType) => carTransmissionType.name}
                      getOptionValue={(carTransmissionType) => carTransmissionType.name}
                      value={transmission}
                      onChange={(val) => setTransmission(val)}
                      isMulti
                    />
                    <Dropdown
                      label-aria="Fjöldi sæta"
                      placeholder="Fjöldi sæta"
                      options={seatsOptions}
                      value={seats}
                      onChange={(val) => setSeats(val)}
                    />
                    <Dropdown
                      label-aria="Fjöldi hurða"
                      placeholder="Fjöldi hurða"
                      options={doorsOptions}
                      value={doors}
                      onChange={(val) => setDoors(val)}
                    />
                    <Dropdown
                      label-aria="Fjöldi ISOfix"
                      placeholder="Fjöldi ISOfix"
                      options={isofixOptions}
                      value={isofixPoints}
                      onChange={(val) => setIsofixPoints(val)}
                    />
                  </div>
                </div>
              </div>
              <div className="col-6">
                <Button
                  onClick={() => setMobileFiltersOpen(false)}
                  label="Hefja leit"
                  primary
                  size="large"
                  className="w-100 text-uppercase button--bl-blue"
                />
              </div>
            </div>
          </div>
        )}
      </div>
      <hr className="my-0" />
      {/* Remount component when filters change to avoid stale data */}
      {/* DO NOT REMOVE key prop */}
      <CarCardGridMemo
        filters={debouncedFilters}
        showRentalPrices={rental}
        key={JSON.stringify(sortObjectByKey(debouncedFilters))}
      />
    </>
  );
};

const CarCardGridMemo = React.memo(CarsGroupedGrid);

const sortOptions = [
  { value: null, label: "Sjálfgefin röðun" },
  { value: "min_price", label: "Lægsta verð fyrst" },
  { value: "-min_price", label: "Hæsta verð fyrst" },
];

const seatsOptions = [
  { value: [1, 3], label: "1-3" },
  { value: [4, 5], label: "4-5" },
  { value: [6, 7], label: "6-7" },
  { value: [8, 10], label: "8-10" },
  { value: [11, 100], label: "11+" },
];

const doorsOptions = [
  { value: [2, 2], label: "2" },
  { value: [3, 3], label: "3" },
  { value: [4, 4], label: "4" },
  { value: [5, 5], label: "5" },
];

const isofixOptions = [
  { value: [2, 2], label: "2" },
  { value: [3, 3], label: "3" },
  { value: [4, 4], label: "4" },
  { value: [5, 5], label: "5" },
];

const carCategoryOptions = [
  { value: null, label: "Allar gerðir" },
  { value: [CAR_CATEGORY.TILBOD], label: "Tilboð" },
  { value: [CAR_CATEGORY.JEPPI, CAR_CATEGORY.JEPPLINGUR], label: "Jeppar & jepplingar" },
  { value: CAR_CATEGORY.FOLKSBILL, label: "Fólksbílar" },
  { value: CAR_CATEGORY.ATVINNUBILL, label: "Atvinnubílar" },
];

const fuelTypeOptions = [
  { value: FUEL_TYPE.ELECTRIC, label: "Rafmagnsbílar" },
  {
    value: [
      FUEL_TYPE.PETROL_HYBRID,
      FUEL_TYPE.DIESEL_HYBRID,
      FUEL_TYPE.PETROL_PLUGIN,
      FUEL_TYPE.DIESEL_PLUGIN,
    ],
    label: "Hybrid / Plugin hybrid",
  },
  { value: [FUEL_TYPE.PETROL, FUEL_TYPE.DIESEL], label: "Bensín / Dísil" },
];
