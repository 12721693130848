import React from "react";

import { useCarWinterTireTypesQuery } from "@/api/generated/cars";
import { LoadingSpinner } from "@/components";
import { useControllableState } from "@/hooks";

/**
 * @param {Object} props
 * @param {import('@/api/generated/cars').CarWinterTiresTypeSchema)} [props.selectedWinterTires] - The selected winter tires
 * @param {Function} [props.onSelectedWinterTiresChange] - The callback to call when the selected winter tires change
 */
export const CarWinterTiresSelector = ({
  selectedWinterTires: selectedWinterTiresProp,
  onSelectedWinterTiresChange,
}) => {
  const { data: winterTireTypes, isLoading, isError } = useCarWinterTireTypesQuery();
  const [selectedWinterTires, setSelectedWinterTires] = useControllableState({
    prop: selectedWinterTiresProp,
    onChange: onSelectedWinterTiresChange,
    defaultProp: [],
  });

  return (
    <div>
      <p className="h4 text-primary fw-bold">Vetrardekk</p>
      {isLoading && (
        <div className="d-flex justify-content-center m-3">
          <LoadingSpinner />
        </div>
      )}
      {!isLoading && !isError && winterTireTypes?.length === 0 && (
        <p className="alert alert-info text-center fs-6">Þessi bíll býður ekki upp á vetrardekk.</p>
      )}
      {isError && (
        <p className="alert alert-danger text-center fs-6">
          Villa kom upp við að sækja vetrardekk.
        </p>
      )}
      <div className="d-flex flex-wrap gap-4 mt-3">
        {winterTireTypes?.map((option) => (
          <div key={option.code} className="d-flex align-items-center gap-2">
            <input
              type="radio"
              id={option.value}
              name="winterTires"
              className="form-check-input m-0"
              style={{ width: "1.5rem", height: "1.5rem" }}
              value={option.code}
              checked={selectedWinterTires === option.code}
              onChange={() => setSelectedWinterTires(option.code)}
            />
            <label htmlFor={option.value} className="form-check-label fw-bold">
              {option.name}
            </label>
          </div>
        ))}
      </div>
    </div>
  );
};
