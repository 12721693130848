import React, { useMemo } from "react";

import { CarStatusBadge, Pill } from "@/components";
import { cn, formatNumber } from "@/utils";

import { CarInfoList, FuelTypesList } from "./CarCardsComponents";

import {CAR_DISPLAY_STATUS} from "@/const";

/**
 * @param {Object} props
 * @param {import("@/api/generated/cars").CarSchema } props.car - Car data
 * @param {Boolean} [props.showMake=false] - Show make of the car
 * @param {Boolean} [props.rental=false] - Show min rental price of the car. If false, show min purchase price
 * @param {string} [props.className] - Additional classes to apply
 */
export const CarCard = ({ car, showMake = false, rental = false, className, ...props }) => {
  const href = useMemo(() => {
    const url = new URL(`/bilar/${car.id}`, window.location.origin);

    if (rental) {
      url.searchParams.append("type", "rental");
    }

    return url.href;
  }, [car.id, rental]);

  const eligibleForEnergyGrant = useMemo(
    () => car.discounts.some((discount) => discount.code === "energy_grant"),
    [car],
  );

  const modelOnOffer = useMemo(
    () => car.discounts.some((discount) => discount.code === "offer_price"),
    [car],
  );

  return (
    <div className={cn("card car-card", className)} {...props}>
      <a href={href} className="car-card__link">
        <div className="car-card__badge-container">
          <div className="badge-wrapper d-flex gap-3 ms-auto">
            <CarStatusBadge status={car.display_status} />
            {car.kjarabill && <CarStatusBadge status={CAR_DISPLAY_STATUS.KJARABILL} />}
            {eligibleForEnergyGrant && <Pill label="Orkusjóðsstyrkur" color="green" />}
            {modelOnOffer && <CarStatusBadge status={CAR_DISPLAY_STATUS.MODEL_ON_OFFER} />}
          </div>
        </div>
        <img src={car.image} className="car-card__img" alt={`${car.make} ${car.model}`} />
        <p className="car-card__title">{`${showMake ? car.make : ""} ${car.model}`}</p>
        <FuelTypesList fuelTypes={[car.fuelType]} />
        <div className="car-card__info-container">
          <div className="car-card__price-container">
            <CarPrice car={car} rental={rental} />
          </div>
          <CarInfoList
            batteryCapacity={car.battery_capacity}
            range={car.range}
            fuelEconomy={car.fuel_economy}
          />
        </div>
      </a>
    </div>
  );
};

/**
 * @param {Object} props
 * @param {import("@/api/generated/cars").CarSchema} props.car - Car data
 * @param {Boolean} props.rental - Show rental prices instead of purchase prices
 */
const CarPrice = ({ car, rental }) => {
  if (rental) {
    return (
      <>
        <p className="car-card__price-label">Verð frá</p>
        <p className="car-card__price">
          {formatNumber(
            car.rental_options.reduce((min, option) => Math.min(min, option.price), Infinity),
          )}{" "}
          kr.
        </p>
      </>
    );
  }

  if (car.kjarabill_price > 0) {
    const energy_grant = car.discounts.find((d) => d.code === "energy_grant");
    const kjara_price = car.kjarabill_price - (energy_grant ?  energy_grant.amount : 0);
    return (
      <>
        <div className="car-card__kjarabill-price">
          <p className="car-card__price-label">Verð</p>
          <p className="car-card__price">{formatNumber(kjara_price)} kr.</p>
        </div>
        <p className="car-card__price-before">{formatNumber(car.price)} kr.</p>
      </>
    );
  }

  return (
    <>
      <p className="car-card__price-label">Verð</p>
      <p className="car-card__price">{formatNumber(car.price)} kr.</p>
    </>
  );
};
