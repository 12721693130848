import React, { useCallback, useState } from "react";
import { useForm } from "react-hook-form";

import {
  useCarAccessoriesQuery,
  useCarLongTermRentalMutation,
  useCarWinterTireTypesQuery,
} from "@/api/generated/cars";
import { Button, LoadingSpinner } from "@/components";

import { Modal } from "../modal/Modal";
import { SelectedCarInfo } from "./SelectedCarInfo";

/**
 * @typedef {Object} SelectedAccessories
 * @property {string} winterTires - Selected winter tires
 * @property {number[]} accessories - Selected accessories
 */

/**
 * @param {Object} props
 * @param {import('@/api/generated/cars').CarDetailSchema} props.car - The car to checkout
 * @param {import('@/api/generated/cars').CarRentalOptionSchema} props.selectedRentalOption - The selected rental option
 * @param {SelectedAccessories} props.selectedAccessories - The selected accessories
 * @param {Record<string, string>} props.customerInfo - The customer info
 */
export const RentalSummary = ({ car, selectedRentalOption, selectedAccessories, customerInfo }) => {
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();
  const { data: winterTireTypes } = useCarWinterTireTypesQuery();
  const { data: accessories } = useCarAccessoriesQuery({ carId: car.id });
  const [sendRentalApplication, { isLoading, isSuccess, isError }] = useCarLongTermRentalMutation();

  const onSubmit = useCallback(
    (data) => {
      sendRentalApplication({
        carId: car.id,
        carLongTermRentalCheckoutSchema: {
          name: customerInfo.name,
          kennitala: customerInfo.kennitala,
          tel: customerInfo.tel,
          email: customerInfo.email,
          rental_option: selectedRentalOption,
          accessories: selectedAccessories.accessories,
          winter_tires: selectedAccessories.winterTires,
          accept_terms: data.acceptTerms,
        },
      });
    },
    [
      car.id,
      customerInfo.email,
      customerInfo.kennitala,
      customerInfo.name,
      customerInfo.tel,
      selectedAccessories.accessories,
      selectedAccessories.winterTires,
      selectedRentalOption,
      sendRentalApplication,
    ],
  );

  return (
    <div className="d-flex flex-column gap-4 rental-summary">
      <SelectedCarInfo car={car} selectedRentalOption={selectedRentalOption} />
{/*       
      <div>
        <h2 className="h4">Aukahlutir</h2>
        <table className="table">
          <tbody>
            <tr>
              <td className="text-start">Vetrardekk</td>
              {selectedAccessories.winterTires ? (
                <td className="fw-medium text-end">
                  {winterTireTypes.find((t) => t.code === selectedAccessories.winterTires)?.name}
                </td>
              ) : (
                <td>Nei</td>
              )}
            </tr>
            <tr>
              <td className="text-start">Aukabúnaður</td>
              <td className="fw-medium text-end">
                {selectedAccessories.accessories
                  .map((accessoryId) => accessories.find((a) => a.id === accessoryId)?.description)
                  .join(", ")}
              </td>
            </tr>
          </tbody>
        </table>
      </div> */}
      <div>
        <h2 className="h4">Upplýsingar um leigutaka</h2>
        <table className="table">
          <tbody>
            <tr>
              <td className="text-start">Leigutaki</td>
              <td className="fw-medium text-end">{customerInfo.name}</td>
            </tr>
            <tr>
              <td className="text-start">Kennitala</td>
              <td className="fw-medium text-end">{customerInfo.kennitala}</td>
            </tr>
            <tr>
              <td className="text-start">Sími</td>
              <td className="fw-medium text-end">{customerInfo.tel}</td>
            </tr>
            <tr>
              <td className="text-start">Tölvupóstur</td>
              <td className="fw-medium text-end">{customerInfo.email}</td>
            </tr>
          </tbody>
        </table>
      </div>
      {isSuccess ? (
        <div className="alert alert-success">Umsókn móttekin, við munum hafa samband</div>
      ) : (
        <form onSubmit={handleSubmit(onSubmit)} className="d-flex flex-column gap-5">
          <div className="form-check">
            <input
              type="checkbox"
              className="form-check-input"
              id="acceptTerms"
              {...register("acceptTerms", { required: "Samþykki er nauðsynlegt" })}
            />
            <label className="form-check-label fw-bold text-primary" htmlFor="acceptTerms">
              Ég samþykki notkun{" "}
              <a
                href="#"
                onClick={(e) => {
                  e.preventDefault();
                  setModalIsOpen(true);
                }}
                className="text-secondary"
              >
                lánshæfismats
              </a>
            </label>
            {errors.acceptTerms && <p className="text-danger">{errors.acceptTerms.message}</p>}
          </div>
          <div className="d-flex justify-content-center">
            <div className="d-flex flex-column gap-4 w-max min-w-5">
              <Button
                type="submit"
                icon={
                  isLoading && (
                    <LoadingSpinner className="text-white m-2" style={{ width: "2rem" }} />
                  )
                }
                label="Senda umsókn"
                primary
                className="w-100"
                disabled={isLoading}
              />
            </div>
          </div>
        </form>
      )}
      {isError && (
        <div className="alert alert-danger">
          Villa kom upp við að senda umsókn. Vinsamlegast reyndu aftur.
        </div>
      )}
      <Modal isOpen={modalIsOpen} onRequestClose={() => setModalIsOpen(false)} title="Lánshæfismat">
        <h3 className="mb-4">Umboð til öflunar upplýsinga um fjárhag einstaklings</h3>
        <div>
          <p className="text-small">
            Ég undirritaður/undirrituð, umboðsveitandi, veiti Flex ehf., kt. 660321-0630, (hér eftir
            „Flex“) fullt og ótakmarkað umboð til þess að afla upplýsinga um fjárhagsmálefni mín og
            eigna- og skuldastöðu í þeim tilgangi að leggja mat á lánshæfi mitt og/eða greiðslugetu
            í samræmi við lög.
          </p>
          <p className="text-small">
            Umboð þetta veitir Flex heimild til að kalla eftir upplýsingum um tekjur mínar og gjöld,
            eignir og skuldir ásamt öðrum fjárskuldbindingum, frá öllum þeim aðilum sem geta veitt
            slíkar upplýsingar. Um er að ræða m.a. en ekki takmarkað við banka, sparisjóði,
            lánafyrirtæki, lífeyrissjóði, tryggingarfélög, Húsnæðis og mannvirkjastofnun, aðrar
            fjármálastofnanir eða fjárhagsupplýsingastofur, ásamt öllum öðrum gögnum og upplýsingum
            tengdum þeim fjárskuldbindingum, þ.á m. hvort þau séu í vanskilum eða komin í innheimtu
            hjá þriðja aðila. Flex er heimilt að afla upplýsinga hvort sem er beint frá viðkomandi
            kröfuhafa eða í gegnum Creditinfo Lánstrausts hf., kt. 710197-2109. Umboðið veitir
            jafnframt heimild til þess að sækja upplýsingar í vanskilaskrá og um lánshæfismat*
            (áhættumat) mitt til Creditinfo Lánstrausts hf. Lánshæfismatið spáir fyrir um líkur á
            alvarlegum vanskilum í framtíðinni og byggir á upplýsingum sem Creditinfo safnar og/ eða
            miðlar, s.s. upplýsingum í vanskilaskrá, hlutafélagaskrá, skattskrá o.fl. Lánshæfismatið
            byggir á samkeyrslu gagna og getur innihaldið sögulegar upplýsingar, s.s. um stöðu
            vanskila og lánshæfismats. Umboð þetta nær einnig til sjálfvirkrar vöktunar Flex á
            lánshæfismati og hvort breytingar hafi orðið á skráningu á vanskilaskrá Creditinfo.
            Vöktunina má viðhafa í gegnum Viðskiptamannavakt Creditinfo. Jafnframt veitir umboðið
            Flex heimild til að sækja (á grundvelli kennitölu ef með þarf) upplýsingar um
            eignastöðu, þ.e. fasteigna- og ökutækjaeign, sem miðlað er af Creditinfo Lánstraust hf.
            og sóttar í gagnagrunna Þjóðskrár Íslands og Samgöngustofu eða þess aðila sem á hverjum
            tíma telst ábyrgðaraðili umræddra gagnagrunna.
          </p>
          <p className="text-small">
            Umboð þetta veitir Creditinfo Lánstrausti hf., heimild til öflunar upplýsinga frá
            Skattinum. Í því felst heimild til öflunar upplýsinga um launatekjur úr staðgreiðsluskrá
            allt að tólf mánuði aftur í tíma frá því óskað er eftir þeim samkvæmt umboði þessu.
            Einnig heimild til að sækja síðasta skattframtal auk heimildar til þess að afla
            upplýsinga um álagningu vaxta- og barnabóta ef við á. Heimild til öflunar upplýsinga frá
            Skattinum gildir í 180 daga frá dagsetningu umboðs þessa. Þá tekur umboðið ennfremur til
            öflunar upplýsinga um skuldir við innheimtumenn ríkissjóðs, sveitarfélög og aðra
            opinbera innheimtuaðila, þ.m.t. upplýsingar um greiðslur meðlaga (þ.e. mánaðarlega
            greiðslubyrði, samkomulög, greiðslustöðu og hvort um vanskil sé að ræða). Umboðsveitandi
            veitir jafnframt Creditinfo Lánstrausti hf., sömu heimild til að sækja allar upplýsingar
            sem umboð þetta tekur til, þ.m.t. frá ríkisskattstjóra, þar sem Creditinfo sér um
            vinnslu* greiðslumats fyrir hönd Flex.
          </p>
          <p className="text-small">
            Umboðsveitandi samþykkir og heimilar að framangreindar upplýsingar verði sóttar og
            nýttar í tengslum við ákvarðanatöku um viðskipti, sem og við eftirlit í tengslum við
            slík viðskipti, boð um tiltekin kjör og ný viðskipti, enda tengist notkunin þá lögvörðum
            hagsmunum Flex. Umboðsveitandi samþykkir jafnframt að Flex miðli forsendum og
            niðurstöðum lánshæfis- og greiðslumats til annarra umsækjenda í sameiginlegu
            greiðslumati og að heimilt sé að upplýsa viðkomandi lánamiðlara/leigumiðlara um
            niðurstöðu lánshæfis- og/eða greiðslumats. Nánari upplýsingar um meðferð, varðveislu og
            vinnslu persónuupplýsinga hjá Flex má finna hér: www.flex.is. Umboð þetta, nema hvað
            varðar upplýsingar sem sóttar eru til ríkisskattstjóra, er í gildi á meðan Flex hefur
            lögvarða hagsmuni af notkun framangreindra upplýsinga. Flex er heimilt á hverjum tíma að
            sækja upplýsingar um gildandi lánshæfismat, sem og uppfærslu annarra upplýsinga sem
            umboðið nær til, á meðan umboð þetta er í gildi, svo sem vegna nýrrar viðskiptabeiðni,
            beiðni um breytingar á fyrri viðskiptasamningum og eftirlits með leigusafni. Afturköllun
            umboðs þessa skal taka gildi 30 dögum eftir að Flex hefur sannanlega móttekið
            afturköllun umboðsveitanda og/eða þegar viðskiptum aðila lýkur. Þrátt fyrir afturköllun
            umboðsins er Flex þó áfram heimilt að afla allra framangreindra gagna að því leyti sem
            nauðsynlegt er vegna þeirra skuldbindinga sem stofnað hefur verið til við Flex og eru í
            gildi þegar afturköllun er tilkynnt.
          </p>
          <p className="text-small">
            Umboðsveitandi hefur kynnt mér hvað felst í samþykki þessu vegna öflunar upplýsinga og
            gerð lánshæfis- og/eða greiðslumats og gert sér grein fyrir því að afleiðingar þess að
            veita ekki samþykki kann að leiða til þess að ekki sé hægt að verða við beiðni hans um
            leigu. Þá felst í undirritun samþykki á þeirri vinnslu sem mun eiga sér stað, tilgangi
            hennar og framkvæmd. Flex er heimilt að senda viðskiptavini skjal þetta með rafrænum
            hætti þannig að hann geti undirritað það með fullgildri rafrænni undirskrift.
          </p>
          <p className="text-small">
            *Nánari upplýsingar um meðferð, varðveislu og vinnslu persónuupplýsinga og lánshæfismat
            Creditinfo Lánstraust hf. ásamt notkunarreglum, má finna á{" "}
            <a
              className="rental-summary-link"
              target="_blank"
              rel="noreferrer"
              href="https://www.creditinfo.is/"
            >
              www.creditinfo.is
            </a>
            .
          </p>
          <button
            className="button button--primary px-5 mt-5 m-auto d-lg-none d-flex"
            size="small"
            onClick={() => setModalIsOpen(false)}
          >
            Loka
          </button>
        </div>
      </Modal>
    </div>
  );
};
