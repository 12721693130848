import React, { useCallback } from "react";

import { useCarsCompareDetailsQuery } from "@/api/generated/cars";
import { Button, CarComparisonTable, CloseFilterIcon, LoadingSpinner } from "@/components";
import { useComparisonCarsIds } from "@/hooks";

export const CarComparisonContainer = () => {
  const { comparisonCarsIds, setComparisonCarsIds } = useComparisonCarsIds();
  const { data, error, isLoading } = useCarsCompareDetailsQuery({ ids: comparisonCarsIds });

  const onRemove = useCallback(
    (id) => {
      setComparisonCarsIds((prev) => prev.filter((carId) => carId !== id));
    },
    [setComparisonCarsIds],
  );

  if (isLoading) {
    return (
      <CarComparisonLayout>
        <LoadingIndicator />
      </CarComparisonLayout>
    );
  }

  if (error) {
    return (
      <CarComparisonLayout>
        <ErrorIndicator />
      </CarComparisonLayout>
    );
  }

  if (!data || !data.length) {
    return (
      <CarComparisonLayout>
        <NoResultsIndicator />
      </CarComparisonLayout>
    );
  }

  return (
    <CarComparisonLayout>
      <CarComparisonTable cars={data} onRemove={onRemove} className="mb-5" />
    </CarComparisonLayout>
  );
};

const CarComparisonLayout = ({ children }) => {
  const { deleteComparisonCarsIds } = useComparisonCarsIds();

  return (
    <div className="container clamped">
      <div className="row">
        <div className="col-12 d-flex justify-content-end">
          <Button
            label="Hreinsa samanburð"
            icon={<CloseFilterIcon />}
            noBorder
            className="p-2 h-auto d-flex flex-row-reverse fw-normal"
            onClick={deleteComparisonCarsIds}
          />
        </div>
        <h1 className="col-12">Samanburður</h1>
        {children}
      </div>
    </div>
  );
};

const LoadingIndicator = () => (
  <div className="col-12 d-flex justify-content-center my-5">
    <LoadingSpinner />
  </div>
);

const ErrorIndicator = () => {
  return (
    <div className="alert alert-danger text-center my-5" role="alert">
      Villa kom upp, vinsamlegast reyndu aftur síðar.
    </div>
  );
};

const NoResultsIndicator = () => (
  <div className="alert alert-info text-center my-5" role="alert">
    Engir bílar til samanburðar. Vinsamlegast bættu við bílum til að geta borið saman.
  </div>
);
