import { isEqual } from "lodash";
import PropTypes from "prop-types";
import React, { useCallback, useId, useMemo, useState } from "react";
import Select, { components } from "react-select";

import { Button, CheckboxSvg } from "@/components";
import { cn } from "@/utils";

import "./dropdown.scss";

/**
 * Dropdown component
 * @param {Object} props - Component props
 * @param {Array} props.options - Array of options to choose from
 * @param {Array} props.value - Selected value(s)
 * @param {Array} props.defaultValue - Default selected value(s)
 * @param {String} props.label - Label for the dropdown
 * @param {Function} props.onChange - Function to call when value changes
 * @param {String} props.placeholder - Placeholder text
 * @param {Boolean} props.isMulti - Allow multiple selections
 * @param {Boolean} props.closeMenuOnSelect - Close menu on select
 * @param {String} props.labelClassName - Class name for the label element
 * @param {String} props.selectClassName - Class name for the select element
 * @param {String} props.className - Additional class names
 * @param {Function} props.getOptionValue - Function to get the value of an option
 * @param {...import("react-select").Props} props.props - Additional props for react-select
 */
export const Dropdown = ({
  options,
  value,
  defaultValue = [],
  label,
  onChange,
  placeholder = "Veldu",
  isMulti = false,
  closeMenuOnSelect,
  labelClassName,
  selectClassName,
  className,
  getOptionValue = (option) => option.value,
  ...props
}) => {
  const id = useId();
  const [menuOpen, setMenuOpen] = useState(false);

  const findOption = useCallback(
    (val) => {
      if (val === null) return null;

      if (isMulti && Array.isArray(val)) {
        return val.map((v) => options?.find((option) => isEqual(getOptionValue(option), v)));
      }
      return options?.find((option) => isEqual(getOptionValue(option), val));
    },
    [options, isMulti, getOptionValue],
  );

  const selectedOptions = useMemo(() => findOption(value), [value, findOption]);

  const setValue = useCallback(
    (newOption) => {
      if (onChange) {
        const newValue = isMulti ? newOption?.map(getOptionValue) : getOptionValue(newOption);
        onChange(newValue);
      }
    },
    [onChange, isMulti, getOptionValue],
  );

  const onConfirmSelection = useCallback(() => {
    setMenuOpen(false);
  }, []);

  return (
    <div className={cn("dropdown", isMulti && "multi", className)}>
      {label && (
        <label className={labelClassName} htmlFor={id}>
          {label}
        </label>
      )}
      <Select
        {...props}
        inputId={id}
        value={selectedOptions}
        options={options}
        defaultValue={defaultValue}
        placeholder={placeholder}
        onChange={setValue}
        classNamePrefix="dropdown-select"
        hideSelectedOptions={false}
        closeMenuOnSelect={closeMenuOnSelect ?? !isMulti}
        isMulti={isMulti}
        menuIsOpen={menuOpen}
        onMenuOpen={() => setMenuOpen(true)}
        onMenuClose={() => setMenuOpen(false)}
        onConfirmSelection={onConfirmSelection}
        getOptionValue={getOptionValue}
        components={{
          Option,
          Menu,
          IndicatorSeparator: undefined,
        }}
        className={selectClassName}
      />
    </div>
  );
};

const Option = ({ children, ...props }) => (
  <components.Option
    className={cn("dropdown__option", props.isMulti ? "multi" : "single")}
    {...props}
  >
    {props.isMulti && (
      <span className={cn("checkbox", props.isSelected && "checked")}>
        <span className="checkmark">
          <CheckboxSvg />
        </span>
      </span>
    )}
    {children}
  </components.Option>
);

const Menu = ({ children, isMulti, ...props }) => {
  const { onConfirmSelection } = props.selectProps;

  return (
    <components.Menu {...props}>
      {children}
      {isMulti && <Button primary label="Velja" onClick={onConfirmSelection} />}
    </components.Menu>
  );
};

const valuePropType = PropTypes.oneOfType([
  PropTypes.string,
  PropTypes.number,
  PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.string, PropTypes.number])),
  PropTypes.object,
]);

Dropdown.propTypes = {
  options: PropTypes.array,
  value: valuePropType,
  defaultValue: valuePropType,
  label: PropTypes.string,
  onChange: PropTypes.func,
  placeholder: PropTypes.string,
  isMulti: PropTypes.bool,
  closeMenuOnSelect: PropTypes.bool,
  className: PropTypes.string,
  getOptionValue: PropTypes.func,
};
