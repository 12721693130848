import React, { StrictMode } from "react";
import { Provider } from "react-redux";

import { store } from "@/store";
import { CarGroupDetailsContainer } from "./car-detail-container";

export const Wrapper = (props) => {
  return (
    <StrictMode>
      <Provider store={store}>
        <div className="container clamped">
          <CarGroupDetailsContainer {...props} />
        </div>
      </Provider>
    </StrictMode>
  );
};
