import PropTypes from "prop-types";
import React from "react";

import { Button, CalendarSvg } from "@/components";

export const Userbar = () => {
  const bookAppointment = () => {
    window.location.href = "/panta-tima/";
  };

  return (
    <div className="userbar">
      {/* My pages, cart and search are not used in BL 1.0 - will be kept here as comments until needed */}
      {/* {user ? (
        <Button
          className="my-pages-btn"
          size="small"
          onClick={toggleMyPages}
          icon={<MyPagesSvg />}
          noBorder
        />
      ) :
        <Button
          size="small"
          onClick={onLogin}
          icon={<MyPagesSvg />}
          noBorder
          data-testid="login-button"
        />
      )} */}
      {/* <Button size="small" onClick={noop} icon={<SearchSvg />} noBorder className="search-btn me-3" /> */}
      {/* <Button size="small" onClick={noop} icon={<CartSvg />} noBorder className="me-2" /> */}
      <Button
        className="appointment-btn"
        primary
        size="medium"
        onClick={bookAppointment}
        icon={<CalendarSvg />}
        label="PANTA TÍMA"
      />
    </div>
  );
};

Userbar.propTypes = {
  user: PropTypes.shape({
    name: PropTypes.string.isRequired,
  }),
  onLogin: PropTypes.func.isRequired,
  onCreateAccount: PropTypes.func.isRequired,
};
