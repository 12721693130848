import React from "react";

import { CarsGroupedGrid, CarsGrid } from "@/components";

export const CarMakeListContainer = ({ carMake }) => {
  const kjarabilar = document.location.href.indexOf("kjarabilar") !== -1;
  if (!kjarabilar) {
    return <CarsGroupedGrid filters={{ make: carMake, kjarabill: false }} showMake={false} />;
  } else {
    return <CarsGrid filters={{ make: carMake, kjarabill: true }} showMake={false} />;
  }
};
