import { useMemo } from "react";

import { useLocalStorage } from "./useLocalStorage";

export const useComparisonCarsIds = () => {
  const [comparisonCarsIdsRaw, setComparisonCarsIds, deleteComparisonCarsIds] = useLocalStorage(
    "comparison-cars-ids",
    [],
  );

  const comparisonCarsIds = useMemo(() => {
    if (!comparisonCarsIdsRaw) return [];

    if (!Array.isArray(comparisonCarsIdsRaw)) {
      deleteComparisonCarsIds();
      return [];
    }

    return comparisonCarsIdsRaw.map((id) => parseInt(id, 10)).filter((id) => !isNaN(id));
  }, [comparisonCarsIdsRaw, deleteComparisonCarsIds]);

  return { comparisonCarsIds, setComparisonCarsIds, deleteComparisonCarsIds };
};
