import React, { useMemo } from "react";

import { LeafSvg } from "@/components";
import { FUEL_TYPE, FUEL_TYPE_LABEL } from "@/const";

/**
 * @param {Object} props
 * @param {(keyof typeof import("@/const").FUEL_TYPE)[]} props.fuelTypes - List of fuel types
 */
export const FuelTypesList = ({ fuelTypes }) => {
  if (!fuelTypes || fuelTypes.length === 0) {
    return null;
  }

  return (
    <ul className="fuel-types">
      {fuelTypes.map((type) => {
        return (
          <li key={type}>
            {type === FUEL_TYPE.ELECTRIC && <LeafSvg />}
            <span>{FUEL_TYPE_LABEL[type] ?? type}</span>
          </li>
        );
      })}
    </ul>
  );
};

export const CarInfoList = ({ fuelEconomy, range }) => {
  const infoItems = useMemo(
    () => [
      {
        condition: Boolean(fuelEconomy),
        label: "Eyðsla frá",
        value: fuelEconomy,
        unit: "l/100km",
      },
      {
        condition: Boolean(range),
        label: "Drægni allt að",
        value: range,
        unit: "km",
      },
    ],
    [fuelEconomy, range],
  );

  return (
    <ul className="car-card__info-list">
      {infoItems
        .filter((item) => item.condition)
        .map(({ label, value, unit }) => (
          <li key={label}>
            <span className="car-card__info-label">{label}</span>
            <span className="car-card__info-value">
              {value} <span className="car-card__value-unit">{unit}</span>
            </span>
          </li>
        ))}
    </ul>
  );
};
