import { throttle } from "lodash";
import { useCallback, useEffect, useState } from "react";

/**
 * Hook for syncing state with query parameters
 * The type of the value is not checked when initializing from the query parameters, so it can be anything
 * @template T
 * @param {string} key
 * @param {T} initialValue
 * @returns {[T, React.Dispatch<React.SetStateAction<T>>]}
 */
export const useQueryParamsState = (key, initialValue) => {
  const [value, setValue] = useState(() => {
    const params = new URLSearchParams(window.location.search);
    const values = params.getAll(key);

    // If there are multiple values, initialize with an array
    // If there is only one value, initialize with that value
    // Fall back to the initial value if there are no values
    const rawValue = (values.length > 1 ? values : values[0]) ?? JSON.stringify(initialValue);

    try {
      if (Array.isArray(rawValue)) {
        return rawValue.map((v) => JSON.parse(v));
      }
      return JSON.parse(rawValue);
    } catch {
      return rawValue;
    }
  });

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const updateURL = useCallback(
    throttle(
      (value) => {
        const params = new URLSearchParams(window.location.search);

        if (value == null || value === "" || (Array.isArray(value) && value.length === 0)) {
          params.delete(key);
        } else {
          if (Array.isArray(value)) {
            params.delete(key);
            value.forEach((v) => params.append(key, JSON.stringify(v)));
          } else {
            params.set(key, JSON.stringify(value));
          }
        }

        const paramsString = params.toString();

        // If there are no query parameters, remove the question mark
        if (paramsString === "") {
          window.history.replaceState({}, "", window.location.pathname);
          return;
        }

        window.history.replaceState({}, "", `${window.location.pathname}?${paramsString}`);
      },
      100,
      { leading: true },
    ),
    [key],
  );

  useEffect(() => {
    updateURL(value);
  }, [value, updateURL]);

  return [value, setValue];
};
