import PropTypes from "prop-types";
import React from "react";

import { useControllableState } from "@/hooks";
import { cn } from "@/utils";

export const Toggle = ({ label_l, label_r, value, defaultValue = false, onChange, className }) => {
  const [active, setActive] = useControllableState({
    prop: value,
    defaultProp: defaultValue,
    onChange,
  });

  return (
    <div
      className={cn("toggle", active && "toggle--active", className)}
      role="toggle"
      aria-checked={active}
    >
      <span className="toggle__switch" />
      <div className="toggle__labels-container">
        <span
          className="toggle__label"
          onClick={() => setActive(false)}
          tabIndex={0}
          role="button"
          onKeyDown={(e) => e.key === "Enter" && setActive(false)}
        >
          {label_l}
        </span>
        <span
          className="toggle__label"
          onClick={() => setActive(true)}
          tabIndex={0}
          role="button"
          onKeyDown={(e) => e.key === "Enter" && setActive(true)}
        >
          {label_r}
        </span>
      </div>
    </div>
  );
};

Toggle.propTypes = {
  label_l: PropTypes.string,
  label_r: PropTypes.string,
  value: PropTypes.bool,
  defaultValue: PropTypes.bool,
  onChange: PropTypes.func,
  className: PropTypes.string,
};
