import React, { useEffect, useMemo } from "react";

import { useControllableState } from "@/hooks";
import { cn } from "@/utils";

import "./tabs.scss";

/**
 * @typedef {Object} Tab
 * @property {string} title - The title of the tab.
 * @property {Boolean | (tabKey: string | number, currentKey: string | number) => Boolean} [disabled] - Whether the tab is disabled.
 * @property {Boolean} [hidden] - Whether the tab is hidden.
 * @property {React.ReactNode} children - The content of the tab.
 * @property {string} [value] - Optional unique value for the tab.
 */

/**
 * @typedef {Omit<Tab, "disabled"> & { disabled: Boolean }} FinalTab
 */

/**
 * @typedef {Object} TabsProps
 * @property {Array<Tab>} [tabs] - The tabs to display. You can use either this prop or use the `<Tab>` component as children.
 * @property {React.ReactNode} [children] - The children of the component. You can use either this prop or use the `tabs` prop.
 * @property {string | number} [currentTab] - The index or key of the current tab.
 * @property {string | number} [defaultTab] - The index or key of the default tab.
 * @property {(tabKey: string | number) => void} [onTabChange] - A function that is called when the tab is changed.
 * @property {Boolean} [compact] - Whether to use a compact style for the tabs.
 * @property {string} [className] - Additional classes to add to the tabs wrapper.
 * @property {string} [tabPanelClassName] - Additional classes to add to the tab panel.
 */

/**
 * A tab component that can be used to display a single tab.
 * @param {Tab} _props
 */
export const Tab = (_props) => {
  console.warn("Tab component should only be rendered as a direct child of Tabs component");
  return null;
};

/**
 * A tabs component that can be used to display multiple tabs.
 * @param {TabsProps & React.HTMLProps<HTMLDivElement>} props
 */
export const Tabs = ({
  tabs,
  children,
  currentTab: currentTabProp,
  defaultTab,
  onTabChange,
  compact,
  className,
  tabPanelClassName,
  ...props
}) => {
  const [currentTabKey, setCurrentTabKey, isControlled] = useControllableState({
    prop: currentTabProp,
    defaultProp: defaultTab,
    onChange: onTabChange,
  });

  if (tabs && children) {
    console.warn("Tabs component should not have both tabs and children props");
  }

  /**
   * @type {Array<FinalTab>}
   */
  const finalTabs = useMemo(() => {
    if (children != null) {
      return React.Children.toArray(children).map((child, index) => {
        if (child.type !== Tab) {
          throw new Error("Tabs can only contain Tab components");
        }

        return {
          ...child.props,
          value: child.props.value ?? index,
        };
      });
    }

    if (tabs != null) {
      return tabs?.map((tab, index) => ({
        ...tab,
        value: tab.value ?? index,
      }));
    }

    return [];
  }, [children, tabs]).map((tab) =>
    typeof tab.disabled === "function"
      ? { ...tab, disabled: tab.disabled(tab.value, currentTabKey) }
      : tab,
  );

  useEffect(() => {
    if (isControlled) return;

    // Select first enabled tab when the tabs change
    setCurrentTabKey(finalTabs.find((tab) => !tab.disabled).value);
  }, [finalTabs, isControlled, setCurrentTabKey]);

  const currentTab = useMemo(() => {
    if (typeof currentTabKey === "number") return finalTabs[currentTabKey];

    return finalTabs.find((tab) => tab.value === currentTabKey);
  }, [finalTabs, currentTabKey]);

  if (!finalTabs || finalTabs.length === 0) {
    return null;
  }

  return (
    <div className={cn("tabs", compact && "tabs--compact", className)} {...props}>
      <nav className="tabs__nav">
        <ul className="tabs__nav__tablist" role="tablist">
          {finalTabs
            .filter((tab) => !tab.hidden)
            .map((tab) => (
              <li
                key={tab.value}
                className={cn(
                  "tabs__nav__tab",
                  tab.value === currentTabKey && "tabs__nav__tab--active",
                )}
                aria-disabled={tab.disabled}
                role="tab"
              >
                <button
                  className="tabs__nav__tab__button"
                  disabled={tab.disabled}
                  onClick={() => setCurrentTabKey(tab.value)}
                >
                  {tab.title}
                </button>
              </li>
            ))}
        </ul>
      </nav>
      <div role="tabpanel" className={tabPanelClassName}>
        {currentTab?.children}
      </div>
    </div>
  );
};
